import React, { useState } from "react";
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";

import copy from "copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "75%",
    overflowY: "auto",
    textAlign: "left",
  },
}));

export default function PopupCarbonLP(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // display cta btn state and func toggle
  const [displayCtaBtn, setDisplayCtaBtn] = useState("yes");

  const handleChangeDisplayCtaBtn = (event) => {
    setDisplayCtaBtn(event.target.value);
  };

  // code of component
  const [generateCode, setGenerateCode] = useState(false);
  const [codeOfComponent, setCodeOfComponent] = useState("");

  const generateCodeFunc = () => {
    setGenerateCode(true);

    setCodeOfComponent(`
    <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">

<style>

/* START OVERRIDE CREATIVE ELEMENTS */
main:not(.page--sports) .page-cms {
  margin-top: 0 !important;
}

#main {
  background-color: #000;
}

#wrapper .elementor-section.elementor-section-boxed > .elementor-container {
  max-width: none !important;
}
#wrapper .container {
  width: auto !important;
  max-width: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#wrapper
  .elementor-column-gap-default
  .elementor-element-populated:not(footer .elementor-element-populated) {
  padding: 0 !important;
}
/* END OVERRIDE CREATIVE ELEMENTS */

/* ------------- $$$$$$$$$$ MOBILE FIRST $$$$$$$$$$ ------------- */

* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden !important;
}

.lp-container {
  background-color: #000;
  color: #fff;
  font-family: Roboto;
  padding-top: 0;
}

.lp-section {
  margin-bottom: 70px;
  padding: 12px 14px;
}

.lp-container p {
  color: #fff !important;
}

.lp-container h3 {
  border: none !important;
}

/* ---------- START SECTION 1 STYLES ---------- */
.hero {
  min-height: 100vh;
  position: relative;
}
.hero .hero__logo {
  text-align: center;
  padding-top: 22px;
  z-index: 1;
  position: relative;
}
.hero .hero__logo img {
  width: 220px;
}

.hero .hero__left-img {
  position: absolute;
  top: 0;
  z-index: 0;
  left: -120px;
}
.hero .hero__left-img img {
  width: 194px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.hero .hero__right-img {
  position: absolute;
  top: 0;
  z-index: 0;
  right: -120px;
}
.hero .hero__right-img img {
  width: 194px;
}

.hero .hero__center-img {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero .hero__center-img img {
  width: 794px;
  max-width: none !important;
}

.hero .hero__bottom-img {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero .hero__bottom-img img {
  width: 100vw;
  max-width: none !important;
}

.hero .hero__text {
  position: absolute;
  top: 50%;
  max-width: 90%;
}
.hero .hero__text-brand {
  display: block;
  color: #02cccb;
  text-transform: uppercase;
  font-size: 14px;
}
.hero .hero__text-title {
  text-transform: uppercase;
  font-size: 38px !important;
  margin-bottom: 12px;
}
.hero .hero__text-paragraph {
  line-height: 1.2;
  font-weight: 300;
  font-size: 16px;
}
/* ---------- END SECTION 1 STYLES ---------- */

/* ---------- START SECTION 2 STYLES ---------- */
.section2 .section2__title {
  border: none !important;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #02cccb;
  text-transform: uppercase;
  font-size: 38px !important;
  line-height: 36px;
  margin-bottom: 12px;
}
.section2 .section2__paragraph {
  line-height: 1.2;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 30px;
}
.section2 .section2__img {
  text-align: center;
  position: relative;
}
.section2 .section2__img img {
  width: 100%;
}

.section2 .section2__img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0); /* Black see-through */
  width: 100%;
  height: 100%;
}

.section2 .section2__cta {
  margin-top: 12px;
}

.section2 .section2__cta--desktop {
  display: none !important;
}

.section2 .section2__cta-content {
  margin-bottom: 32px;
}

.section2 .section2__cta span {
  display: block;
}

.section2 .section2__cta-product-modal-code {
  font-size: 12px;
  line-height: 22px;
}

.section2 .section2__cta-title {
  font-size: 40px !important;
  line-height: 36px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.section2 .section2__cta-variants {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.section2 .section2__cta-price {
  font-weight: 700;
  color: #000;
  background-color: #fee842;
  display: inline-block !important;
  padding: 2px 6px;
  font-size: 30px;
  font-family: "Roboto Condensed";
}

.section2 .section2__cta-btn a {
  display: inline-block;
  width: 280px;
  margin-top: 10px;
  font-size: 18px;
  border-radius: 5px;
  background-color: #fff;
  text-decoration: none;
  color: #000;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.4s ease-out;
}
.section2 .section2__cta-btn a:hover {
  background-color: #ddd;
}
/* ---------- END SECTION 2 STYLES ---------- */

/* ---------- START SECTION 3 STYLES ---------- */
.section3 .section3__video-container {
  position: relative;
  width: 100%;
}
.section3 .section3__video-container img {
  width: 100%;
  object-fit: cover;
}
.section3 .section3__img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4); /* Black see-through */
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background 0.6s ease-out;
}
.section3 .section3__img-overlay:hover {
  background: rgba(0, 0, 0, 0);
}
.section3 .section3__img-overlay svg {
  font-size: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.section3 .section3__iframe {
  width: 100%;
  min-height: 300px;
}
/* ---------- END SECTION 3 STYLES ---------- */

/* ---------- START SECTION 4 STYLES ---------- */
.section4 {
  padding: 0;
  margin-bottom: 0;
}

.section4 .section4__text {
  padding: 32px 34px;
}

.section4 .section4__title {
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  font-size: 40px;
  width: 67.91666666vw;
  height: 19.58333333vw;
  background-size: cover;
  background-image: url("https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_relance_img.jpg");
}
.section4 .section4__title:before {
  background-image: url("https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_relance_mask.png");
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 101% 101%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.section4 .section4__paragraph {
  margin-top: 4px;
  font-size: 14px !important;
  line-height: 18px;
  text-align: left;
}

.section4 .section4__paragraph-underline-text {
  position: relative;
}
.section4 .section4__paragraph-underline-text:after {
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: -0.175em;
  bottom: 0;
  width: 100%;
  height: 20%;
  padding: 0 0.175em;
  background-color: #da0598;
  z-index: 210;
  -webkit-transition: -webkit-transform 225ms ease-out;
  -moz-transition: -moz-transform 225ms ease-out;
  -o-transition: -o-transform 225ms ease-out;
  transition: transform 225ms ease-out;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  transition-delay: 0s;
}
.section4 .section4__img {
  margin-top: 60px;
  width: 100%;
}
.section4 .section4__img img {
  width: 100%;
  margin: 0 !important;
}
/* ---------- END SECTION 4 STYLES ---------- */

/* ---------- START SECTION 5 STYLES ---------- */
.section5 {
  padding: 0;
}
.section5 .section5__content {
  min-height: 600px;
  width: 100%;
  background-image: url("https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_legerete_background.png");
  background-size: cover;
}
.section5 .section5__img {
  padding-top: 62px;
  text-align: center;
  position: relative;
  z-index: 3;
}
.section5 .section5__img img {
  width: 240px;
}

.section5 .section5__text {
  text-align: center;
  position: relative;
  z-index: 2;
  top: -35px;
}

.section5 .section5__title {
  font-size: 56px !important;
  line-height: 21.07843137vw;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase;
}

.section5 .section5__paragraph {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}
.section5 .section5__paragraph-underline-text {
  position: relative;
  z-index: 1;
}
.section5 .section5__paragraph-underline-text:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #b95c02;
  z-index: -1;
}
/* ---------- END SECTION 5 STYLES ---------- */

/* ---------- START SECTION 6 STYLES ---------- */
.section6 {
  padding: 0;
}
.section6__content {
  min-height: 300px;
  width: 100%;
  position: relative;
}
.section6 .section6__video {
  position: relative;
  width: 100%;
  min-height: 200px;
  height: 200px;
  background-color: #000;
  object-fit: cover;
}
.section6 .section6__img {
  position: absolute;
  top: 40%;
  left: 38%;
  transform: rotate(-50deg) translate(50%, -50%);
}
.section6 .section6__img img {
  width: 220px;
}
.section6 .section6__text {
  padding: 12px 14px;
  margin-top: 10px;
}
.section6 .section6__title {
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase;
  font-size: 45px !important;
}
.section6 .section6__paragraph {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}
.section6 .section6__paragraph-underline-text {
  position: relative;
  z-index: 1;
}
.section6 .section6__paragraph-underline-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #ff6871;
  z-index: -1;
}
/* ---------- END SECTION 6 STYLES ---------- */

/* ---------- START SECTION 7 STYLES ---------- */
.section7 {
  margin-bottom: 90px;
}
.section7 .section7__video {
  width: 90%;
  display: block;
  margin: 0 auto;
}
/* ---------- END SECTION 7 STYLES ---------- */

/* ---------- START SECTION 8 STYLES ---------- */

.section8 .section8__content-row {
  margin-bottom: 42px;
}

.section8 .section8__img img {
  width: 90%;
  margin-top: 10px;
}

.section8 .section8__title {
  border: none;
  font-size: 38px !important;
  line-height: 36px;
  color: #02cccb;
  text-transform: uppercase;
  font-weight: 400;
}
.section8 .section8__paragraph {
  margin-top: 12px;
  font-size: 16px;
  line-height: 20px;
}
.section8 .section8__paragraph span {
  color: #02cccb;
}

.section8 .section8__title2 {
  color: #fff;
}

.section8 .section8__cta-btn {
  margin-bottom: 20px;
}

.section8 .section8__cta-btn a {
  display: inline-block;
  width: 280px;
  margin-top: 10px;
  font-size: 18px;
  border-radius: 5px;
  background-color: #fff;
  text-decoration: none;
  color: #000;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.4s ease-out;
}
.section8 .section8__cta-btn a:hover {
  background-color: #ddd;
}
/* ---------- END SECTION 8 STYLES ---------- */

/* ---------- START SECTION 9 STYLES ---------- */
.section9 .section9__img img {
  width: 100%;
  margin: 0 !important;
}
/* ---------- END SECTION 9 STYLES ---------- */

/* ------------- $$$$$$$$$$ TABLET 760px $$$$$$$$$$ ------------- */
@media (min-width: 760px) {
  /* ---------- START SECTION 1 STYLES ---------- */
  .hero .hero__center-img {
    top: 34%;
  }
  .hero .hero__center-img img {
    width: 950px;
  }
  .hero .hero__left-img {
    left: -90px;
  }
  .hero .hero__right-img {
    right: -90px;
  }
  /* ---------- END SECTION 1 STYLES ---------- */

  /* ---------- START SECTION 3 STYLES ---------- */
  .section3 .section3__iframe {
    min-height: 500px;
  }
  /* ---------- END SECTION 3 STYLES ---------- */

  /* ---------- START SECTION 5 STYLES ---------- */
  .section5 .section5__text {
    top: -65px;
  }
  .section5 .section5__title {
    font-size: 86px !important;
    line-height: 16.07843137vw;
  }

  /* ---------- END SECTION 5 STYLES ---------- */

  /* ---------- START SECTION 6 STYLES ---------- */
  .section6 {
    margin: 140px 0;
  }
  .section6 .section6__img {
    left: 40%;
  }
  /* ---------- END SECTION 6 STYLES ---------- */

  /* ---------- START SECTION 7 STYLES ---------- */
  .section7 {
    margin-bottom: 120px;
  }
  /* ---------- END SECTION 7 STYLES ---------- */

  /* ---------- START SECTION 8 STYLES ---------- */
  .section8 .section8__content-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 80px;
  }
  .section8 .section8__content-row2 {
    flex-direction: row-reverse;
  }
  .section8 .section8__content-row--left {
    width: 47%;
  }
  .section8 .section8__content-row--right {
    width: 47%;
  }
  .section8 .section8__text2 {
    padding-top: 12px;
  }
  .section8 .section8__img img {
    width: 100%;
  }

  /* ---------- END SECTION 8 STYLES ---------- */
}

/* ------------- $$$$$$$$$$ DESKTOP 1200px $$$$$$$$$$ ------------- */
@media (min-width: 1200px) {
  .lp-section {
    margin-bottom: 90px;
  }
  /* ---------- START SECTION 1 STYLES ---------- */
  .hero .hero__logo {
    padding-top: 10px;
  }
  .hero .hero__center-img {
    top: 45%;
  }
  .hero .hero__text {
    position: absolute;
    top: 45%;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  .hero .hero__left-img {
    left: -10px;
  }
  .hero .hero__left-img img {
    width: 230px;
  }
  .hero .hero__right-img {
    right: -10px;
  }
  .hero .hero__right-img img {
    width: 230px;
  }
  .hero .hero__bottom-img {
    top: 90%;
  }
  .hero .hero__text-title {
    font-size: 48px !important;
    margin-bottom: 12px;
    text-align: center;
  }
  .hero .hero__text-brand {
    font-size: 16px;
    padding-left: 70px;
  }
  /* ---------- END SECTION 1 STYLES ---------- */

  /* ---------- START SECTION 2 STYLES ---------- */
  .section2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    margin-top: 52px;
  }
  .section2 .section2__text {
    width: 27%;
    padding-top: 16px;
  }
  .section2 .section2__img {
    width: 62%;
  }

  .section2 .section2__cta--mobile {
    display: none !important;
  }
  .section2 .section2__cta--desktop {
    display: block !important;
  }
  /* ---------- END SECTION 2 STYLES ---------- */

  /* ---------- START SECTION 3 STYLES ---------- */
  .section3 .section3__video-container {
    width: 80%;
    margin: 0 auto;
  }
  .section3 .section3__iframe {
    width: 100%;
    min-height: 570px;
  }
  /* ---------- END SECTION 3 STYLES ---------- */

  /* ---------- START SECTION 4 STYLES ---------- */
  .section4 {
    display: flex;
    justify-content: space-between;
  }

  .section4 .section4__text,
  .section4 .section4__img {
    width: 47%;
  }

  .section4 .section4__text {
    margin-top: 240px;
  }

  .section4 .section4__title {
    width: 43.916667vw;
    height: 14.583333vw;
  }

  .section4 .section4__paragraph {
    font-size: 16px !important;
  }
  /* ---------- END SECTION 4 STYLES ---------- */

  /* ---------- START SECTION 5 STYLES ---------- */
  .section5 {
    padding-bottom: 100px;
  }
  .section5 .section5__img img {
    width: 340px;
  }
  .section5 .section5__title {
    font-size: 110px !important;
    line-height: 13.07843137vw;
  }
  .section5 .section5__text {
    top: -75px;
  }
  .section5 .section5__paragraph {
    font-size: 18px !important;
  }
  /* ---------- END SECTION 5 STYLES ---------- */

  /* ---------- START SECTION 6 STYLES ---------- */
  .section6 .section6__video {
    height: 240px;
  }

  .section6 .section6__image-and-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 330px;
  }
  .section6 .section6__paragraph {
    font-size: 18px !important;
  }
  .section6 .section6__img {
    position: static;
    transform: none;
  }
  .section6 .section6__img img {
    width: 320px;
  }
  .section6 .section6__title {
    font-size: 75px !important;
  }

  /* ---------- END SECTION 6 STYLES ---------- */

  /* ---------- START SECTION 7 STYLES ---------- */
  .section7 {
    margin-top: -320px;
    margin-bottom: 150px;
  }
  /* ---------- END SECTION 7 STYLES ---------- */

  /* ---------- START SECTION 8 STYLES ---------- */
  .section8 .section8__content-row {
    justify-content: space-evenly;
  }

  .section8 .section8__content-row--left {
    width: 47%;
    padding: 12px;
  }
  .section8 .section8__content-row--right {
    width: 47%;
    padding: 12px;
  }

  .section8 .section8__title {
    font-size: 38px !important;
  }
  .section8 .section8__paragraph {
    font-size: 20px;
    line-height: 28px;
  }
  /* ---------- END SECTION 8 STYLES ---------- */

  /* ---------- START SECTION 9 STYLES ---------- */
  .section9 .section9__row {
    display: flex;
    justify-content: center;
  }
  /* ---------- END SECTION 9 STYLES ---------- */
}

/* ------------- $$$$$$$$$$ LARGE DESKTOP 1800px $$$$$$$$$$ ------------- */
@media (min-width: 1800px) {
  /* ---------- START SECTION 1 STYLES ---------- */
  .hero .hero__left-img img {
    width: 300px;
  }
  .hero .hero__right-img img {
    width: 300px;
  }
  .hero .hero__center-img img {
    width: 1700px;
  }
  .hero .hero__bottom-img {
    top: 80%;
  }
  .hero .hero__text {
    top: 49%;
  }
  .hero .hero__text-brand {
    font-size: 22px;
  }
  .hero .hero__text-title {
    font-size: 78px !important;
  }
  .hero .hero__text-paragraph {
    font-size: 20px;
  }

  /* ---------- END SECTION 1 STYLES ---------- */

  /* ---------- START SECTION 2 STYLES ---------- */
  .section2 .section2__img {
    width: 54%;
  }
  /* ---------- START SECTION 2 STYLES ---------- */

  /* ---------- START SECTION 3 STYLES ---------- */
  .section3 .section3__iframe {
    width: 100%;
    min-height: 700px;
  }
  /* ---------- END SECTION 3 STYLES ---------- */

  /* ---------- START SECTION 4 STYLES ---------- */
  .section4 .section4__text {
    margin-top: 340px;
  }
  /* ---------- END SECTION 4 STYLES ---------- */

  /* ---------- START SECTION 5 STYLES ---------- */
  .section5 .section5__img img {
    width: 400px;
  }
  .section5 .section5__text {
    top: -82px;
  }
  .section5 .section5__title {
    line-height: 9.07843137vw;
  }
  /* ---------- END SECTION 5 STYLES ---------- */

  /* ---------- START SECTION 6 STYLES ---------- */
  .section6 {
    margin: 240px 0;
  }
  .section6 .section6__video {
    height: 300px;
  }
  .section6 .section6__image-and-text {
    bottom: 370px;
  }
  /* ---------- END SECTION 6 STYLES ---------- */

  /* ---------- START SECTION 7 STYLES ---------- */

  .section7 .section7__video {
    width: 75%;
  }
  /* ---------- END SECTION 7 STYLES ---------- */

  /* ---------- START SECTION 8 STYLES ---------- */
  .section8 .section8__content-row {
    margin-bottom: 120px;
  }
  .section8 .section8__content-row--left {
    width: 40%;
  }
  .section8 .section8__content-row--right {
    width: 40%;
  }
  .section8 .section8__title {
    font-size: 48px !important;
    line-height: 50px;
  }

  /* ---------- END SECTION 8 STYLES ---------- */
}

</style>



<div class="lp-container"">

    <!-- START SECTION 1 -->
    <section class="lp-section hero">
      <div class="hero__logo">
        <img
          src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/logo_decathlon-kiprun.svg"
          alt="kiprun-logo"
        />
      </div>

      <div class="hero__left-img">
        <img
          src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cover_background_labo.png"
          alt=""
        />
      </div>

      <div class="hero__right-img">
        <img
          src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cover_background_labo.png"
          alt=""
        />
      </div>

      <div class="hero__center-img">
        <img
          src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cover_shoe/cover_shoe_00130.png"
          alt=""
        />
      </div>

      <div class="hero__bottom-img">
        <img
          src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cover_background_ground.png"
          alt=""
       
        />
      </div>
     
     
          <div class="hero__text">
              <span class="hero__text-brand" data-aos="fade-left">kd900x</span>
              <h2 class="hero__text-title" data-aos="fade-left">#BreakYourLimits</h2>
              <p class="hero__text-paragraph" data-aos="fade-left">
                Auf der Suche nach Schuhen, um deine Grenzen zu überschreiten?<br />
                Durch den Pebax®-Schaum und die Carbon-Platte, <br />
                bieten dir die KD900X eine unglaubliche Energierückgabe, <br />
                um an Schnelligkeit zu gewinnen und auf Dauer mehr Leistung zu
                bringen.
              </p>
            </div>
     

    </section>
    <!--  END SECTION 1 -->

    <!-- START SECTION 2 -->
    <section class="lp-section section2">
      <div class="section2__text">
          <h3 class="section2__title">Technische Informationen</h3>
          <p class="section2__paragraph">
            20% höhere Energierückgabe <br>
            Leichtigkeit : 225g in Gr. 42 / 190g in Gr. 38 <br>
            Drop: 8mm
          </p>

          <div class="section2__cta section2__cta--desktop">
              <div class="section2__cta-content">
                  <span class="section2__cta-product-modal-code">Ref : 8666803</span>
                  <h3 class="section2__cta-title">KD900X HERREN</h3>
                  <span class="section2__cta-variants">weiß</span>
                  <span class="section2__cta-price">159,99€</span>
                  <div class="section2__cta-btn">
                    <a href="/laufschuhe-strasse-herren/326082-157513-laufschuhe-herren-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
                  </div>
              </div>
              <div class="section2__cta-content">
                  <span class="section2__cta-product-modal-code">Ref : 8644961</span>
                  <h3 class="section2__cta-title">KD900X DAMEN</h3>
                  <span class="section2__cta-variants">weiß</span>
                  <span class="section2__cta-price">159,99€</span>
                  <div class="section2__cta-btn">
                    <a href="/laufschuhe-strasse-damen/326083-157519-laufschuhe-damen-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
                  </div>
              </div>
            </div>
      </div>



      <div class="section2__img">
        <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/vente_background.jpg" alt="kiprun laufen schuhe">
        <div class="section2__img-overlay"></div>
      </div>

      <div class="section2__cta section2__cta--mobile">
        <div class="section2__cta-content">
            <span class="section2__cta-product-modal-code">Ref : 8666803</span>
            <h3 class="section2__cta-title">KD900X HERREN</h3>
            <span class="section2__cta-variants">weiß</span>
            <span class="section2__cta-price">159,99€</span>
            <div class="section2__cta-btn">
              <a href="/laufschuhe-strasse-herren/326082-157513-laufschuhe-herren-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
            </div>
        </div>
        <div class="section2__cta-content">
            <span class="section2__cta-product-modal-code">Ref : 8644961</span>
            <h3 class="section2__cta-title">KD900X DAMEN</h3>
            <span class="section2__cta-variants">weiß</span>
            <span class="section2__cta-price">159,99€</span>
            <div class="section2__cta-btn">
              <a href="/laufschuhe-strasse-damen/326083-157519-laufschuhe-damen-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
            </div>
        </div>
      </div>
    </section>
    <!-- END SECTION 2 -->  

    <!-- START SECTION 3 -->
    <section class="lp-section section3" >
       <div class="section3__video-container" id="video-container" data-aos="fade-up">
         <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/video_img.jpg" alt="kiprun laufschuhe" />
         <div class="section3__img-overlay">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896zm0 832a384 384 0 0 0 0-768a384 384 0 0 0 0 768zm-48-247.616L668.608 512L464 375.616v272.768zm10.624-342.656l249.472 166.336a48 48 0 0 1 0 79.872L474.624 718.272A48 48 0 0 1 400 678.336V345.6a48 48 0 0 1 74.624-39.936z"/></svg>
         </div>
       </div> 
    </section>
    <!-- START SECTION 3 -->

    <!-- START SECTION 4 -->
    <section class="lp-section section4">
      <div class="section4__text" data-aos="fade-right">
          <h3 class="section4__title">
              <span></span>
            </h3>
            <p class="section4__paragraph">
                Hervorragende Energierückgabe<br>
                dank des 
                <span>
                  <span class="section4__paragraph-underline-text">Pebax®-VFOAM-Schaums</span>
                </span><br>
                sowie der
                <span>
                    <span class="section4__paragraph-underline-text">Carbon-Platte.</span>
                  </span><br>
                  Eine Kombination, die<br>
                  <span>
                      <span class="section4__paragraph-underline-text">75% Energie zurückgibt.</span>
                    </span><br>
            </p>
      </div>

      <div class="section4__img" data-aos="fade-left">
        <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_relance_img.jpg" alt="kiprun laufschuhe">
      </div>

    </section>
    <!-- START SECTION 4 -->

    <!-- START SECTION 5 -->
    <section class="lp-section section5" data-aos="fade-up">
      <div class="section5__content">
        <div class="section5__img">
          <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_legerete_shoe.png" alt="kiprun laufschuhe">
        </div>
        <div class="section5__text">
          <h3 class="section5__title">Leichtigkeit</h3>
          <p class="section5__paragraph">
            Das geringe Gewicht macht 30% des Rebounds aus.<br>
            Nur 
            <span class="section5__paragraph-underline-text">225g in Größe 42.</span>

          </p>
        </div>
      </div>
    </section>
    <!-- START SECTION 5 -->

    <!-- START SECTION 6 -->
    <section class="lp-section section6">
      <div class="section6__content">
          <video autoplay muted loop class="section6__video">
              <source src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/videos/avantages_adherence_rain.mp4" type="video/mp4">
            </video>
            <div class="section6__image-and-text">
                <div class="section6__img">
                    <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/avantages_adherence_shoe.png" alt="kiprun laufschuhe">
                  </div>
                  <div class="section6__text">
                    <h3 class="section6__title">
                        Bodenhaftung
                    </h3>
                    <p class="section6__paragraph">
                      <span class="section6__paragraph-underline-text">
                          Perfekte Bodenhaftung
                      </span>
                      auf feuchtem Untergrund <br>
                      dank Sohlenstruktur und -geometrie.
                    </p>
                  </div>
            </div>
      </div>
    </section>
    <!-- END SECTION 6 -->

    <!-- START SECTION 7 -->
    <section class="lp-section section7">
      <video autoplay muted loop class="section7__video">
        <source src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/videos/wireframe.mp4" type="video/mp4">
      </video>
    </section>
    <!-- END SECTION 7 -->

    <!-- START SECTION 8 -->
    <section class="lp-section section8">

      <div class="section8__content-row">
        <div class="section8__content-row--left" data-aos="fade-right">
          <div class="section8__img">
            <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cocreation_performance-durable_img.png" alt="kiprun laufschuhe">
          </div>
        </div>
        <div class="section8__content-row--right" data-aos="fade-left">
          <div class="section8__text">
              <h3 class="section8__title">DAUERHAFTE PERFORMANCE</h3>
              <p class="section8__paragraph">
                Mit den KD900X Laufschuhen, musst du dich nicht mehr zwischen Leistung und Haltbarkeit entscheiden! Sie wurden von anspruchsvollen Läufern <span>bis 1000km getestet – von einer unserer Testpersonen sogar bis 1500km!</span>
              </p>
          </div>
        </div>
      </div>

      <div class="section8__content-row">
          <div class="section8__content-row--left" data-aos="fade-right">
            <div class="section8__text section8__text2">
                <h3 class="section8__title section8__title2">Zusammenarbeit <br> mit Experten</h3>
                <p class="section8__paragraph">
                    Um ein solches Meisterwerk zu vollbringen, haben wir eng mit internen und externen Partnern
                    zusammengearbeitet.<br>
                    Durch diese internen Partnerschaften konnten wir unsere eigene Carbonplatte entwickeln und
                    aus unserer Verbindung mit Arkema entstand der VFOAM-Schaumstoff aus pebax®.
                </p>
                <div class="section8__cta-btn">
                    <a href="/laufschuhe-strasse-damen/326083-157519-laufschuhe-damen-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
                  </div>
            </div>
          </div>
          <div class="section8__content-row--right section8__content-row--right2" data-aos="fade-left">
              <div class="section8__img">
                  <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cocreation_experts_img.jpg" alt="kiprun laufschuhe">
                </div>
          </div>
        </div>

        <div class="section8__content-row section8__content-row2">
            <div class="section8__content-row--left" data-aos="fade-left">
              <div class="section8__text">
                <h3 class="section8__title">Geringes Gewicht  <br> für mehr Leistung</h3>
                <p class="section8__paragraph">
                    Forschungs- und Entwicklungsstudien haben gezeigt, dass die Leistung beim Laufen mit einem
                    leichteren Produkt und der damit einhergehenden höheren Energieeinsparung verbessert werden
                    kann. Bei der Entwicklung des Modells Kiprun KD900X gab es keinerlei Kompromisse in puncto
                    Komfort und Haltbarkeit. Wir haben leichtere Komponenten ausgewählt und das Sohlendesign
                    optimiert. 
                </p>
            </div>
            </div>
            <div class="section8__content-row--right section8__content-row--right2" data-aos="fade-right">
              <div class="section8__img">
                <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cocreation_legerete_img.jpg" alt="kiprun laufschuhe">
              </div>
            </div>
          </div>

          <div class="section8__content-row">
              <div class="section8__content-row--left section8__content-row--left2" data-aos="fade-right">
                <div class="section8__text section8__text2">
                    <h3 class="section8__title section8__title2">Erfolgreich getestet   <br> von unserem Athleten <br> Yoann Kowal</h3>
                    <p class="section8__paragraph">
                        Beim Halbmarathon in Lille am 20. März 2022 hat Yoann mit den KD900X einen persönlichen
                        Rekord von 1 h 02 min 17s aufgestellt – eine wahre Leistung bei einem offiziell anerkannten
                        Wettkampf. Yoann wusste im Hinblick auf den Rebound besonders das geringe Gewicht der Schuhe
                        zu schätzen sowie die hervorragende Bodenhaftung, insbesondere auf feuchtem Untergrund in
                        Haarnadelkurven. 
                    </p>
                    <div class="section8__cta-btn">
                        <a href="/laufschuhe-strasse-herren/326082-157513-laufschuhe-herren-kiprun-kd900x-weiss.html" >Das Produkt entdecken</a>
                      </div>
                </div>
              </div>
              <div class="section8__content-row--right section8__content-row--right2" data-aos="fade-left">
                  <div class="section8__img">
                      <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/cocreation_yoann-kowall_img.jpg" alt="kiprun laufschuhe">
                    </div>
              </div>
            </div>

    </section>
    <!-- END SECTION 8 -->

    <!-- START SECTION 9 -->
    <section class="lp-section section9">
      <div class="section9__row">
          <div class="section9__img">
              <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_1-1.jpg" alt="kiprun laufschuhe gallery">
            </div>
            <div class="section9__img">
                <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_1-2.jpg" alt="kiprun laufschuhe gallery">
              </div>
      </div>
      <div class="section9__row">
          <div class="section9__img">
              <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_2-1.jpg" alt="kiprun laufschuhe gallery">
            </div>
            <div class="section9__img">
                <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_2-2.jpg" alt="kiprun laufschuhe gallery">
              </div>
      </div>
      <!--
      <div class="section9__img">
        <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_1-1.jpg" alt="kiprun laufschuhe gallery">
      </div>
      <div class="section9__img">
        <img src="https://decathlon-source.eu/kiprun_landing_page_assets/assets/img/portfolio_2-2.jpg" alt="kiprun laufschuhe gallery">
      </div>
      -->
    </section>
    <!-- END SECTION 9 -->

  </div>
  <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>



<script>
/* ----- START HANDLE VIDEO ----- */
const videoContainer = document.getElementById("video-container");
const videoIframe = '<iframe class="section3__iframe" src="https://www.youtube.com/embed/Rbb_qN-b_QY?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
function videoHandleOnClick() {
  videoContainer.innerHTML = videoIframe;
}
videoContainer
  .querySelector(".section3__img-overlay")
  .addEventListener("click", videoHandleOnClick);
/* ----- END HANDLE VIDEO ----- */

/* ----- START ON SCROLL ANIMATION ----- */
AOS.init({
    duration: 1000
  });

/* ----- END ON SCROLL ANIMATION ----- */

</script>
    
      `);
  };

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(codeOfComponent);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <img src={props.imgUrl} className="popupImg" />
            <h2 id="transition-modal-title">{props.title}</h2>
            <p id="transition-modal-description">{props.text}</p>
            <div className="popupInputsContainer">
              <div>
                <a
                  href="https://www.decathlon.at/content/190-kiprun-kd900x"
                  target="_black"
                >
                  Preview landing page
                </a>
              </div>

              <div className="popupInputsContainer__wrapper primary-button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateCodeFunc}
                  className="primary-btn"
                >
                  Generate page Code
                </Button>
              </div>

              {generateCode && (
                <div>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={copyToClipFunc}
                  >
                    copy code
                  </button>
                  {showSuccessCopied && (
                    <div style={{ margin: "10px 0", position: "relative" }}>
                      <DoneIcon
                        style={{ color: "green", position: "absolute" }}
                        fontSize="small"
                      />
                    </div>
                  )}

                  <pre>
                    <code>{codeOfComponent}</code>
                  </pre>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
