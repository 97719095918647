import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// import external installations
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-html";
import copy from "copy-to-clipboard";
import { Icon } from "@iconify/react";

// import material ui:
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

// import images
import screenshotSimilarCategories from "../../img/similar-categories-screenshots.png";
import screenshotGroupModalCodes from "../../img/group-modal-codes-component.PNG";
import screenshotPrestashopApi from "../../img/prestashop-api.PNG";
import screenshotPrestashopApiV2 from "../../img/prestashop-api-v2.png";
import screenshotDecaStories from "../../img/decastories-screenshot.PNG";
import screenshotPromiseDeliveryMessage from "../../img/screenshot_promise_delivery_message.PNG";
import screenshotPricesLP from "../../img/price-lp-screenshot.PNG";
import screenshotCarbonLP from "../../img/carbon-lp-screenshot.PNG";

// import popups components
import GroupModalCodes from "../../generalComponents/GroupModalCodes";
import PrestaApi from "../../generalComponents/PrestaApi";
import DecaStories from "../../generalComponents/DecaStories";
import PromiseDeliveryMessage from "../../generalComponents/PromiseDeliveryMessage";
import PopupPricesLP from "../../ladningPagesPopups/PricesLP";
import PopupCarbonShoesLP from "../../ladningPagesPopups/CarbonShoes";
//---
import DeleteFeaturePopup from "../../generalPopups/DeleteFeaturePopup";

const useStylesImgList = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    //backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    //width: 500,
    // height: 450
    width: 600,
    height: 550,
  },
  icon: {
    //color: 'rgba(255, 255, 255, 0.54)',
    color: "#fff",
  },
}));

const AbTests = (props) => {
  const { user } = useAuth0();
  const classesImgList = useStylesImgList();
  const [imgData, setImgData] = useState([
    /*
    {
      img: screenshotPricesLP,
      name: "Decathlon price philosophy",
      description:
        "Explain why and how decathlon provides high-quality products with attractive prices",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "landing page",
      exampleLink: "https://www.decathlon.at/content/99-konzept-preise",
      onclickDoc: () => setOpenPricesLP(true),
      onclickExampleLink: () =>
        window.open(
          "https://www.decathlon.at/content/99-konzept-preise",
          "_blank"
        ),
    },
    {
      img: screenshotCarbonLP,
      name: "Carbon running shoes",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "landing page",
      exampleLink: "https://www.decathlon.at/content/190-kiprun-kd900x",
      onclickDoc: () => setOpenCarbonLP(true),
      onclickExampleLink: () =>
        window.open(
          "https://www.decathlon.at/content/190-kiprun-kd900x",
          "_blank"
        ),
    },
    */
  ]);

  /*
  useEffect(() => {
    if (props.userDataObjFromSheet.country === "Austria") {
      let copyOfOriginlState = [...imgData];
      copyOfOriginlState.push({
        img: screenshotDecaStories,
        title: "DecaStories",
        type: "general component",
        onclick: () => setOpenDecaStories(true),
      });
      setImgData(copyOfOriginlState);
    }
  }, []);
*/

  const [getMainCode, setGetMainCode] = useState("");
  const [mergedFeaturesData, setMergedFeaturesData] = useState([]);
  // GET EXTERNAL FEATURES UPLOADED BY USERS
  useEffect(() => {
    async function mergeExternalFeaturesToMainFeaturesData() {
      const data = await axios.get(
        process.env.REACT_APP_SHEET_BEST_EXTERNAL_FEATURE_API
      );
      const relExternalFeatures = data.data.filter(
        (e) => e.category === "a/b test"
      );
      let restructureExternalArrayData = [];
      if (relExternalFeatures.length > 0) {
        for (let i = 0; i < relExternalFeatures.length; i++) {
          restructureExternalArrayData.push({
            featureId: relExternalFeatures[i].featureId,
            img: relExternalFeatures[i].imageUrl,
            name: relExternalFeatures[i].name,
            description: relExternalFeatures[i].description,
            icon: relExternalFeatures[i].icon,
            type: relExternalFeatures[i].type,
            uploadedBy: relExternalFeatures[i].uploadedBy,
            exampleLink: relExternalFeatures[i].exampleLink,
            onclickDoc: () =>
              window.open(relExternalFeatures[i].docUrl, "_blank"),
            onclickExampleLink: () =>
              window.open(relExternalFeatures[i].exampleLink, "_blank"),
          });
          if (i === relExternalFeatures.length - 1) {
            setMergedFeaturesData([
              ...imgData,
              ...restructureExternalArrayData,
            ]);
          }
        }
      } else {
        setMergedFeaturesData(imgData);
      }
    }
    mergeExternalFeaturesToMainFeaturesData();
  }, [imgData]);

  // open/close popup states

  const [openGroupModalCode, setOpenGroupModelCode] = useState(false);
  const [openPrestaApi, setOpenPrestaApi] = useState(false);
  const [openDecaStories, setOpenDecaStories] = useState(false);
  const [openPromiseDeliveryMessage, setOpenPromiseDeliveryMessage] =
    useState(false);
  const [openPricesLP, setOpenPricesLP] = useState(false);
  const [openCarbonLP, setOpenCarbonLP] = useState(false);

  // start delete and update states
  const [openDeleteFeature, setOpenDeleteFeature] = useState(false);
  const [featureNameForDelete, setFeatureNameForDelete] = useState("");
  const [featureIdForDelete, setFeatureIdForDelete] = useState("");
  // end delete and update states

  // GENERAL STATES:
  const [codeEditorModeFull, setCodeEditorModeFull] = useState(false);

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(getMainCode);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };

  useEffect(() => {
    // console.log('getMainCode: ', getMainCode);
  }, [getMainCode]);
  // console.log('sportl3 userDataObjFromSheet: ', props.userDataObjFromSheet);

  return (
    <div className="sportLPV3 page">
      <Typography variant="h3" component="h2" gutterBottom className="title">
        A/B TESTS
      </Typography>

      <div className="sportLPV3__content">
        <div
          className="sportLPV3__components"
          style={codeEditorModeFull ? { width: "0" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            // style={{ marginBottom: "10px" }}
            style={
              codeEditorModeFull
                ? { display: "none" }
                : { marginBottom: "10px" }
            }
          >
            {/*
            <p>Available components:</p>
            */}

            {/*
                    <Button variant="contained">
                       How to use
                    </Button>
                     */}
            <ImageListItem
              key="Subheader"
              cols={2}
              style={{ height: "auto", listStyle: "none" }}
            >
              <ListSubheader component="div">
                <LiveHelpIcon style={{ color: "#0082C3", opacity: "0" }} />
              </ListSubheader>
            </ImageListItem>
          </div>

          <div className={classesImgList.root}>
            <ImageList rowHeight={180} className={classesImgList.imageList}>
              {mergedFeaturesData.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.img} alt={item.title} />
                  <ImageListItemBar
                    title={
                      <div>
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{ fontSize: "10px", marginRight: "5px" }}
                          >
                            {item.country}
                          </span>{" "}
                          <Icon icon={item.icon} />
                        </div>
                        <div style={{ marginTop: "26px" }}>{item.name}</div>
                      </div>
                    }
                    subtitle={
                      <div>
                        <span
                          style={{
                            marginBottom: "6px",
                            display: "block",
                            overflow: "auto",
                            height: "62px",
                          }}
                        >
                          {item.description}
                        </span>

                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",

                            // border: "1px solid green",
                          }}
                        >
                          {/*
                            <span
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            Uploaded by: {item.uploadedBy}
                          </span>
                        */}

                          <div
                            style={{ marginTop: "10px", marginBottom: "6px" }}
                          >
                            <LaunchIcon
                              style={{ marginRight: "8px", cursor: "pointer" }}
                              onClick={item.onclickDoc}
                            />
                            {item.exampleLink &&
                              item.exampleLink.length > 0 && (
                                <RemoveRedEyeIcon
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={item.onclickExampleLink}
                                />
                              )}

                            {user && user.email === item.uploadedBy ? (
                              <DeleteOutlineIcon
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenDeleteFeature(true);
                                  setFeatureIdForDelete(item.featureId);
                                  setFeatureNameForDelete(item.name);
                                }}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>

        <div
          className="sportLPV3__code-editor"
          style={codeEditorModeFull ? { width: "100%" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <p style={{ display: "flex", alignItems: "center" }}>
              {!codeEditorModeFull ? (
                <ChevronLeftIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              )}
              Code editor:{" "}
            </p>

            <Button
              onClick={copyToClipFunc}
              variant="contained"
              className="primary-btn"
            >
              Copy code
            </Button>

            {showSuccessCopied && (
              <div
                style={{
                  margin: "10px 0",
                  position: "absolute",
                  right: "125px",
                }}
              >
                <DoneIcon style={{ color: "green" }} fontSize="small" />
              </div>
            )}
          </div>
          <AceEditor
            mode="html"
            width="100%"
            height="700px"
            theme="monokai"
            fontSize={14}
            onChange={(e) => setGetMainCode(e)}
          />
        </div>
      </div>
      {/* start test */}
      {/*
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "60px",
        }}
      >
        {mergedFeaturesData.map((item) => (
          <Card
            sx={{ maxWidth: 345 }}
            style={{ margin: "10px", width: "300px" }}
          >
            <CardMedia
              sx={{ height: 140 }}
              image={item.img}
              title="green iguana"
            />
            <CardContent>
              <div>
                <Icon icon={item.icon} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
              <p>Uploaded by: hadar.acobas@decathlon.com</p>
            </CardContent>
            <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        ))}
      </div>
*/}

      {/* end test */}

      <div className="sportLPV3__popups">
        <GroupModalCodes
          imgUrl={screenshotGroupModalCodes}
          open={openGroupModalCode}
          handleClose={() => setOpenGroupModelCode(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PrestaApi
          imgUrl={screenshotPrestashopApiV2}
          open={openPrestaApi}
          handleClose={() => setOpenPrestaApi(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <DecaStories
          imgUrl={screenshotDecaStories}
          open={openDecaStories}
          handleClose={() => setOpenDecaStories(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PromiseDeliveryMessage
          imgUrl="https://drive.google.com/uc?export=view&id=1aQLSAuEK2ERzlAA70SFWbrhWQefaC1bG"
          open={openPromiseDeliveryMessage}
          handleClose={() => setOpenPromiseDeliveryMessage(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
          title="Promise delivery and click & collect messages"
        />
        <PopupPricesLP
          imgUrl={screenshotPricesLP}
          open={openPricesLP}
          handleClose={() => setOpenPricesLP(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
          title=""
        />
        <PopupCarbonShoesLP
          imgUrl={screenshotCarbonLP}
          open={openCarbonLP}
          handleClose={() => setOpenCarbonLP(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
          title=""
        />
        <DeleteFeaturePopup
          open={openDeleteFeature}
          handleClose={() => setOpenDeleteFeature(false)}
          name={featureNameForDelete}
          featureId={featureIdForDelete}
        />
      </div>
    </div>
  );
};

export default AbTests;
