import React, { useState } from "react";
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";

import copy from "copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "75%",
    overflowY: "auto",
    textAlign: "left",
  },
}));

export default function PopupPricesLP(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // code of component
  const [generateCode, setGenerateCode] = useState(false);
  const [codeOfComponent, setCodeOfComponent] = useState("");

  const generateCodeFunc = () => {
    setGenerateCode(true);

    setCodeOfComponent(`
    <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
    <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap"
      rel="stylesheet"
    />
    <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
    
    <style>
      /* START OVERRIDE CREATIVE ELEMENTS */
      * {
        margin: 0;
        padding: 0;
      }
      main:not(.page--sports) .page-cms {
        margin-top: 0 !important;
      }
    
      #main {
        background-color: #fff;
      }
    
      #wrapper .elementor-section.elementor-section-boxed > .elementor-container {
        max-width: none !important;
      }
      #wrapper .container {
        width: auto !important;
        max-width: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      #wrapper
        .elementor-column-gap-default
        .elementor-element-populated:not(footer .elementor-element-populated) {
        padding: 0 !important;
      }
    
      /* END OVERRIDE CREATIVE ELEMENTS */
    
      /* ---------- START GENERAl STYLES ---------- */
      #main {
        background: #fff;
        width: 100vw;
        min-height: 90vh;
      }
    
      .lp {
        width: 100vw;
        height: auto;
        /*min-height: 20000px;*/
        position: relative;
        font-family: Roboto, sans-serif;
      }
    
      .lp-section {
        min-height: 90vh;
        width: 100%;
        padding-top: 22px;
        padding-bottom: 22px;
      }
    
      /*.lp-section {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: -1;
              transition: opacity 1s ease-out;
              transition-delay: 0.6s;
              padding: 12px;
              overflow: hidden;
            }
    
            .display-section {
              opacity: 1;
              z-index: 1;
            }*/
    
      .text-container {
        /*left: 1800px;*/
        transition: left 0.93s ease;
        position: relative;
      }
      .img-container {
        /*right: 1800px;*/
        transition: right 0.93s ease;
        position: relative;
      }
    
      .display-section .text-container {
        /* left: 0;
              transition-delay: 0.4s;*/
      }
      .display-section .img-container {
        /* right: 0;
              transition-delay: 0.4s;*/
      }
      /*
      .section3 {
      background-image: linear-gradient(
      90deg,
      rgba(229, 206, 0, 1) 0%,
      rgba(255, 234, 40, 1) 35%,
      rgba(229, 206, 0, 1) 100%
      );
      }
      */
    
      .next-section-btn {
        background-color: #000;
        background-image: linear-gradient(
          90deg,
          rgba(24, 23, 23, 1) 0%,
          rgba(36, 35, 35, 1) 35%,
          rgba(24, 23, 23, 1) 100%
        );
        color: white;
        padding: 8px 16px;
        border: 1px solid white;
        border-radius: 16px;
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
      }
    
      .sections-nav {
        position: fixed;
        left: 50%;
        bottom: 6%;
        transform: translateX(-50%);
        z-index: 99999999;
        display: flex;
      }
      .sections-nav-section {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #fff;
        display: block;
        margin: 0 7px;
        opacity: 0.7;
        border: 2px solid transparent;
        cursor: pointer;
      }
      .sections-nav-section-active {
        border: 2px solid #fff !important;
        background-image: linear-gradient(
          90deg,
          rgba(24, 23, 23, 1) 0%,
          rgba(36, 35, 35, 1) 35%,
          rgba(24, 23, 23, 1) 100%
        );
      }
    
      .text-title-label {
        /* border: 1px solid #fff; */
        border-radius: 14px;
        padding: 9px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 290px;
        text-align: center;
        margin-bottom: 22px;
        margin-top: 24px;
        /* font-family: "Luckiest Guy", cursive; */
        background: #ffea28;
        color: #242323 !important;
        text-transform: uppercase;
        cursor: pointer;
        transition: background 0.4s ease-out;
        font-weight: bold;
      }
      .text-title-label:hover {
        background: #ead300;
      }
    
      .text-title {
        margin-top: 0 !important;
        /* font-family: "Luckiest Guy", cursive;*/
        position: relative;
        text-transform: uppercase;
      }
    
      .text-container {
        /*margin-top: 20px;*/
        padding: 12px;
      }
    
      .text-seperate-line {
        display: block;
        width: 100%;
        height: 1.4px;
        background-color: #fff;
        margin: 10px 0 35px 0;
      }
    
      .text-title-entry {
        font-weight: normal !important;
        display: block;
        font-size: 20px;
      }
    
      .text-title-bold {
        font-weight: bold;
        font-size: 40px;
        overflow-wrap: break-word;
      }
    
      .img-container {
        width: 100%;
        text-align: center;
      }
      .img-container img {
        width: 100%;
      }
    
      .height-indicator-top {
        position: fixed;
      }
      .height-indicator-bottom {
        position: absolute;
      }
      .height-indicator-top,
      .height-indicator-bottom {
        height: 2px;
        width: 150px;
        background-color: transparent;
        opacity: 0.7;
        left: 0;
        z-index: 9999999;
      }
    
      /* ---------- END GENERAl STYLES ---------- */
    
      /* ---------- START HERO SECTION STYLES ---------- */
      .section1 {
        background-image: linear-gradient(
          90deg,
          rgba(0, 88, 132, 1) 0%,
          rgba(0, 130, 195, 1) 35%,
          rgba(0, 88, 132, 1) 100%
        );
        /* background-image: url("https://contents.mediadecathlon.com/s806311/k$54cf2a33c30b09975d161af9810124f1/gabarit%2520black%2520friday.jpg"); */
        background-size: cover;
        color: #fff !important;
      }
      /*.section1 img {
        display: none;
      }*/
    
      .section1__text-container {
        text-align: left;
      }
    
      .text-paragraph {
        color: inherit !important;
        font-size: 16px !important;
      }
      /* ---------- END HERO SECTION STYLES ---------- */
    
      /* ---------- START SECTION 2 STYLES ---------- */
      .section2 {
        /*background-image: linear-gradient(
      90deg,
      rgba(0, 72, 118, 1) 0%,
      rgba(0, 130, 195, 1) 35%,
      rgba(0, 72, 118, 1) 100%
      );*/
        background-image: linear-gradient(
          90deg,
          rgb(88, 88, 88) 0%,
          rgb(158, 158, 158) 35%,
          rgb(83, 83, 83) 100%
        );
        color: #fff !important;
      }
    
      .section2 iframe {
        border: none;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
        width: 100%;
        height: 315px;
      }
    
      /* ---------- END SECTION 2 STYLES ---------- */
    
      /* ---------- START SECTION 3 STYLES ---------- */
      .section3 {
        /*background-image: linear-gradient(
        90deg,
        rgba(153, 8, 16, 1) 0%,
        rgba(226, 12, 24, 1) 35%,
        rgba(153, 8, 16, 1) 100%
      );*/
        background-image: linear-gradient(
          90deg,
          rgba(51, 125, 120, 1) 0%,
          rgba(78, 182, 175, 1) 35%,
          rgba(51, 125, 120, 1) 100%
        );
        color: #fff !important;
      }
    
      .section3 h3 {
        border: none;
        margin-bottom: 8px;
      }
      /* ---------- END SECTION 3 STYLES ---------- */
    
      /* ---------- START SECTION 4 STYLES ---------- */
      .section4 {
        background-image: linear-gradient(
          90deg,
          rgba(160, 69, 4, 1) 0%,
          rgba(236, 102, 7, 1) 35%,
          rgba(160, 69, 4, 1) 100%
        );
        color: #fff !important;
      }
    
      .section4__img-container img {
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
      }
    
      .section4__content-boxes {
        /*max-height: 250px;*/
        overflow: auto;
        margin: 20px 0;
        /*border: 1px solid #eee;
      border-radius: 12px;*/
    
        padding: 8px;
      }
      .section4__content-box {
        padding: 12px;
        margin: 12px 0;
      }
      .section4__content-box .section4__paragraph {
        margin: 0;
      }
      .section4__content-box h3 {
        border: 0;
        padding-left: 20px;
        font-size: 20px;
      }
    
      .section4__content-box-title-container {
        display: flex;
        align-items: center;
      }
    
      /* start scrollbar styles */
    
      /* width */
      .section4__content-boxes::-webkit-scrollbar {
        width: 10px;
        height: 90%;
      }
    
      /* Track */
      .section4__content-boxes::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
    
      /* Handle */
      .section4__content-boxes::-webkit-scrollbar-thumb {
        background: lightgray;
      }
    
      /* Handle on hover */
      .section4__content-boxes::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      /* end scrollbar styles */
    
      /* ---------- END SECTION 4 STYLES ---------- */
    
      /* ---------- START SECTION 5 STYLES ---------- */
      .section5 {
        background-image: linear-gradient(
          90deg,
          rgba(8, 120, 50, 1) 0%,
          rgba(13, 177, 75, 1) 35%,
          rgba(8, 120, 50, 1) 100%
        );
        color: #fff !important;
      }
    
      .section5__iframe-wrapper {
        position: relative;
        overflow: hidden;
        height: 0px;
        width: 0;
        padding-bottom: 96%;
        padding-right: 84%;
        margin: 0 auto;
      }
      .section5__iframe-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 230px;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
      }
      /* ---------- END SECTION 5 STYLES ---------- */
    
      /* ---------- START SECTION 6 STYLES ---------- */
      .section6 {
        background-image: linear-gradient(
          90deg,
          rgba(0, 48, 80, 1) 0%,
          rgba(0, 72, 118, 1) 35%,
          rgba(0, 48, 80, 1) 100%
        );
        color: #fff !important;
      }
    
      .section6__product-boxes {
        margin-bottom: 30px;
      }
    
      .section6__product-boxes .section6__product-boxes-section {
        display: flex;
        justify-content: space-between;
        padding: 30px;
      }
    
      .section6__product-box {
        position: relative;
        padding: 20px 0;
        width: 40%;
        text-transform: uppercase !important;
      }
    
      .section6__product-img-container {
        position: relative;
        width: 100%;
        margin: 0 auto;
      }
    
      .section6__product-box-img-price-label {
        display: inline-block;
        position: absolute;
        right: -26px;
        top: -10px;
      }
    
      .section6__product-box-img-price-label--content {
        width: 64px;
        height: 36px;
        background: linear-gradient(
          to right top,
          #fdd835 0%,
          #fdd835 40%,
          #ffea28 60%
        );
        padding: 2px;
        display: flex;
        justify-content: center;
        /*align-items: center;*/
        color: #242323 !important;
      }
    
      .section6__product-box-img-price-label--price {
        font-size: 26px !important;
        font-weight: bold !important;
        color: #242323 !important;
      }
    
      .section6__product-box-img-price-label--deci-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    
      .section6__product-box-img-price-label--additional-elem-background {
        background: linear-gradient(
          to right top,
          #fdd835 0%,
          #fdd835 40%,
          #ffea28 60%
        );
        height: 17px;
        width: 14px;
        clip-path: polygon(9% 0, 0 88%, 100% 0);
        position: relative;
        top: -2px;
        right: 1px;
      }
    
      .section6__product-box-img-price-label--deci-container span {
        font-size: 12px !important;
        line-height: 0.9 !important;
        color: #242323 !important;
      }
    
      .section6__product-img {
        width: 100%;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
        cursor: pointer;
      }
    
      .section6__product-brand {
        display: block;
        font-weight: bold !important;
        font-size: 16px !important;
        text-align: left;
      }
    
      .section6__product-name {
        display: block;
        max-width: 200px;
        font-size: 14px !important;
        text-align: left;
      }
    
      .section6__product-box img {
        transition: all 0.4s ease-out;
      }
    
      .section6__product-box:hover img {
        transform: scale(1.04);
      }
    
      /* ---------- END SECTION 6 STYLES ---------- */
    
      @media (min-width: 400px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /* min-height: 730px;*/
        }
        /* ---------- END GENERAl STYLES ---------- */
      }
    
      @media (min-width: 580px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /* min-height: 786px;*/
        }
        /* ---------- END GENERAl STYLES ---------- */
      }
    
      @media (min-width: 760px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /*min-height: 615px;*/
        }
        .lp-section {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-evenly;
          padding: 100px 12px 0 12px;
        }
    
        .img-container,
        .text-container {
          width: 45%;
        }
    
        .text-container {
          padding-right: 35px;
        }
        /* ---------- END GENERAl STYLES ---------- */
      }
    
      @media (min-width: 900px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /*min-height: 530px;*/
        }
        /* ---------- END GENERAl STYLES ---------- */
      }
    
      @media (min-width: 1200px) {
        /* ---------- START SECTION 2 STYLES ---------- */
        .section2 iframe {
          width: 100%;
          height: 415px;
        }
        /* ---------- END SECTION 2 STYLES ---------- */
    
        /* ---------- END SECTION 5 STYLES ---------- */
        .section5__iframe-wrapper iframe {
          height: 350px;
        }
        /* ---------- END SECTION 5 STYLES ---------- */
      }
    
      @media (min-width: 1500px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /*min-height: 630px;*/
        }
        /* ---------- END GENERAl STYLES ---------- */
      }
    
      @media (min-width: 1800px) {
        /* ---------- START GENERAl STYLES ---------- */
        .lp {
          /*min-height: 960px;*/
        }
        .lp-section {
          padding: 200px 12px 0 12px;
        }
    
        .img-container,
        .text-container {
          width: 40%;
        }
    
        .text-title-entry {
          font-size: 52px;
        }
    
        .text-title-bold {
          font-size: 77px;
        }
    
        .text-paragraph {
          font-size: 18px !important;
        }
    
        .img-container img {
          width: 80%;
        }
        /* ---------- END GENERAl STYLES ---------- */
    
        /* ---------- START SECTION 1 STYLES ---------- */
        .section1__text-container {
          margin-top: 60px;
        }
        /* ---------- END SECTION 1 STYLES ---------- */
        /* ---------- START SECTION 2 STYLES ---------- */
        .section2 iframe {
          width: 100%;
          height: 560px;
        }
        /* ---------- END SECTION 2 STYLES ---------- */
    
        /* ---------- START SECTION 5 STYLES ---------- */
        .section5__iframe-wrapper iframe {
          height: 468px;
        }
        /* ---------- END SECTION 5 STYLES ---------- */
      }
    </style>
    
    <div class="lp">
      <!-- START FIXED MENU-->
      <!--
    
    -->
      <div class="sections-nav" style="display: none">
        <span
          class="sections-nav-section sections-nav-section-active"
          id="nav-btn-1"
        ></span>
        <span class="sections-nav-section" id="nav-btn-2"></span>
        <span class="sections-nav-section" id="nav-btn-3"></span>
        <span class="sections-nav-section" id="nav-btn-4"></span>
        <span class="sections-nav-section" id="nav-btn-5"></span>
        <span class="sections-nav-section" id="nav-btn-6"></span>
      </div>
      <!-- END FIXED MENU -->
    
      <!-- START FIXED HEIGHT INDICATOR -->
      <div class="height-indicator-top"></div>
      <div class="height-indicator-bottom"></div>
      <!-- END FIXED HEIGHT INDICATOR -->
    
      <!-- START SECTION 1 -->
      <div class="lp-section section1 display-section">
        <div class="text-container section1__text-container">
          <h1 class="text-title section1__title">
            <span class="text-title-bold section1__title--bold">Qualität & Vertrauen</span>
            <span class="text-title-entry section1__title--entry">zu einem unschlagbaren Preis!</span>
     
          </h1>
          <span class="text-seperate-line"></span>
          <p class="text-paragraph section1__paragraph">
            Hinter unseren unschlagbaren Preisen steckt viel Arbeit! Erfahre, wie
            wir es schaffen, das gesamte Jahr günstige Produkte anzubieten und warum
            wir auf Rabattaktionen größtenteils verzichten.
          </p>
    
          <a href="#" style="text-decoration: none; display: none">
            <div class="text-title-label section1__title-label" id="btn1">
              <span>wie erreichen wir diese Preise?</span>
            </div>
          </a>
    
          <div
            class="section1__scroll-down-icon-container"
            style="margin-top: 20px"
          >
            <lord-icon
              src="https://cdn.lordicon.com/xwjtkymn.json"
              trigger="loop"
              colors="primary:#ffffff,secondary:#ffffff"
              style="width: 70px; height: 70px"
            >
            </lord-icon>
            <span style="display: block; color: #fff; padding-left: 10px"
              >Scrollen</span
            >
          </div>
        </div>
        <div class="img-container section1__img-container">
          <img
            src="https://decathlon-source.eu/general-images/black-friday-products-trnsparent-backgroundd.png"
            alt="Decathlon Produkte"
          />
        </div>
      </div>
      <!-- END SECTION 1 -->
    
      <!-- START SECTION 2 -->
      <div class="lp-section section2" data-aos="fade-up">
        <div class="text-container section2__text-container">
          <h1 class="text-title section2__title">
            <span class="text-title-entry section2__title--entry"
              >warum verzichten wir auf
            </span>
            <span class="text-title-bold section2__title--bold"
              >rabatt&shy;aktionen?</span
            >
          </h1>
          <span class="text-seperate-line"></span>
    
          <p class="text-paragraph section2__paragraph">
            Unser ständiges Ziel ist es, dass jede*r Spaß dabei hat, Sport zu
            machen. Dafür bieten wir unzählige Sportprodukte für über 70 Sportarten
            an und halten unsere Preise das gesamte Jahr so gering wie möglich.
            Daher verzichten wir auf gezielte Rabattaktionen und haben uns zum Ziel
            gesetzt, dass du bei DECATHLON immer das beste Preis-Leistungs-Angebot
            für deine Sportart erhältst.
          </p>
    
          <p class="text-paragraph section2__paragraph">
            In unserem Angebot findest du dennoch reduzierte Artikel! Für Produkte,
            die nicht mehr nachproduziert werden, reduzieren wir teilweise die
            Preise, um in unseren Lagern Platz für neue innovative Produkte zu
            schaffen.
          </p>
    
          <a href="/7456-sale" style="text-decoration: none">
            <div class="text-title-label section2__title-label" id="btn2">
              <span>zur auslaufzone</span>
            </div>
          </a>
        </div>
        <div class="img-container section2__img-container">
          <iframe src="https://www.youtube.com/embed/v43pfSxxdlM?cc_load_policy=1">
          </iframe>
        </div>
      </div>
      <!-- END SECTION 2 -->
    
      <!-- START SECTION 3 -->
      <div class="lp-section section3" data-aos="fade-up">
        <div class="text-container section3__text-container">
          <h1 class="text-title section3__title">
            <span class="text-title-entry section3__title--entry">Unsere </span>
            <span class="text-title-bold section3__title--bold">Eigenmarken</span>
          </h1>
          <span class="text-seperate-line"></span>
          <h3>
            WIE SCHAFFEN WIR ES, GÜNSTIGE SPORTPRODUKTE MIT EINER HOHEN QUALITÄT
            ANZUBIETEN?
          </h3>
          <p class="text-paragraph section3__paragraph">
            Durch unsere große Anzahl an Eigenmarken ist die gesamte
            Wertschöpfungskette einer Hand: Von der Forschung, über die Logistik
            bis hin zum Verkauf. Dadurch ergeben sich hohe Einsparungen, die wir
            direkt an dich als unsere*n Kunden*in weitergeben.
          </p>
    
          <a href="#" style="text-decoration: none; display: none">
            <div class="text-title-label section3__title-label" id="btn3">
              <span>die gesamte wertschöpfungskette</span>
            </div>
          </a>
        </div>
        <div class="img-container section3__img-container">
          <img
            src="https://contents.mediadecathlon.com/s963922/k$68258557ecfefb0e7031b17149551ba1/brands%20decathlon.png?format=auto"
            alt="Decathlon Eigenmarke"
          />
        </div>
      </div>
      <!-- END SECTION 3 -->
    
      <!-- START SECTION 4 -->
      <div class="lp-section section4" data-aos="fade-up">
        <div class="text-container section4__text-container">
          <h1 class="text-title section4__title">
            <span class="text-title-entry section4__title--entry">Unsere</span>
            <span class="text-title-bold section4__title--bold"
              >wert<wbr>schöpfungs&shy;kette</span
            >
          </h1>
          <span class="text-seperate-line"></span>
    
          <div class="section4__content-boxes">
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/hgbzryoa.json"
                  trigger="hover"
                  colors="primary:#b26836,secondary:#0082c3,tertiary:#ebe6ef,quaternary:#ebe6ef"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Forschung</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Ob in den Bergen oder am und im Wasser: Wir analysieren, entwickeln
                und testen unsere Produkte da, wo sie ihren Ursprung haben.
              </p>
            </div>
    
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/bxxnzvfm.json"
                  trigger="hover"
                  colors="primary:#242323,secondary:#ffea28,tertiary:#f9c9c0,quaternary:#f2e2d9"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Design</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Unsere Designer denken beim Gestalten auch schon an den Preis,
                um qualitativ hochwertige und ansprechende Produkte zu günstigen
                Preisen zu entwerfen.
              </p>
            </div>
    
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/shiwicyv.json"
                  trigger="hover"
                  colors="primary:#0082c3,secondary:#ffea28,tertiary:#ebe6ef"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Konzeption</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Wir suchen immer nach den Materialien mit dem besten
                Preis-Leistungs-Verhältnis - ohne Abstriche bei der Qualität zu
                machen.
              </p>
            </div>
    
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/igqtmmix.json"
                  trigger="hover"
                  colors="primary:#242323,secondary:#0082c3,tertiary:#b26836,quaternary:#0db14b,quinary:#ffea28"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Produktion</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Wir optimieren unsere Prozesse ständig. Um Kosten zu reduzieren,
                arbeiten wir effektiv in Teams und überspringen überflüssige
                Arbeitsschritte.
              </p>
            </div>
    
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/iejknaed.json"
                  trigger="hover"
                  colors="outline:#121331,primary:#004876,secondary:#ffea28,tertiary:#ebe6ef,quaternary:#646e78"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Logistik</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Da auch die Logistik ein Teil unseres Unternehmens ist, können wir
                Kosten einsparen, z.B. bei den Transportkosten, Kartonagen und der
                Lagerhaltung.
              </p>
            </div>
    
            <div class="section4__content-box">
              <div class="section4__content-box-title-container">
                <lord-icon
                  src="https://cdn.lordicon.com/oaflahpk.json"
                  trigger="hover"
                  colors="primary:#FFEA28"
                  style="width: 60px; height: 60px"
                >
                </lord-icon>
                <h3>Unsere Filialen</h3>
              </div>
              <p class="text-paragraph section4__paragraph">
                Und auch in unseren Filialen verbessern wir ständig jedes Detail:
                von der Bodenbeschichtung über die Beleuchtung bis hin zur
                effizienten Einordnung der Ware.
              </p>
            </div>
          </div>
    
          <a href="#" style="text-decoration: none; display: none">
            <div class="text-title-label section4__title-label" id="btn4">
              <span>günstige preise und umwelt&shy;schutz?</span>
            </div>
          </a>
        </div>
        <div class="img-container section4__img-container">
          <img
            src="https://contents.mediadecathlon.com/s859025/k$10014ad6b7ae845d377b4366bcaba2e8/st%2520store%2520bike%2520challenge%2520hk.jpg?format=auto&quality=60&f=700x0"
            alt="Decathlon Filialen"
          />
        </div>
      </div>
      <!-- END SECTION 4 -->
    
      <!-- START SECTION 5 -->
      <div class="lp-section section5" data-aos="fade-up">
        <div class="text-container section5__text-container">
          <h1 class="text-title section5__title">
            <span class="text-title-entry section5__title--entry"
              >günstige preise und
            </span>
            <span class="text-title-bold section5__title--bold">umweltschutz?</span>
          </h1>
          <span class="text-seperate-line"></span>
    
          <p class="text-paragraph section5__paragraph">
            Uns ist natürlich bewusst, dass die Herstellung von Sportprodukten immer
            eine Auswirkung auf unser gemeinsames Spielfeld - die Umwelt hat. Um
            dieses Spielfeld zu schützen, haben wir uns zum Ziel gesetzt, diese
            Auswirkungen zu verringern und gleichzeitig zu garantieren, dass die
            Qualität und Funktionalität unserer Produkte erhalten bleibt. Dieses
            Ziel verfolgen wir mit unserer steigenden Anzahl an Eco-Design
            Produkten.
          </p>
    
          <a href="/content/160-ecodesign" style="text-decoration: none">
            <div class="text-title-label section5__title-label" id="btn5">
              <span>Eco-Design Produkte</span>
            </div>
          </a>
        </div>
        <div class="img-container section5__img-container">
          <div class="section5__iframe-wrapper">
            <iframe
              src="https://players.brightcove.net/3415345270001/DOHosj6Wy_default/index.html?videoId=6156006754001"
              allowfullscreen
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
      <!-- END SECTION 5 -->
    
      <!-- START SECTION 6 -->
      <div class="lp-section section6" data-aos="fade-up">
        <div class="text-container section6__text-container">
          <h1 class="text-title section6__title">
            <span class="text-title-entry section6__title--entry"
              >gute qualität muss nicht immer</span
            >
            <span class="text-title-bold section6__title--bold">teuer sein!</span>
          </h1>
          <span class="text-seperate-line"></span>
          <p class="text-paragraph section6__paragraph">
            Hier findest du einige Produkte, die bestätigen, dass eine hohe Qualität
            nicht mit einem hohen Preis verbunden sind.
          </p>
    
          <div class="text-title-label section6__title-label">
            <span
              ><a
                style="text-decoration: none; color: inherit"
                href="/content/89-alle-sportarten"
                >Finde deine Sportart</a
              ></span
            >
          </div>
        </div>
        <div class="img-container section6__img-container">
          <div class="section6__product-boxes">
            <div class="section6__product-boxes-section">
              <div class="section6__product-box">
                <div class="section6__product-img-container">
                  <div class="section6__product-box-img-price-label">
                    <div class="section6__product-box-img-price-label--content">
                      <p class="section6__product-box-img-price-label--price">3</p>
                      <div
                        class="section6__product-box-img-price-label--deci-container"
                      >
                        <span>€</span>
                        <span>99</span>
                      </div>
                    </div>
                    <div
                      class="section6__product-box-img-price-label--additional-elem-background"
                    ></div>
                  </div>
                  <a
                    href="/rucksacke-tagestour/301684-38950-wanderrucksack-naturwandern-nh100-10-liter-khaki.html"
                  >
                    <img
                      class="section6__product-img"
                      src="https://decathlon-source.eu/general-images/bf-products-bg-removed/8502157-removebg-preview.png"
                    />
                  </a>
                </div>
    
                <span class="section6__product-brand">quechua</span>
                <span class="section6__product-name"
                  >wanderrucksack nh100 arpenaz 10 l luxurious</span
                >
              </div>
    
              <div class="section6__product-box">
                <div class="section6__product-img-container">
                  <div class="section6__product-box-img-price-label">
                    <div class="section6__product-box-img-price-label--content">
                      <p class="section6__product-box-img-price-label--price">24</p>
                      <div
                        class="section6__product-box-img-price-label--deci-container"
                      >
                        <span>€</span>
                        <span>99</span>
                      </div>
                    </div>
                    <div
                      class="section6__product-box-img-price-label--additional-elem-background"
                    ></div>
                  </div>
                  <a
                    href="/bauchtrainer/311440-66593-bauchtrainer-bauchroller-ab-wheel-erweiterbar-mit-matte.html"
                  >
                    <img
                      class="section6__product-img"
                      src="https://decathlon-source.eu/general-images/bf-products-bg-removed/8556755-removebg-preview.png"
                    />
                  </a>
                </div>
    
                <span class="section6__product-brand">corength</span>
                <span class="section6__product-name"
                  >bauchtrainer bauchroller ab wheel erweiterbar mit elastischem
                  riemen</span
                >
              </div>
            </div>
    
            <div class="section6__product-boxes-section">
              <div class="section6__product-box">
                <div class="section6__product-img-container">
                  <div class="section6__product-box-img-price-label">
                    <div class="section6__product-box-img-price-label--content">
                      <p class="section6__product-box-img-price-label--price">69</p>
                      <div
                        class="section6__product-box-img-price-label--deci-container"
                      >
                        <span>€</span>
                        <span>99</span>
                      </div>
                    </div>
                    <div
                      class="section6__product-box-img-price-label--additional-elem-background"
                    ></div>
                  </div>
                  <a
                    href="/boxsacke-hangend/337254-164322-standboxsack-boxing-machine-100-aufblasbar-kinder.html"
                  >
                    <img
                      class="section6__product-img"
                      src="https://contents.mediadecathlon.com/s977298/k$1098cb490d08c44b38c5341994596d31/Outshock%20Boxsack.png?format=auto"
                    />
                  </a>
                </div>
    
                <span class="section6__product-brand">outshock</span>
                <span class="section6__product-name"
                  >STANDBOXSACK BOXING MACHINE 100 KINDER JUSTICE LEAGUE
                  </span
                >
              </div>
    
              <div class="section6__product-box">
                <div class="section6__product-img-container">
                  <div class="section6__product-box-img-price-label">
                    <div class="section6__product-box-img-price-label--content">
                      <p class="section6__product-box-img-price-label--price">
                        229
                      </p>
                      <div
                        class="section6__product-box-img-price-label--deci-container"
                      >
                        <span>€</span>
                        <span>99</span>
                      </div>
                    </div>
                    <div
                      class="section6__product-box-img-price-label--additional-elem-background"
                    ></div>
                  </div>
                  <a
                    href="/3-mann-zelte/313048-141466-wurfzelt-2seconds-easy-freshblack-fur-3personen.html"
                  >
                    <img
                      class="section6__product-img"
                      src="https://decathlon-source.eu/general-images/bf-products-bg-removed/8576110-removebg-preview.png"
                    />
                  </a>
                </div>
    
                <span class="section6__product-brand">quechua</span>
                <span class="section6__product-name"
                  >faltzelt fresh & black - 2 seconds easy für 3 personen</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END SECTION 6 -->
    </div>
    
    <script>
      /* ----- START ON SCROLL ANIMATION ----- */
      AOS.init({
        duration: 1300,
      });
    
      /* ----- END ON SCROLL ANIMATION ----- */
    </script>
    
      `);
  };

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(codeOfComponent);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <img src={props.imgUrl} className="popupImg" />
            <h2 id="transition-modal-title">{props.title}</h2>
            <p id="transition-modal-description">{props.text}</p>
            <div className="popupInputsContainer">
              <div>
                Explain why and how decathlon provides high-quality products
                with attractive prices
              </div>
              <div>
                <a
                  href="https://www.decathlon.at/content/99-konzept-preise"
                  target="_black"
                >
                  Preview landing page
                </a>
              </div>

              <div className="popupInputsContainer__wrapper primary-button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateCodeFunc}
                  className="primary-btn"
                >
                  Generate page Code
                </Button>
              </div>

              {generateCode && (
                <div>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={copyToClipFunc}
                  >
                    copy code
                  </button>
                  {showSuccessCopied && (
                    <div style={{ margin: "10px 0", position: "relative" }}>
                      <DoneIcon
                        style={{ color: "green", position: "absolute" }}
                        fontSize="small"
                      />
                    </div>
                  )}

                  <pre>
                    <code>{codeOfComponent}</code>
                  </pre>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
