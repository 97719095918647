import React, { useState } from "react";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextareaAutosize,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const categories = [
  "home page",
  "sport page",
  "category/search",
  "product page",
  "landing page",
  "a/b test",
  "tutorial",
  "checkout",
];

const AddExternalFeature = (props) => {
  // general states
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // form states
  const [category, setCategory] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [exampleLink, setExampleLink] = useState("");
  const [screenshot, setScreenshot] = useState(null);
  console.log("props.userDataObjFromSheet: ", props.userDataObjFromSheet);
  /*
  const handleSubmit = async (e) => {
    e.preventDefault();


    // Here you can handle the form submission and store the values in the desired way
    // For now, let's just log the values to the console
    console.log({
      category,
      featureName,
      featureDescription,
      docUrl,
      screenshot,
    });
  };
*/

  // handle country name for icon
  function returnCountryNameFormatted(country) {
    if (country.split(" ").length > 1) {
      let finalCountryName = "";
      const arr = country.toLowerCase().split(" ");
      for (let i = 0; i < arr.length; i++) {
        finalCountryName += arr[i];
        if (i !== arr.length - 1) {
          finalCountryName += "-";
        }
      }
      return finalCountryName;
    } else {
      return country.toLowerCase();
    }
  }

  const handleSubmitStoreImageAndGetUrl = async (e) => {
    e.preventDefault();
    // validate form
    const isValid = validateForm();

    if (!isValid) {
      alert("category, name, doc url and image are required");
    } else {
      // set loading to true
      setFormIsLoading(true);

      // Create form submit image to cloudinary
      const formDataCloudinary = new FormData();
      formDataCloudinary.append("file", screenshot);
      formDataCloudinary.append("upload_preset", "ml_default");
      /*formDataCloudinary.append(
      "cloud_name",
      process.env.REACT_APP_CLOUDINARY_NAME
    );*/

      try {
        // Make a POST request to the API endpoint for form submission and image upload
        const responseCloudinary = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`,
          formDataCloudinary,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle the response as needed (e.g., show a success message)
        console.log(
          "Form submitted successfully",
          responseCloudinary.data.secure_url
        );

        const dataObjForSheet = {
          featureId: new Date().getTime(),
          category: category,
          name: featureName,
          description: featureDescription,
          docUrl: docUrl,
          imageUrl: responseCloudinary.data.secure_url,
          country: props.userDataObjFromSheet.country,
          icon: `twemoji:flag-${returnCountryNameFormatted(
            props.userDataObjFromSheet.country
          )}`,
          uploadedBy: props.userDataObjFromSheet.email,
          exampleLink: exampleLink,
        };
        const responseSheet = await axios.post(
          process.env.REACT_APP_SHEET_BEST_EXTERNAL_FEATURE_API,
          dataObjForSheet
        );
        console.log("responseSheet: ", responseSheet);
        if (responseSheet.status === 200) {
          formSubmittedSuccessfulyClearInputs();
        } else {
          alert("Something went wrong!");
        }
        //setScreenshot(response.data.secure_url);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error submitting form", error);
        alert("Something went wrong!");
      }
    }
  };

  const validateForm = () => {
    if (
      category === "" ||
      featureName === "" ||
      docUrl === "" ||
      screenshot === null
    ) {
      return false;
    } else {
      return true;
    }
  };

  const formSubmittedSuccessfulyClearInputs = () => {
    // set loading to false
    setFormIsLoading(false);

    // display success message for a 3 sec
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);

    // clean inputs
    setCategory("");
    setFeatureName("");
    setFeatureDescription("");
    setDocUrl("");
    setExampleLink("");
  };

  return (
    <div className="add-external-feature page">
      <Typography variant="h3" component="h2" gutterBottom className="title">
        share feature
      </Typography>
      <form onSubmit={handleSubmitStoreImageAndGetUrl}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Choose Category (required)</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "8px 0 4px 0", color: "gray" }}>
              Name your feature
            </div>
            <TextField
              fullWidth
              label="Name (required)"
              value={featureName}
              onChange={(e) => setFeatureName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "8px 0 4px 0", color: "gray" }}>
              Keep it short and straightforward. For example, "As a customer, I
              can _________________", or "As a web marketer, I can
              ____________________"
            </div>
            <TextField
              fullWidth
              label="Description"
              value={featureDescription}
              onChange={(e) => setFeatureDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "8px 0 4px 0", color: "gray" }}>
              Document your feature in an external doc and share the link here
            </div>
            <TextField
              fullWidth
              label="Documentation URL (required)"
              value={docUrl}
              onChange={(e) => setDocUrl(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "8px 0 4px 0", color: "gray" }}>
              Add an example link (if the feature is live)
            </div>
            <TextField
              fullWidth
              label="Example Link"
              value={exampleLink}
              onChange={(e) => setExampleLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "8px 0 4px 0", color: "gray" }}>
              Upload an image/screenshot of your feature (required)
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setScreenshot(e.target.files[0])}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="primary-btn"
              style={formIsLoading ? { display: "none" } : { display: "block" }}
            >
              Save feature
            </Button>
            <div
              className="add-external-feature__loading"
              style={formIsLoading ? { display: "block" } : { display: "none" }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </div>
            <div
              className="add-external-feature__success"
              style={
                showSuccessMessage ? { display: "block" } : { display: "none" }
              }
            >
              Successfully submitted 🙂
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddExternalFeature;
