/*
  <div className="home__container">
          <div className="home__left">
            <div className="home__icon-mobile">
              <lord-icon
                src="https://cdn.lordicon.com/jnlncdtk.json"
                trigger="hover"
                colors="primary:#004876,secondary:#ffea28,tertiary:#0082c3,quaternary:#f3c3ba,quinary:#e7e2eb"
                style={{ width: "180px", height: "180px", marginTop: "-40px" }}
              ></lord-icon>
            </div>
            <div className="home__icon-desktop">
              <lord-icon
                src="https://cdn.lordicon.com/jnlncdtk.json"
                trigger="hover"
                colors="primary:#004876,secondary:#ffea28,tertiary:#0082c3,quaternary:#f3c3ba,quinary:#e7e2eb"
                style={{ width: "380px", height: "380px", marginTop: "-40px" }}
              ></lord-icon>
            </div>
          </div>
          <div className="home__right">
            <Updates />

            <Typography
              variant="h4"
              component="h4"
              gutterBottom
              className="title welcome-title"
            >
              Now you can contribute to growing the OneFront library
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              gutterBottom
              className="title welcome-subtitle"
            >
              Share with other countries features, components, or pages
              developed in {props.userDataObjFromSheet.country}.
            </Typography>
            <Link to="/share-feature">
              <Button
                variant="contained"
                className="primary-btn"
                style={{ marginTop: "12px", minWidth: "200px" }}
              >
                Share now
              </Button>
            </Link>
          </div>
        </div>
*/

import React from "react";
import "./index.css";
import HpComponentsPage from "../HpComponentsPage";
import Updates from "../Updates";
import MainIllu from "../../img/main-illu.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const Home = (props) => {
  // console.log('userHasAllDataInSheetBool home: ', props.userHasAllDataInSheetBool);
  return (
    <div className="home page">
      {props.userHasAllDataInSheetBool ? (
        <div className="home__container2">
          {/*
                    <Typography
            variant="h4"
            component="h4"
            gutterBottom
            className="title welcome-title"
          >
            Reskin new design
          </Typography>
          */}

          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="title welcome-title"
          >
            OneFront is a collaborative effort among e-commerce teams in OneShop
            countries, focusing on front-end components and local features. It
            is an initiative by the countries and{" "}
            <span style={{ fontSize: "22px" }}>NOT</span> an official solution
            from OneShop. Each country is responsible for the code it adds to
            its website, and the developer who shared the code is not obligated
            to provide updates or support.
          </Typography>

          <div style={{ marginTop: "20px" }}>
            {/*
             <Updates />
            */}
          </div>
        </div>
      ) : (
        <div className="home-settings-missing-message">
          <lord-icon
            src="https://cdn.lordicon.com/tdrtiskw.json"
            trigger="loop"
            colors="primary:#0082c3,secondary:#ffea28"
            style={{ width: "250px", height: "250px" }}
          ></lord-icon>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            className="title"
          >
            To use the app please complete the settings
          </Typography>
          <div className="primary-button-container home-settings-missing-message__btn-container">
            <Link
              style={{ color: "#fff", textDecoration: "none" }}
              to="/settings"
            >
              <Button variant="contained">Set up now</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
