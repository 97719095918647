import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
// import external installations
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-html";
import copy from "copy-to-clipboard";
import { Icon } from "@iconify/react";

// import material ui:
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// import images
import screenshopHpBanner1 from "../../img/hp-banner1-desktop-and-mobile.png";
import screenshopHpBanner3 from "../../img/banner3.png";
import screenshotAustriaHp from "../../img/hp-austria-screenshot.PNG";

// import popups components
import PopupHpBanner1 from "../../hpPopupsComponents/PopupBanner1";
import PopupHpBanner2 from "../../hpPopupsComponents/PopupBanner2";
import PopupHpBanner3 from "../../hpPopupsComponents/PopupBanner3";
import DeleteFeaturePopup from "../../generalPopups/DeleteFeaturePopup";

const useStylesImgList = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    //backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    //width: 500,
    // height: 450
    width: 600,
    height: 550,
  },
  icon: {
    //color: 'rgba(255, 255, 255, 0.54)',
    color: "#fff",
  },
}));

const HpComponents = (props) => {
  const { user } = useAuth0();
  const classesImgList = useStylesImgList();
  const [imgData, setImgData] = useState([
    /* {
      img: screenshopHpBanner1,
      title: "Banner 1",
      type: "display 3 categories",
      onclick: () => setOpenHpBanner1(true),
    },
    {
      img: screenshopHpBanner1,
      title: "Banner 2",
      type: "display 6 categories (3 X 2)",
      onclick: () => setOpenHpBanner2(true),
    },
    {
      img: screenshopHpBanner3,
      title: "Banner 3",
      type: "blue banner (text+icon X 3)",
      onclick: () => setOpenHpBanner3(true),
    },*/

    {
      img: screenshotAustriaHp,
      name: "HP Austria",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "home page",
      exampleLink: "https://www.decathlon.at",
      onclickDoc: () =>
        window.open(
          "https://docs.google.com/document/d/1I0Z23wfSH52EDQ--B38SEcAfwt2TxUlW_jb4fCHq0sU/edit?usp=sharing",
          "_blank"
        ),
      onclickExampleLink: () =>
        window.open("https://www.decathlon.at", "_blank"),
    },
  ]);

  const [getMainCode, setGetMainCode] = useState("");
  const [mergedFeaturesData, setMergedFeaturesData] = useState([]);

  // GET EXTERNAL FEATURES UPLOADED BY USERS
  useEffect(() => {
    async function mergeExternalFeaturesToMainFeaturesData() {
      const data = await axios.get(
        process.env.REACT_APP_SHEET_BEST_EXTERNAL_FEATURE_API
      );
      const relExternalFeatures = data.data.filter(
        (e) => e.category === "home page"
      );
      let restructureExternalArrayData = [];
      if (relExternalFeatures.length > 0) {
        for (let i = 0; i < relExternalFeatures.length; i++) {
          restructureExternalArrayData.push({
            featureId: relExternalFeatures[i].featureId,
            img: relExternalFeatures[i].imageUrl,
            name: relExternalFeatures[i].name,
            description: relExternalFeatures[i].description,
            icon: relExternalFeatures[i].icon,
            type: relExternalFeatures[i].type,
            uploadedBy: relExternalFeatures[i].uploadedBy,
            exampleLink: relExternalFeatures[i].exampleLink,
            onclickDoc: () =>
              window.open(relExternalFeatures[i].docUrl, "_blank"),
            onclickExampleLink: () =>
              window.open(relExternalFeatures[i].exampleLink, "_blank"),
          });
          if (i === relExternalFeatures.length - 1) {
            setMergedFeaturesData([
              ...imgData,
              ...restructureExternalArrayData,
            ]);
          }
        }
      } else {
        setMergedFeaturesData(imgData);
      }
    }
    mergeExternalFeaturesToMainFeaturesData();
  }, [imgData]);

  // open/close popup states

  const [openHpBanner1, setOpenHpBanner1] = useState(false);

  const [openHpBanner2, setOpenHpBanner2] = useState(false);

  const [openHpBanner3, setOpenHpBanner3] = useState(false);

  const [openAustriaHp, setOpenAustriaHp] = useState(false);

  // start delete and update states
  const [openDeleteFeature, setOpenDeleteFeature] = useState(false);
  const [featureNameForDelete, setFeatureNameForDelete] = useState("");
  const [featureIdForDelete, setFeatureIdForDelete] = useState("");
  // end delete and update states

  // GENERAL STATES:
  const [codeEditorModeFull, setCodeEditorModeFull] = useState(false);

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(getMainCode);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };

  useEffect(() => {
    // console.log('getMainCode: ', getMainCode);
  }, [getMainCode]);
  // console.log('sportl3 userDataObjFromSheet: ', props.userDataObjFromSheet);
  return (
    <div className="sportLPV3 page">
      <Typography variant="h3" component="h2" gutterBottom className="title">
        home page
      </Typography>

      <div className="sportLPV3__content">
        <div
          className="sportLPV3__components"
          style={codeEditorModeFull ? { width: "0" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            style={
              codeEditorModeFull
                ? { display: "none" }
                : { marginBottom: "10px" }
            }
          >
            {/*
             <p>Available components:</p>
            */}

            {/*
                    <Button variant="contained">
                       How to use
                    </Button>
                     */}
            <ImageListItem
              key="Subheader"
              cols={2}
              style={{ height: "auto", listStyle: "none" }}
            >
              <ListSubheader component="div">
                <LiveHelpIcon style={{ color: "#0082C3", opacity: "0" }} />
              </ListSubheader>
            </ImageListItem>
          </div>

          <div className={classesImgList.root}>
            <ImageList rowHeight={180} className={classesImgList.imageList}>
              {mergedFeaturesData.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.img} alt={item.title} />
                  <ImageListItemBar
                    title={
                      <div>
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{ fontSize: "10px", marginRight: "5px" }}
                          >
                            {item.country}
                          </span>{" "}
                          <Icon icon={item.icon} />
                        </div>
                        <div style={{ marginTop: "26px" }}>{item.name}</div>
                      </div>
                    }
                    subtitle={
                      <div>
                        <span
                          style={{
                            marginBottom: "6px",
                            display: "block",
                            overflow: "auto",
                            height: "62px",
                          }}
                        >
                          {item.description}
                        </span>

                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",

                            // border: "1px solid green",
                          }}
                        >
                          {/*
                           <span
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            Uploaded by: {item.uploadedBy}
                          </span>
                          */}

                          <div
                            style={{ marginTop: "10px", marginBottom: "6px" }}
                          >
                            <LaunchIcon
                              style={{ marginRight: "8px", cursor: "pointer" }}
                              onClick={item.onclickDoc}
                            />
                            {item.exampleLink &&
                              item.exampleLink.length > 0 && (
                                <RemoveRedEyeIcon
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={item.onclickExampleLink}
                                />
                              )}

                            {user && user.email === item.uploadedBy ? (
                              <DeleteOutlineIcon
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenDeleteFeature(true);
                                  setFeatureIdForDelete(item.featureId);
                                  setFeatureNameForDelete(item.name);
                                }}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>

        <div
          className="sportLPV3__code-editor"
          style={codeEditorModeFull ? { width: "100%" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <p style={{ display: "flex", alignItems: "center" }}>
              {!codeEditorModeFull ? (
                <ChevronLeftIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              )}
              Code editor:{" "}
            </p>

            <Button
              onClick={copyToClipFunc}
              variant="contained"
              className="primary-btn"
            >
              Copy code
            </Button>

            {showSuccessCopied && (
              <div
                style={{
                  margin: "10px 0",
                  position: "absolute",
                  right: "125px",
                }}
              >
                <DoneIcon style={{ color: "green" }} fontSize="small" />
              </div>
            )}
          </div>
          <AceEditor
            mode="html"
            width="100%"
            height="700px"
            theme="monokai"
            fontSize={14}
            onChange={(e) => setGetMainCode(e)}
          />
        </div>
      </div>

      <div className="sportLPV3__popups">
        <PopupHpBanner1
          imgUrl={screenshopHpBanner1}
          open={openHpBanner1}
          handleClose={() => setOpenHpBanner1(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupHpBanner2
          imgUrl={screenshopHpBanner1}
          open={openHpBanner2}
          handleClose={() => setOpenHpBanner2(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupHpBanner3
          imgUrl={screenshopHpBanner3}
          open={openHpBanner3}
          handleClose={() => setOpenHpBanner3(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <DeleteFeaturePopup
          open={openDeleteFeature}
          handleClose={() => setOpenDeleteFeature(false)}
          name={featureNameForDelete}
          featureId={featureIdForDelete}
        />
      </div>
    </div>
  );
};

export default HpComponents;
