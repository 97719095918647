import React, { useState } from "react";
import "./index.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";

import copy from "copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "75%",
    overflowY: "auto",
    textAlign: "left",
  },
}));

export default function DeleteFeaturePopup(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsSuccessfullyDeleted(false);
  };

  // code of component
  const [generateCode, setGenerateCode] = useState(false);
  const [codeOfComponent, setCodeOfComponent] = useState("");

  const generateCodeFunc = () => {
    setGenerateCode(true);
  };

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(codeOfComponent);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSuccessfullyDeleted, setIsSuccessfullyDeleted] = useState(false);
  const deleteExternalFeature = async (featureId) => {
    setIsDeleteLoading(true);
    const res = await axios.delete(
      `${process.env.REACT_APP_SHEET_BEST_EXTERNAL_FEATURE_API}/featureId/*${featureId}*`
    );
    setIsDeleteLoading(false);
    if (res.status === 200) {
      setIsSuccessfullyDeleted(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      alert("Something went wrong!");
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <img src={props.imgUrl} className="popupImg" />
            <h2 id="transition-modal-title">{props.name}</h2>
            <p id="transition-modal-description">{props.text}</p>
            <div className="popupInputsContainer">
              {!isSuccessfullyDeleted && !isDeleteLoading ? (
                <p style={{ marginBottom: "6px" }}>
                  Are you sure you want to delete?
                </p>
              ) : (
                ``
              )}

              {!isDeleteLoading && !isSuccessfullyDeleted ? (
                <Button
                  onClick={() => deleteExternalFeature(props.featureId)}
                  variant="contained"
                  className="secondary-btn"
                >
                  yes
                </Button>
              ) : (
                ``
              )}

              {isDeleteLoading && <div>Loading...</div>}

              {isSuccessfullyDeleted && !isDeleteLoading ? (
                <div style={{ color: "green" }}>
                  The feature has been successfully deleted
                </div>
              ) : (
                ``
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
