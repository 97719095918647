import React from "react";
import "./index.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#004876",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(category, component, newDesign, cssCode, isClickable) {
  return { category, component, newDesign, cssCode, isClickable };
}

const rows = [
  createData("Sport page", "Base code", "will update automatically", "", false),
  createData(
    "Sport page",
    "2 categories",
    "will update automatically",
    "",
    false
  ),
  createData(
    "Sport page",
    "3 categories",
    "will update automatically",
    "",
    false
  ),
  createData(
    "Sport page",
    "4 categories",
    "will update automatically",
    "",
    false
  ),
  createData(
    "Sport page",
    "Article banner",
    "copy css",
    `
    .article1 .article1__content--categoryName {
      background-color: #3643BA;
    }
  
    .article1 .article1__content--cta {
      background-color: #3643BA;
      border-radius: 0;
    }
    .article1 .article1__content--cta:hover {
      background-color: #323894;
    }
  `,
    true
  ),
  createData(
    "Sport page",
    "Products slider",
    "copy css",
    `
    :root {
        /* Background */
        --redesign-background-primary-color: #FFFFFF;
        --redesign-background-secondary-color: #F5F4F5;
        --redesign-background-tertiary-color: #F1F0EE;
        --redesign-background-brand-primary-color: #3643BA;
        --redesign-background-brand-secondary-color: #ECEDF8;
        --redesign-background-accent-color: #3643BA;
        --redesign-background-alert-color: #E3262F;
      
        /* Content */
        --redesign-content-primary-color: #101010;
        --redesign-content-secondary-color: #424242;
        --redesign-content-tertiary-color: #616161;
        --redesign-content-active-color: #3643BA;
        --redesign-content-inactive-color: #949494;
        --redesign-content-negative-color: #E3262F;
        --redesign-content-warning-color: #DB6900;
        --redesign-content-positive-color: #149B65;
        --redesign-content-accent-color: #FFFFFF;
      
        /* Border */
        --redesign-border-primary-color: #A8A8A8;
        --redesign-border-secondary-color: #F1F0EE;
        --redesign-border-tertiary-color: #FFFFFF;
        --redesign-border-active-color: #3643BA;
        --redesign-border-inactive-color: #7A7A7A;
        --redesign-border-negative-color: #E3262F;
        --redesign-border-warning-color: #DB6900;
        --redesign-border-positive-color: #149B65;
        --redesign-border-information-color: #3643BA;
    
        /* Sale */
        --redesign-sale-yellow: #ffcd4e;
      
        /* Hover */
        --redesign-hover-primary-color: #323894;
      
        /* Shadow */
        --redesign-shadow-color: #313daa1A;
      }
    
    
    
      /* Loading */
      .lds-ellipsis div {
        background: var(--redesign-background-brand-secondary-color);
      }
    
      /* Card */
      .product-card {
        border-radius: 0;
      }
    
      /* Price */
      .card-price {
        color: #000;
        background-color: transparent;
      }
      .card-price--regular {
        text-decoration: line-through;
        font-size: .5em;
         color: #616161;
      }
      
      .card-price--percent-off {
          font-size: .5em;
          color: #000;
          background-color: var(--redesign-sale-yellow);
          padding: 2px 4px;
      }
      
      .card-price--sale {
        color: #000;
      }
    
      /* Brand */
      .card__brand {
        color: var(--redesign-content-primary-color);
      }
    
      /* Description */
      .card__description {
        color: var(--redesign-content-primary-color);
      }
    
  
  `,
    true
  ),
  createData(
    "Sport page",
    "Showroom",
    "copy css",
    `
    :root {
        /* Background */
        --redesign-background-primary-color: #FFFFFF;
        --redesign-background-secondary-color: #F5F4F5;
        --redesign-background-tertiary-color: #F1F0EE;
        --redesign-background-brand-primary-color: #3643BA;
        --redesign-background-brand-secondary-color: #ECEDF8;
        --redesign-background-accent-color: #3643BA;
        --redesign-background-alert-color: #E3262F;
      
        /* Content */
        --redesign-content-primary-color: #101010;
        --redesign-content-secondary-color: #424242;
        --redesign-content-tertiary-color: #616161;
        --redesign-content-active-color: #3643BA;
        --redesign-content-inactive-color: #949494;
        --redesign-content-negative-color: #E3262F;
        --redesign-content-warning-color: #DB6900;
        --redesign-content-positive-color: #149B65;
        --redesign-content-accent-color: #FFFFFF;
    
        /* Sale */
        --redesign-sale-yellow: #ffcd4e;
      
        /* Border */
        --redesign-border-primary-color: #A8A8A8;
        --redesign-border-secondary-color: #F1F0EE;
        --redesign-border-tertiary-color: #FFFFFF;
        --redesign-border-active-color: #3643BA;
        --redesign-border-inactive-color: #7A7A7A;
        --redesign-border-negative-color: #E3262F;
        --redesign-border-warning-color: #DB6900;
        --redesign-border-positive-color: #149B65;
        --redesign-border-information-color: #3643BA;
      
        /* Hover */
        --redesign-hover-primary-color: #323894;
      
        /* Shadow */
        --redesign-shadow-color: #313daa1A;
      }
    
    
      /* Container */
      .sportpage-showroom .highlight__details {
        border-radius: 0;
      }
    
      /* Card */
      .sportpage-showroom .product-card {
        border-radius: 0;
      }
    
      /* Price */
      .sportpage-showroom .card-price {
        background-color: transparent;
        color: var(--redesign-content-primary-color);
      }
      .sportpage-showroom .card-price--sale {
        background-color: var(--redesign-background-alert-color);
        color: #fff;
      }
      .sportpage-showroom .card-price--percent-off {
        color: var(--redesign-background-alert-color);
      }
    
      /* Brand */
      .sportpage-showroom .card__brand {
        color: var(--redesign-content-primary-color) !important;
      }
    
      /* Description */
      .sportpage-showroom .card__description {
        color: var(--redesign-content-primary-color);
      }
    
    
      /* Btn */
      .sportpage button {
        border-radius: 0 !important;
       }
      .sportpage-showroom .highlight__cta--desktop,
      .sportpage-showroom .highlight__cta--mobile {
        background-color: var(--redesign-background-brand-primary-color);
        color: #fff;
      }
     
      .sportpage-showroom .highlight__cta--desktop:not(:disabled):hover,
      .sportpage-showroom .highlight__cta--mobile:not(:disabled):hover {
        background-color: var(--redesign-hover-primary-color);
        color: #fff;
      }
    
      /* Sale price */
      .sportpage-showroom .card-price--regular {
        text-decoration: line-through;
        font-size: .5em;
        color: #616161;
      }
    
    
      .sportpage-showroom .card-price--percent-off {
        font-size: .5em;
        color: #000;
        background-color: var(--redesign-sale-yellow);
        padding: 2px 4px;
      }
    
    
      .sportpage-showroom .card-price--sale {
        color: #000;
      }
    
    
    
      @media(max-width: 900px) {
        .sportpage-showroom .highlight__cta--desktop {
         display: none !important;
        }
      }
  `,
    true
  ),
  createData(
    "Home page",
    "HP Austria",
    "copy css",
    `
    <style>
:root {
  /* Background */
  --redesign-background-primary-color: #FFFFFF;
  --redesign-background-secondary-color: #F5F4F5;
  --redesign-background-tertiary-color: #F1F0EE;
  --redesign-background-brand-primary-color: #3643BA;
  --redesign-background-brand-secondary-color: #ECEDF8;
  --redesign-background-accent-color: #3643BA;
  --redesign-background-alert-color: #E3262F;


  /* Content */
  --redesign-content-primary-color: #101010;
  --redesign-content-secondary-color: #424242;
  --redesign-content-tertiary-color: #616161;
  --redesign-content-active-color: #3643BA;
  --redesign-content-inactive-color: #949494;
  --redesign-content-negative-color: #E3262F;
  --redesign-content-warning-color: #DB6900;
  --redesign-content-positive-color: #149B65;
  --redesign-content-accent-color: #FFFFFF;


  /* Border */
  --redesign-border-primary-color: #A8A8A8;
  --redesign-border-secondary-color: #F1F0EE;
  --redesign-border-tertiary-color: #FFFFFF;
  --redesign-border-active-color: #3643BA;
  --redesign-border-inactive-color: #7A7A7A;
  --redesign-border-negative-color: #E3262F;
  --redesign-border-warning-color: #DB6900;
  --redesign-border-positive-color: #149B65;
  --redesign-border-information-color: #3643BA;


  /* Hover */
  --redesign-hover-primary-color: #323894;


  /* Shadow */
  --redesign-shadow-color: #313daa1A;
}


    #main .container {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important
}


.elementor-2 .elementor-element.elementor-element-yenvrib,
main:not(.page--sports) .page-cms {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}


.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 100%
}


.elementor-column-gap-default .elementor-element-populated {
    padding: 0 !important
}


.homepage-banner-container {
    /*background: #7ac0e1;*/
    background: var(--redesign-background-brand-secondary-color);
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 0 1em
}


.homepage-banner-container::after {
    content: "";
    width: 120%;
    height: 100px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 30%) rotate(356deg);
    z-index: 1
}


.homepage-banner {
    padding: 1em 0;
    color: var(--vtmn-color_white);
    font-family: Roboto, sans-serif;
    display: grid;
    grid-template-columns: repeat(2, 48.5%);
    grid-gap: 2%;
    min-height: 40vh;
    overflow: hidden;
    max-width: 1700px;
    position: relative;
    z-index: 2
}


.banner-item-main,
.banner-item-side {
    /*border-radius: .5em;*/
    min-height: 150px;
    z-index: 2;
    position: relative
}


.banner-item-main .banner-item-link,
.banner-item-side .banner-item-link {
    align-items: flex-start
}


.banner-item-main:before,
.banner-item-side::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit
}


.banner-item-main:before {
    background: linear-gradient(to left, transparent 50%, rgba(0, 0, 0, .6))
}


.banner-item-side::before {
    background: linear-gradient(to bottom, transparent 30%, rgba(0, 0, 0, .4))
}


.homepage-banner__title {
    font-size: 2rem !important
}


.banner-item-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;
    height: 100%;
    width: 100%;
    color: inherit;
    border-radius: inherit;
    position: relative
}


.banner-item-heading {
    font-size: var(--vtmn-typo_title-1-font-size) !important
}


.banner-item-link:focus,
.banner-item-link:hover {
    color: inherit
}


.banner-item-content {
    max-width: 360px;
    width: 35%
}


.banner-item-1 {
    background-size: cover;
    grid-column: 1/-1;
    width: 100%
}


.banner-item-2 {
    background-size: cover;
    width: 100%;
    grid-column: 1
}


.banner-item-3 {
    background-size: cover;
    width: 100%;
    grid-column: 2
}


.banner-item-1 {
    background-position: center
}


.banner-item-2 {
    background-position: center 30%
}


.banner-item-3 {
    background-position: center
}


.homepage-banner h2 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .5em
}


.homepage-banner p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
}


.homepage-component {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1.5em var(--vtmn-spacing_2);
    position: relative;
    z-index: 2
}


.homepage-component:last-of-type {
    margin-bottom: 5em
}


.homepage-component__heading {
    padding-left: var(--vtmn-spacing_2);
    font-size: 20px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    margin-bottom: .5em;
    color: var(--redesign-content-primary-color);
}


.homepage-component--highlight {
    padding: 1.5em 0
}


.slider-component {
    padding: 1.5em 0 1.5em .5em
}


.main-categories {
    display: flex;
    flex-wrap: wrap
}


.main-categories__category-wrapper {
    padding: var(--vtmn-spacing_2);
    flex: 1 1 calc(100% / 3)
}


.main-categories__category {
    display: flex;
    flex-direction: column;
    align-items: center
}


.main-categories__category:hover .main-categories__img {
    filter: brightness(.75)
}


.main-categories__img-wrapper {
    /*border-radius: .5rem;*/
    background: rgba(128, 128, 128, .52)
}


.main-categories__img {
    margin: 0 !important;
    border-radius: inherit !important;
    height: 100% !important;
    object-fit: cover;
    filter: brightness(1);
    transition: ease-in 250ms;
    width: 100%
}


.main-caegories__title {
    font-family: var(--vtmn-typo_font-family--condensed);
    /*color: var(--vtmn-color_black);*/
    color: var(--redesign-content-primary-color);
    font-style: italic;
    text-transform: uppercase;
    font-weight: 700;
    padding: .5em 0;
    font-size: 14px
}


.img-wrapper--main-category {
    height: 50px
}


.img-wrapper--sport-category {
    height: 200px;
    min-width: 150px
}


.grid-section {
    margin: 0 auto
}


.cards-wrapper {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none
}


.cards-wrapper::-webkit-scrollbar {
    display: none
}


.product-card {
    font-family: Roboto Condensed, sans-serif;
    text-align: center;
    background-color: #fff;
    font-size: 1.5rem;
    height: 290px;
    padding: .5em;
    box-shadow: 0 0 15px -2px rgb(0 0 0 / 15%);
    /*border-radius: 10px;*/
    position: relative;
    overflow: hidden;
    min-width: calc(100vw / 2.5)
}


.highlight__products .product-card {
    min-width: auto
}


.category-card {
    padding: 0
}


.category-card h3 {
    margin: 0
}


.card__top {
    height: 55%
}


.product-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0
}


.category-card img {
    object-fit: contain
}


.sport-card {
    object-fit: cover
}


.card__bottom {
    padding-top: .7em
}


.product-card p {
    margin: 0
}


.card-price {
    font-weight: 700;
    background-color: transparent;
    padding: 2px;
    font-family: Roboto Condensed, sans-serif;
    font-size: 16px;
    justify-self: center;
    align-self: center;
    /*color: #1a2a34;*/
    color: var(--redesign-content-primary-color);
    line-height: 1
}


.card-price-container {
    position: relative
}


.card-price-container__top {
    position: absolute;
    top: -70%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%
}


.card-price-container__bottom {
    margin-bottom: .5em
}


.card-price--regular {
    text-decoration: line-through;
    font-size: .5em
}


.card-price--percent-off {
    /*color: #ee000c;*/
    color: var(--redesign-content-negative-color);
    font-size: .5em
}


.card-price--sale {
    color: #fff;
    /*background-color: #ee000c;*/
    background-color: var(--redesign-content-negative-color);
}


.card-price--sale-inactive {
    display: none
}


.card-link {
    display: flex;
    flex-direction: column;
    text-decoration: none
}


.card__brand {
    text-transform: uppercase !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    /*color: #242323 !important*/
    color: var(--redesign-content-primary-color) !important;
}


.card__description {
    font-size: 12px !important;
    /*color: #242323 !important;*/
    color: var(--redesign-content-primary-color) !important;
    line-height: 1.1;
    opacity: .9;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
}


.card-popularity {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px
}


.slider-btn-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    z-index: 2
}


.slider-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: 0;
    outline: 0;
    background: 0 0;
    position: relative
}


.slider-btn:focus {
    outline: 0
}


.slider-btn::before {
    line-height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '';
    opacity: .25;
    background: #000;
    transform: translate(50%, 50%)
}


.slider-active.slider-btn::before {
    opacity: .95
}


.banner-item-text {
    display: none
}


.highlight {
    display: flex;
    flex-direction: column
}


.highlight__details {
    background-size: cover;
    background-position: center;
    flex: 1 1 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: end;
    -webkit-justify-content: flex-end;
    padding: 4em 1em;
    overflow: hidden;
    min-height: 300px
}


.highlight__details::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 30%, rgba(0, 0, 0, .6))
}


.highlight__content {
    position: relative;
    max-width: 450px
}


.articles__heading,
.highlight__title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px !important;
    font-family: Roboto Condensed, sans-serif
}


.articles__heading,
.aricles__title,
.articles__text {
    /*color: #242323 !important*/
    color: var(--redesign-content-primary-color) !important;
}


.articles__heading {
    margin: 0
}


.articles__text {
    font-size: 14px;
    line-height: 1.3;
    font-family: Roboto Condensed, sans-serif;
    margin-bottom: 35px !important
}


.aricles__title {
    font-weight: 700;
}


.highlight__subtitle {
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 20px;
    margin: .9em 0 !important
}


.highlight__cta--desktop {
    display: none !important
}


.highlight__products {
    flex: 1 1 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: -4em 0 0;
    padding: .5em
}


.highlight__cta--mobile {
    margin: 0 auto
}


.highlight__products .main-categories__category-wrapper:nth-child(6),
.highlight__products .main-categories__category-wrapper:nth-child(7) {
    display: none
}


.articles__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: .5em;
    overflow: hidden
}


.articles__container {
    position: relative;
    flex: 1 1 100%;
    overflow: hidden;
    /*border-radius: .5em*/
}


.articles__content {
    max-width: 480px;
    margin: 0 auto;
    position: relative;
    padding: 2em;
    display: flex;
    flex-direction: column;
    height: 100%
}


.articles__container:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    /*background-color: #f8f9f9;*/
    background-color: var(--redesign-background-secondary-color);
    /*border-radius: .5em*/
}


.articles__container--light-blue:before {
    /*background-color: #e7f3f9*/
    background-color: var(--redesign-background-brand-secondary-color);
}


.articles__img {
    margin: 20px 0 !important;
    /*border-radius: 3px !important;*/
    height: 280px !important;
    object-fit: cover;
    width: 100%
}


.articles__btn {
    margin-top: auto
}


@media (min-width:600px) {
    .main-categories__category-wrapper {
        flex: 1 1 calc(100% / 6)
    }


    .img-wrapper-main-catogries {
        height: 70px
    }


    .banner-item-text {
        display: block
    }


    .img-wrapper--main-category {
        height: 60px
    }


    .homepage-banner {
        margin: 0 auto
    }


    .homepage-banner-container::after {
        transform: translate(0, 50%) rotate(359deg)
    }


    .slider-component {
        padding: 1.5em .5em 1.5em
    }


    .highlight__products {
        grid-template-columns: 1fr 1fr 1fr 1fr
    }


    .product-card {
        min-width: 170px
    }


    .card__top {
        height: 60%
    }


    .articles__wrapper {
        flex-direction: row
    }


    .articles__container:before {
        top: 35%;
        transform: skewY(-10deg)
    }


    .articles__container--light-blue:before {
        top: -50%;
        transform: skewY(-10deg)
    }


    .articles__content {
        padding: 4em 2em
    }
}


@media (min-width:900px) {
    .homepage-component {
        padding: 1.5em 3em
    }


    .homepage-banner-container {
        padding: 0
    }


    .homepage-component__heading {
        font-size: 26px
    }


    .homepage-banner {
        grid-template-columns: 70% auto;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "item1 item2""item1 item3";
        min-height: 65vh;
        padding: 1em 3.5em
    }


    .banner-item {
        transition: transform .8s ease-in-out
    }


    .banner-item:hover {
        transform: scale(1.02)
    }


    .banner-item-link {
        padding: 2em
    }


    .banner-item-main .banner-item-link {
        justify-content: center
    }


    .banner-item-1 {
        grid-area: item1
    }


    .banner-item-2 {
        grid-area: item2
    }


    .banner-item-3 {
        grid-area: item3
    }


    .banner-item-1 {
        grid-area: item1
    }


    .banner-item-2 {
        grid-area: item2
    }


    .banner-item-3 {
        grid-area: item3
    }


    .slider-btn-container {
        display: none
    }


    .product-card {
        height: 350px
    }


    .highlight__products .product-card {
        height: 310px
    }


    .img-wrapper--sport-category {
        height: 300px
    }


    .highlight {
        display: flex;
        flex-direction: row
    }


    .highlight__details {
        margin-bottom: 3em;
        padding: 2em;
        /*border-radius: .5em*/
    }


    .highlight__cta--desktop {
        display: flex !important
    }


    .highlight__cta--mobile {
        display: none !important
    }


    .highlight__products {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1em;
        margin: 1em 0 0 -3em
    }


    .homepage-component--highlight {
        padding: 1.5em 3.5em
    }


    .articles__img {
        height: 420px !important
    }


    .articles__heading,
    .highlight__title {
        font-size: 30px !important
    }


    .highlight__products .main-categories__category-wrapper:nth-child(6),
    .highlight__products .main-categories__category-wrapper:nth-child(7) {
        display: block
    }


    .homepage-component--highlight+.homepage-component--highlight .highlight-order2 {
        flex-direction: row-reverse
    }


    .homepage-component--highlight+.homepage-component--highlight .highlight__products {
        margin: 1em -3em 0 0
    }


    .homepage-component--highlight+.homepage-component--highlight .highlight__details {
        padding: 2em 4em
    }
}


@media (min-width:1300px) {
    .homepage-component {
        padding: 1.5em .5em
    }


    .homepage-banner {
        padding: 1em .5em
    }
}


.vtmn-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 3rem;
    border: 0;
    line-height: 1;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: .875rem 1.5rem;
    font-family: Roboto, system-ui, '-apple-system', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: .01687rem;
    border-radius: 0;
    color: #fff;
    fill: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: hsl(200, 100%, 36.9%);
    transition: box-shadow .2s ease-out
}


.vtmn-btn:not(:disabled):hover {
    background-color: hsla(200, 100%, calc(36.9% * .85), 1);
    cursor: pointer
}


.vtmn-btn:not(:disabled):active {
    background-color: hsla(200, 100%, calc(36.9% * .7), 1)
}


.vtmn-btn:disabled {
    cursor: not-allowed;
    opacity: .38
}


.vtmn-btn:not(:disabled):focus-visible {
    outline: 0;
    box-shadow: 0 0 0 .25rem #fff, 0 0 0 .375rem hsl(200, 100%, 4.7%)
}


.vtmn-btn:not(:disabled) {
    outline: 0
}


.vtmn-btn_variant--primary {
    /*background-color: hsl(200, 100%, 36.9%);*/
    background-color: var(--redesign-background-brand-primary-color);
    color: #fff;
    fill: #fff
}


.vtmn-btn_variant--primary:not(:disabled):hover {
    /*background-color: hsla(200, 100%, calc(36.9% * .85), 1)*/
    background-color: var(--redesign-hover-primary-color);
}


.vtmn-btn_variant--primary:not(:disabled):active {
    /*background-color: hsla(200, 100%, calc(36.9% * .7), 1)*/
    background-color: var(--redesign-hover-primary-color);
}


.vtmn-btn_variant--primary-reversed {
    box-shadow: none;
    background-color: #fff;
    color: hsl(200, 100%, 4.7%);
    fill: hsl(200, 100%, 4.7%)
}


.vtmn-btn_variant--primary-reversed:not(:disabled):hover {
    background-color: hsla(0, 0%, 100%, .8);
    box-shadow: inset 0 0 0 .125rem #fff
}


.vtmn-btn_variant--primary-reversed:not(:disabled):active {
    background-color: hsla(0, 0%, 100%, .6);
    box-shadow: inset 0 0 0 .125rem #fff
}


.vtmn-btn_variant--primary-reversed:not(:disabled):focus-visible {
    outline: 0;
    box-shadow: inset 0 0 0 .125rem #fff, 0 0 0 .25rem #fff, 0 0 0 .375rem hsl(200, 100%, 4.7%)
}


.vtmn-btn_variant--conversion {
    box-shadow: inset 0 0 0 .125rem transparent;
    /*background-color: hsl(54, 100%, 57.8%);*/
    background-color: var(--redesign-content-active-color);
    /*color: hsl(200, 100%, 4.7%);*/
    color: #fff;
    /*fill: hsl(200, 100%, 4.7%);*/
    fill: #fff;
}


.vtmn-btn_variant--conversion:not(:disabled):hover {
    /*background-color: hsla(54, 100%, calc(57.8% + (100% - 57.8%) * .48), 1);*/
    background-color: var(--redesign-hover-primary-color);
}


.vtmn-btn_variant--conversion:not(:disabled):active {
    /*background-color: hsla(54, 100%, calc(calc(57.8% + (100% - 57.8%) * .48) + (100% - calc(57.8% + (100% - 57.8%) * .48)) * .5), 1)*/
    background-color: var(--redesign-hover-primary-color);
}


.vtmn-btn_variant--conversion:not(:disabled):focus-visible {
    outline: 0;
    box-shadow: inset 0 0 0 .125rem transparent, 0 0 0 .25rem #fff, 0 0 0 .375rem hsl(200, 100%, 4.7%)
}


.homepage-component--cta-bottom .cta-bottom__wrapper {
    padding: .5em
}


.homepage-component--cta-bottom .cta-bottom__item {
    /*background-color: #f8f9f9;*/
    background-color: var(--redesign-background-secondary-color);
    display: block;
    /*border-radius: .8rem .8rem .8rem 0;*/
    width: 100%;
    padding: 3.2rem;
    margin: 20px 0;
    text-align: center
}


.homepage-component--cta-bottom .cta-bottom__item-secondary {
    display: none
}


.homepage-component--cta-bottom .cta-bottom__item-img img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 20rem;
    margin-bottom: 2.4rem;
    /*border-radius: 100px*/
}


.homepage-component--cta-bottom .cta-bottom__item-text span {
    font-size: 14px;
    display: block;
    color: #005580
}


@media(min-width:760px) {
    .homepage-component--cta-bottom .cta-bottom__item-secondary {
        display: block
    }


    .homepage-component--cta-bottom .cta-bottom__wrapper {
        display: flex;
        justify-content: space-between
    }


    .homepage-component--cta-bottom .cta-bottom__item {
        width: 47%
    }


    .homepage-component--cta-bottom .cta-bottom__item-img img {
        max-height: 15rem
    }
}


@media(min-width:1000px) {
    .homepage-component--cta-bottom .cta-bottom__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding: 2.2rem
    }


    .homepage-component--cta-bottom .cta-bottom__item-img img {
        max-width: 6rem;
        max-height: 6rem;
        margin-bottom: 0;
        margin-right: 2.2rem
    }
}


.homepage-component-ratings {
    padding-top: 0
}


.homepage-component-ratings__container {
    padding: .5em
}


.homepage-component-ratings__content {
    /*background-color: #f8f9f9;*/
    background-color: var(--redesign-background-secondary-color);
    /*adius: .8rem .8rem .8rem 0;*/
    padding: 2.2rem 3.2rem;
    margin: 20px 0;
    text-align: center
}


.homepage-component-ratings__ratings-number {
    font-size: 36px
}


.homepage-component-ratings__ratings-number-separate {
    display: inline-block;
    margin: 0 6px;
    font-size: 22px
}


.homepage-component-ratings__ratings-from-number {
    font-size: 22px
}


.fa-star,
.fa-star-half-full,
.fa-start-o {
    font-size: 18px;
    color: #ec6607
}


.fa-half-star-checked {
    font-size: 0
}


.homepage-component-ratings__cta {
    margin-top: 14px
}


.homepage-component-ratings__cta-title {
    border: none !important;
    text-transform: uppercase
}


.homepage-component-ratings__cta-btn {
    margin-bottom: 10px;
    display: block
}


.homepage-component-ratings__cta-btn button {
    min-width: 200px;
    margin: 0 auto
}


@media(min-width:760px) {
    .homepage-component-ratings__content {
        display: flex;
        align-items: center
    }


    .homepage-component-ratings__ratings {
        margin-right: 25px
    }


    .homepage-component-ratings__cta-btns {
        display: flex;
        justify-content: space-between
    }


    .homepage-component-ratings__cta-btn {
        margin: 8px 6px;
        padding: 8px 6px
    }


    .homepage-component-ratings__cta-title {
        text-align: left;
        padding-left: 10px
    }
}


@media(min-width:760px) {
    .homepage-component-ratings__ratings {
        margin-right: 65px
    }
}


.loading-products {
    width: 100%;
    position: relative
}


.loading-products span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px
}


.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffea28;
    animation-timing-function: cubic-bezier(0, 1, 1, 0)
}


.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 .6s infinite
}


.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 .6s infinite
}


.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 .6s infinite
}


.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 .6s infinite
}


@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0)
    }


    100% {
        transform: scale(1)
    }
}


@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1)
    }


    100% {
        transform: scale(0)
    }
}


@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0)
    }


    100% {
        transform: translate(24px, 0)
    }
}


@media (max-width:370px) {
    .banner-item-1 {
        background-size: cover;
        grid-column: 1/-1;
        width: 100%
    }


    .banner-item-2 {
        background-size: cover;
        grid-column: 1/-1;
        width: 100%
    }


    .banner-item-3 {
        background-size: cover;
        grid-column: 1/-1;
        width: 100%
    }


    .homepage-banner__title {
        margin-top: 10px
    }
}


.hp-brand-logos .main-categories__img-wrapper {
    border: 1px solid #ececec;
    /*border-radius: .5rem;*/
    background-color: #fff !important
}


.hp-brand-logos .main-categories__img-wrapper img {
    object-fit: contain
}


.hp-brand-logos .main-categories__category:hover .main-categories__img {
    filter: none !important
}
</style>
    `,
    true
  ),
  createData(
    "Category/Search",
    "content",
    "will update automatically",
    "",
    false
  ),
  createData(
    "Category/Search",
    "Propose categories to search results",
    "copy css",
    `
    <style>
    #propose-categories-container {
      background: #3643BA;
      color: #fff !important;
      margin-top: 20px;
      padding: 10px;
    }
   #propose-categories-container a {
      text-decoration: none !important;
      color: #3643BA !important;
      display: block;
      background: #fff;
      padding: 4px 6px;
      border-radius: 6px;
    }

    #propose-categories-container a:not(:last-child) {
      margin-right: 12px;
    }

    .propose-categories__title {
      color: #fff;
      font-size: 18px;
      display: block;
      margin-bottom: 10px;
     }

     .propose-categories__categories-container {
      position: relative;
      overflow: auto;
     }

     .propose-categories__categories {
      display: flex;
      min-width: 1000px;
     }
    </style>

    `,
    true
  ),
];

const useStyles = makeStyles({
  /*  table: {
      minWidth: 700,
    },*/
  copyText: {
    cursor: "pointer",
    color: "#004876", // Change "green" to your desired color
  },
});

const Updates = () => {
  const classes = useStyles();

  const handleCopyClick = (cssCode) => {
    navigator.clipboard.writeText(cssCode).then(
      () => {
        alert("CSS copied to clipboard!");
      },
      (err) => {
        alert("Failed to copy text: ", err);
      }
    );
  };

  return (
    <div className="updates">
      <div className="updates__table">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Category</StyledTableCell>
                <StyledTableCell align="left">Component</StyledTableCell>
                <StyledTableCell align="right">New design</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.category}</StyledTableCell>
                  <StyledTableCell>{row.component}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.isClickable ? (
                      <span
                        className={classes.copyText}
                        onClick={() => handleCopyClick(row.cssCode)}
                      >
                        {row.newDesign}
                      </span>
                    ) : (
                      <span>{row.newDesign}</span>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Updates;
