import React, { useState, useEffect } from "react";
import axios from "axios";
import "./index.css";
import data from "../../components/data/products-data.json";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import copy from "copy-to-clipboard";
import DoneIcon from "@material-ui/icons/Done";
// for accordion mui:
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// for tabs mui:
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// for toggle buttons mui:
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// import papaparse:
import { CSVReader } from "react-papaparse";
const buttonRef1 = React.createRef();
const buttonRef2 = React.createRef();
const buttonRef3 = React.createRef();
const buttonRef4 = React.createRef();
const buttonRef5 = React.createRef();
const buttonRef6 = React.createRef();
const buttonRef7 = React.createRef();
const buttonRef8 = React.createRef();
const buttonRef9 = React.createRef();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "75%",
    overflowY: "auto",
    textAlign: "left",
    width: "90%",
    height: "75%",
  },
}));

/* -- start tabs component -- */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/* -- end tabs component -- */

export default function PromiseDeliveryMessage(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // for accordion:
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // for tabs mui:
  const [valueTabs, setValueTabs] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  /* -- START for toggle buttons mui - feature is live in countries -- */
  const [alignment, setAlignment] = useState("no");
  const [featuresDataFromDb, setFeaturesDataFromDb] = useState([]);
  const [featureCountriesSrtFromDb, setFeatureCountriesSrtFromDb] =
    useState("");
  const [featureCountriesArrFromDb, setFeatureCountriesArrFromDb] = useState(
    []
  );
  const [toggleBtnsDisabledBool, setToggleBtnsDisabledBool] = useState(false);
  const handleChangeToggleBtns = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // get api features data and check if user country is in data
  const getFeaturesDataFormDb = async () => {
    try {
      const res = await axios.get(
        "https://sheet.best/api/sheets/5a544ccc-9b8b-46bc-9e51-aba70505cd5e"
      );
      setFeaturesDataFromDb(res.data);
      const pdmObjData = res.data.filter(
        (e) => e.featureName === "promise_delivery_message"
      );

      if (pdmObjData[0].liveInCountries !== null) {
        setFeatureCountriesSrtFromDb(pdmObjData[0].liveInCountries);
        const countriesSplitedArr = pdmObjData[0].liveInCountries.split(", ");
        setFeatureCountriesArrFromDb(countriesSplitedArr);
        if (
          countriesSplitedArr.some(
            (e) =>
              e.toLowerCase() ===
              props.userDataObjFromSheet.country.toLowerCase()
          )
        ) {
          setAlignment("yes");
        }
      } else {
        setAlignment("no");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFeaturesDataFormDb();
  }, []);

  // handle on click - yes (feature is live in user country)
  const featureIsLiveInUserCountryHandleOnClick = async () => {
    try {
      // disalble btns:
      setToggleBtnsDisabledBool(true);

      // get feature id in google sheet
      const featureIdInSheet =
        featuresDataFromDb.filter(
          (e) => e.featureName === "promise_delivery_message"
        )[0].id - 1;

      // add country name to array and convert to string
      let updatedArrOfCountries = [
        ...featureCountriesArrFromDb,
        props.userDataObjFromSheet.country,
      ];
      updatedArrOfCountries = [...new Set(updatedArrOfCountries)];
      const updatedStrOfCountries = updatedArrOfCountries.join(", ");

      // patch api call to update google sheet
      const updateRes = await axios.patch(
        `https://sheet.best/api/sheets/5a544ccc-9b8b-46bc-9e51-aba70505cd5e/${featureIdInSheet}`,
        {
          id: null,
          liveInCountries: updatedStrOfCountries,
        }
      );

      // able btns:
      setToggleBtnsDisabledBool(false);

      // set states
      setAlignment("yes");
      setFeatureCountriesSrtFromDb(updatedStrOfCountries);
    } catch (err) {
      console.error(err);
    }
  };

  // handle on click - no (feature is not live in user country)
  const featureIsNotLiveInUserCountryHandleOnClick = async () => {
    try {
      // disalble btns:
      setToggleBtnsDisabledBool(true);

      // get feature id in google sheet
      const featureIdInSheet =
        featuresDataFromDb.filter(
          (e) => e.featureName === "promise_delivery_message"
        )[0].id - 1;

      // remove country name from array and convert to string
      let updatedArrOfCountries = [...featureCountriesArrFromDb];
      const indexOfCountryInArr = updatedArrOfCountries.indexOf(
        props.userDataObjFromSheet.country
      );
      if (indexOfCountryInArr !== -1) {
        updatedArrOfCountries.splice(indexOfCountryInArr, 1);
        updatedArrOfCountries = [...new Set(updatedArrOfCountries)];
      }
      let updatedStrOfCountries = updatedArrOfCountries.join(", ");

      // patch api call to update google sheet
      const updateRes = await axios.patch(
        `https://sheet.best/api/sheets/5a544ccc-9b8b-46bc-9e51-aba70505cd5e/${featureIdInSheet}`,
        {
          id: null,
          liveInCountries: updatedStrOfCountries,
        }
      );

      // able btns:
      setToggleBtnsDisabledBool(false);

      // set states
      setAlignment("no");
      setFeatureCountriesSrtFromDb(updatedStrOfCountries);
    } catch (err) {
      console.error(err);
    }
  };

  /* -- END for toggle buttons mui - feature is live in countries -- */

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const [generateCode, setGenerateCode] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(readyScriptCodeAsString);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };

  /* start papaparse multiple btns and files functions (test) */
  const handleOpenDialog = (e, btn_ref) => {
    // Note that the ref is set async, so it might be null at some point
    if (btn_ref.current) {
      btn_ref.current.open(e);
    }
  };

  const handleOnFileLoad = (data, setStateOfFileData) => {
    console.log("---------------------------");
    console.log(data);
    setStateOfFileData(data);
    console.log("---------------------------");

    //start execute functions

    //end execute functions
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  /* end papaparse multiple btns and files functions (test) */

  /* start papaparse functions */
  /*
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log("---------------------------");
    console.log(data);
    //setProductDataDirectFromCsv(data);
    console.log("---------------------------");
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };
  */
  /* end papaparse functions */

  /* ## START STATES AND FUNCTIONS PROMISE DELIVERY MESSAGE ## */
  // ** start "general" states and functions
  const [generalDirectDataFromCsv, setGeneralDirectDataFromCsv] =
    useState(false);
  // ** end "general" states and functions

  // ** start "prios_clickandcollect" states and functions
  const [
    priosClickandcollectDirectDataFromCsv,
    setPriosClickandcollectDirectDataFromCsv,
  ] = useState(false);
  // ** end "prios_clickandcollect" states and functions

  // ** start "prios_delivery" states and functions
  const [priosDeliverytDirectDataFromCsv, setPriosDeliverytDirectDataFromCsv] =
    useState(false);
  // ** end "prios_delivery" states and functions

  // ** start "in_prios_override_clickandcollect" states and functions
  const [
    inPriosOverrideClickandcollectDirectDataFromCsv,
    setInPriosOverrideClickandcollectDirectDataFromCsv,
  ] = useState(false);
  // ** end "in_prios_override_clickandcollect" states and functions

  // ** start "in_prios_override_delivery" states and functions
  const [
    inPriosOverrideDeliverytDirectDataFromCsv,
    setInPriosOverrideDeliverytDirectDataFromCsv,
  ] = useState(false);
  // ** end "in_prios_override_delivery" states and functions

  // ** start "strict_overrides_clickandcollect" states and functions
  const [
    strictOverrideClickandcollectDirectDataFromCsv,
    setStrictOverrideClickandcollectDirectDataFromCsv,
  ] = useState(false);
  // ** end "strict_overrides_clickandcollect" states and functions

  // ** start "strict_overrides_delivery" states and functions
  const [
    strictOverrideDeliveryDirectDataFromCsv,
    setStrictOverrideDeliveryDirectDataFromCsv,
  ] = useState(false);
  // ** end "strict_overrides_delivery" states and functions

  // ** start "general_settings_clickandcollect" states and functions
  const [
    generalSettingsClickandcollectDirectDataFromCsv,
    setGeneralSettingsClickandcollectDirectDataFromCsv,
  ] = useState(false);
  // ** end "general_settings_clickandcollect" states and functions

  // ** start "general_settings_delivery" states and functions
  const [
    generalSettingsDeliveryDirectDataFromCsv,
    setGeneralSettingsDeliveryDirectDataFromCsv,
  ] = useState(false);
  // ** end "general_settings_delivery" states and functions

  // ** start reuseable functions
  //remove first item from array:
  const removeFirstItemInArray = (arr) => {
    return arr.slice(1, arr.length);
  };
  //convert zones string to array string:
  const convertStringToArrayString = (str) => {
    return str
      .split(",")
      .map((e) => e.trim())
      .map((e) => `"${e}"`)
      .toString();
  };
  //convert prio overrides to objects:
  const convertPrioOverridesToObjects = (
    data_direct_from_csv,
    prio_num,
    override_by
  ) => {
    let cuttedArr = removeFirstItemInArray(data_direct_from_csv);
    let objStr;
    let readyObjsStr = "";

    for (let i = 0; i < cuttedArr.length; i++) {
      if (
        cuttedArr[i].data[0] === prio_num &&
        cuttedArr[i].data[1] === override_by
      ) {
        //check if override by product nature or model code and set obj accordingly
        if (override_by === "product-nature") {
          objStr = `
          {
            values: [${convertStringToArrayString(cuttedArr[i].data[2])}],
            override_exeptions_modal_codes: [${convertStringToArrayString(
              cuttedArr[i].data[3]
            )}],
            override_text_message: "${cuttedArr[i].data[4]}",
            override_add_commentBool: ${
              cuttedArr[i].data[5] === "yes" ? true : false
            },
            override_comment_text:
              "${cuttedArr[i].data[6]}",
          }
          `;
        } else if (override_by === "model-code") {
          objStr = `
          {
            values: [${convertStringToArrayString(cuttedArr[i].data[2])}],
            override_exeptions_product_nature: [${convertStringToArrayString(
              cuttedArr[i].data[3]
            )}],
            override_text_message: "${cuttedArr[i].data[4]}",
            override_add_commentBool: ${
              cuttedArr[i].data[5] === "yes" ? true : false
            },
            override_comment_text: "${cuttedArr[i].data[6]}",
          },
          `;
        }

        //check if first obj
        if (readyObjsStr.length === 0) {
          readyObjsStr = readyObjsStr + objStr;
        } else {
          readyObjsStr = readyObjsStr + "," + objStr;
        }
      }
    }

    return readyObjsStr;
  };

  //convert prio overrides to objects:
  const convertStrictOverridesToObjects = (
    data_direct_from_csv,
    override_by
  ) => {
    let cuttedArr = removeFirstItemInArray(data_direct_from_csv);
    let objStr;
    let readyObjsStr = "";

    for (let i = 0; i < cuttedArr.length; i++) {
      if (cuttedArr[i].data[0] === override_by) {
        //check if override by product nature or model code and set obj accordingly
        if (override_by === "product-nature") {
          objStr = `
            {
              values: [${convertStringToArrayString(cuttedArr[i].data[1])}],
              override_exeptions_modal_codes: [${convertStringToArrayString(
                cuttedArr[i].data[2]
              )}],
              override_text_message: "${cuttedArr[i].data[3]}",
              override_add_commentBool: ${
                cuttedArr[i].data[4] === "yes" ? true : false
              },
              override_comment_text:
                "${cuttedArr[i].data[5]}",
            }
            `;
        } else if (override_by === "model-code") {
          objStr = `
            {
              values: [${convertStringToArrayString(cuttedArr[i].data[1])}],
              override_exeptions_product_nature: [${convertStringToArrayString(
                cuttedArr[i].data[2]
              )}],
              override_text_message: "${cuttedArr[i].data[3]}",
              override_add_commentBool: ${
                cuttedArr[i].data[4] === "yes" ? true : false
              },
              override_comment_text: "${cuttedArr[i].data[5]}",
            },
            `;
        }

        //check if first obj
        if (readyObjsStr.length === 0) {
          readyObjsStr = readyObjsStr + objStr;
        } else {
          readyObjsStr = readyObjsStr + "," + objStr;
        }
      }
    }

    return readyObjsStr;
  };
  // ** end reuseable functions

  // check if all CSV files are uploaded
  const checkIfAllFilesAreUploaded = () => {
    if (
      generalDirectDataFromCsv &&
      priosClickandcollectDirectDataFromCsv &&
      priosDeliverytDirectDataFromCsv &&
      inPriosOverrideClickandcollectDirectDataFromCsv &&
      inPriosOverrideDeliverytDirectDataFromCsv &&
      strictOverrideClickandcollectDirectDataFromCsv &&
      strictOverrideDeliveryDirectDataFromCsv &&
      generalSettingsClickandcollectDirectDataFromCsv &&
      generalSettingsDeliveryDirectDataFromCsv
    ) {
      generateScriptCode();
    } else {
      alert("You have not uploaded all the files😅");
    }
  };

  // start generate code
  const [readyScriptCodeAsString, setReadyScriptCodeAsString] = useState("");
  const generateScriptCode = () => {
    setGenerateCode(true);
    setReadyScriptCodeAsString(
      `


/* --------------------- START DATA --------------------- */
/* -- START ALGOLIA DETAILS -- */
var algoliaDetails = {
    app_id: "${props.userDataObjFromSheet.algoliaAppId}",
    api_search_key: "${props.userDataObjFromSheet.algoliaApiSearchKey}",
    index_name: "${props.userDataObjFromSheet.algoliaIndexName}"
}
/* -- END ALGOLIA DETAILS -- */

/* -- START STYLES -- */
${
  generalDirectDataFromCsv[1].data[3] === "use-default-styles"
    ? `
  var styles = {
    text_size: "14px",
    text_color: "#242323",
    font_weight: "bold",
    borders_color: "rgb(238, 238, 238)",
    icon_size: "19px",
    icon_color: "#0082C3",
    comment_size: "10px",
    comment_color: "#242323",
  };  
  `
    : `
  var styles = {
    text_size: "${generalDirectDataFromCsv[1].data[4]}",
    text_color: "${generalDirectDataFromCsv[1].data[5]}",
    font_weight: "${generalDirectDataFromCsv[1].data[6]}",
    borders_color: "${generalDirectDataFromCsv[1].data[9]}",
    icon_size: "${generalDirectDataFromCsv[1].data[10]}",
    icon_color: "${generalDirectDataFromCsv[1].data[11]}",
    comment_size: "${generalDirectDataFromCsv[1].data[8]}",
    comment_color: "${generalDirectDataFromCsv[1].data[7]}",
  };
  `
}
/* -- END STYLES -- */

/* -- START DEFAULT SETTINGS CLICK AND COLLECT -- */
var defaultSettingsClickAndCollect = {
  hide_message_bool_no_prio_fit: ${
    generalSettingsClickandcollectDirectDataFromCsv[1].data[0] === "yes"
      ? true
      : false
  },
  message_text_no_prio_fit: "${
    generalSettingsClickandcollectDirectDataFromCsv[1].data[1]
  }",
  display_comment_no_prio_fit: ${
    generalSettingsClickandcollectDirectDataFromCsv[1].data[2] === "yes"
      ? true
      : false
  },
  comment_text_no_prio_fit: "${
    generalSettingsClickandcollectDirectDataFromCsv[1].data[3]
  }",
};
/* -- END DEFAULT DEFAULT SETTINGS CLICK AND COLLECT -- */


/* -- START DEFAULT SETTINGS DELIVERY -- */
var defaultSettingsDelivery = {
  hide_message_bool_no_prio_fit: ${
    generalSettingsDeliveryDirectDataFromCsv[1].data[0] === "yes" ? true : false
  },
  message_text_no_prio_fit: "${
    generalSettingsDeliveryDirectDataFromCsv[1].data[1]
  }",
  display_comment_no_prio_fit: ${
    generalSettingsDeliveryDirectDataFromCsv[1].data[2] === "yes" ? true : false
  },
  comment_text_no_prio_fit: "${
    generalSettingsDeliveryDirectDataFromCsv[1].data[3]
  }",
};
/* -- END DEFAULT SETTINGS DELIVERY -- */



/* -- START GENERAL OVERRIDES CLICK AND COLLECT -- */
var clickAndCollectGeneralOverrides = {
  general_overrides_bool: true,
  overrides_product_nature: [
    ${convertStrictOverridesToObjects(
      strictOverrideClickandcollectDirectDataFromCsv,
      "product-nature"
    )}
   ],
  overrides_modal_code: [
    ${convertStrictOverridesToObjects(
      strictOverrideClickandcollectDirectDataFromCsv,
      "model-code"
    )}
   ],
};
/* -- END GENERAL OVERRIDES CLICK AND COLLECT -- */

/* -- START GENERAL OVERRIDES DELIVERY -- */
var deliveryGeneralOverrides = {
  general_overrides_bool: true,
  overrides_product_nature: [
    ${convertStrictOverridesToObjects(
      strictOverrideDeliveryDirectDataFromCsv,
      "product-nature"
    )}
   ],
  overrides_modal_code: [
    ${convertStrictOverridesToObjects(
      strictOverrideDeliveryDirectDataFromCsv,
      "model-code"
    )}
   ],
};
/* -- END GENERAL OVERRIDES DELIVERY -- */


/* -- START CLICK AND COLLECT VARS OBJECT -- */
var clickAndCollectVars = {
  general: {
    messageText: "",
    commentText: "",
    commentTextDisplayBool: false,
    hideElemBool: false
  },
  prio1: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio2: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio3: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio4: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio5: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio6: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio7: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio8: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  }
};
/* -- END CLICK AND COLLECT VARS OBJECT -- */

/* -- START DELIVERY VARS OBJECT -- */
var deliveryVars = {
  general: {
    messageText: "",
    commentText: "",
    commentTextDisplayBool: false,
    hideElemBool: false
  },
  prio1: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio2: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio3: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio4: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio5: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio6: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio7: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  },
  prio8: {
    availableBool: false,
    overrideExistBool: false,
    overrideTextMessage: "",
    overrideCommentBool: false,
    overrideCommentMessageText: ""
  }
};
/* -- END VARS DELIVERY -- */

/* -- START PRIOS CLICK AND COLLECT -- */
var priosClickAndCollect = {
  prio_1: {
    text_message: "${priosClickandcollectDirectDataFromCsv[1].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[1].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[1].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[1].data[1]
    )}],
    overrides_product_nature: [
     ${convertPrioOverridesToObjects(
       inPriosOverrideClickandcollectDirectDataFromCsv,
       "1",
       "product-nature"
     )}
    ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "1",
        "model-code"
      )}
    ],
  },

  prio_2: {
    text_message: "${priosClickandcollectDirectDataFromCsv[2].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[2].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[2].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[2].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "2",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "2",
        "model-code"
      )}
    ],
  },

  prio_3: {
    text_message: "${priosClickandcollectDirectDataFromCsv[3].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[3].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[3].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[3].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "3",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "3",
        "model-code"
      )}
    ],
  },

  prio_4: {
    text_message: "${priosClickandcollectDirectDataFromCsv[4].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[4].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[4].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[4].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "4",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "4",
        "model-code"
      )}
    ],
  },

  prio_5: {
    text_message: "${priosClickandcollectDirectDataFromCsv[5].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[5].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[5].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[5].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "5",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "5",
        "model-code"
      )}
    ],
  },

  prio_6: {
    text_message: "${priosClickandcollectDirectDataFromCsv[6].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[6].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[6].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[6].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "6",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "6",
        "model-code"
      )}
    ],
  },

  prio_7: {
    text_message: "${priosClickandcollectDirectDataFromCsv[7].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[7].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[7].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[7].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "7",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "7",
        "model-code"
      )}
    ],
  },

  prio_8: {
    text_message: "${priosClickandcollectDirectDataFromCsv[8].data[2]}",
    add_commentBool: ${
      priosClickandcollectDirectDataFromCsv[8].data[3] === "yes" ? true : false
    },
    comment_text: "${priosClickandcollectDirectDataFromCsv[7].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosClickandcollectDirectDataFromCsv[8].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "8",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideClickandcollectDirectDataFromCsv,
        "8",
        "model-code"
      )}
    ],
  },
};
/* -- END PRIOS CLICK AND COLLECT -- */


/* -- START PRIOS DELIVERY -- */
var priosDelivery = {
  prio_1: {
    text_message: "${priosDeliverytDirectDataFromCsv[1].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[1].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[1].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[1].data[1]
    )}],
    overrides_product_nature: [
     ${convertPrioOverridesToObjects(
       inPriosOverrideDeliverytDirectDataFromCsv,
       "1",
       "product-nature"
     )}
    ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "1",
        "model-code"
      )}
    ],
  },

  prio_2: {
    text_message: "${priosDeliverytDirectDataFromCsv[2].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[2].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[2].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[2].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "2",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "2",
        "model-code"
      )}
    ],
  },

  prio_3: {
    text_message: "${priosDeliverytDirectDataFromCsv[3].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[3].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[3].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[3].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "3",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "3",
        "model-code"
      )}
    ],
  },

  prio_4: {
    text_message: "${priosDeliverytDirectDataFromCsv[4].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[4].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[4].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[4].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "4",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "4",
        "model-code"
      )}
    ],
  },

  prio_5: {
    text_message: "${priosDeliverytDirectDataFromCsv[5].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[5].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[5].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[5].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "5",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "5",
        "model-code"
      )}
    ],
  },

  prio_6: {
    text_message: "${priosDeliverytDirectDataFromCsv[6].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[6].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[6].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[6].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "6",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "6",
        "model-code"
      )}
    ],
  },

  prio_7: {
    text_message: "${priosDeliverytDirectDataFromCsv[7].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[7].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[7].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[7].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "7",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "7",
        "model-code"
      )}
    ],
  },

  prio_8: {
    text_message: "${priosDeliverytDirectDataFromCsv[8].data[2]}",
    add_commentBool: ${
      priosDeliverytDirectDataFromCsv[8].data[3] === "yes" ? true : false
    },
    comment_text: "${priosDeliverytDirectDataFromCsv[7].data[4]}",
    code_zones: [${convertStringToArrayString(
      priosDeliverytDirectDataFromCsv[8].data[1]
    )}],
    overrides_product_nature: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "8",
        "product-nature"
      )}
     ],
    overrides_modal_code: [
      ${convertPrioOverridesToObjects(
        inPriosOverrideDeliverytDirectDataFromCsv,
        "8",
        "model-code"
      )}
    ],
  },
};
/* -- END PRIOS DELIVERY -- */

/* --------------------- END DATA --------------------- */
// -- START HANDLE PRODUCT SIZE MESSAGE
// correct size string if has perentesis

var sizeFromAlgoliaResult;

var addPerentesisToSizeIfHasForAlgoliaApiCallFilters = function addPerentesisToSizeIfHasForAlgoliaApiCallFilters(sizeStrFromAlgolia, sizeStrFromPageElem) {
  if (sizeStrFromAlgolia !== undefined && sizeStrFromPageElem !== undefined && sizeStrFromAlgolia.indexOf(")") > -1) {
    // get length of strings
    var lengthOfSizeStrFromAlgolia = sizeStrFromAlgolia.length;
    var lengthOfSizeStrFromPageElem = sizeStrFromPageElem.length; // calc the dif (-2 for the perentesis)

    var dif = lengthOfSizeStrFromAlgolia - lengthOfSizeStrFromPageElem - 2; // get index of perentesis

    var inexOfFirstPrenteses = sizeStrFromAlgolia.indexOf("(");
    var inexOfSecondPrenteses = sizeStrFromAlgolia.indexOf(")"); // split the size from page str

    var splitedArrFromPageElement = sizeStrFromPageElem.split(""); // add the perentesis to the arr with splice

    splitedArrFromPageElement.splice(inexOfFirstPrenteses - dif, 0, "(");
    splitedArrFromPageElement.splice(inexOfSecondPrenteses - dif, 0, ")"); // convert the arr to str

    var readyToUseStr = splitedArrFromPageElement.join("");
    return readyToUseStr;
  } else {
    return sizeStrFromPageElem;
  }
};

var productSizeAsString = false;
var filterBySizeObjForAlgoliaApi = false;

function handleProductSize() {
  // 1. onclick size btns
  var sizeSelectorElem = document.getElementById("oneshop__size-selector__select-size");
  sizeSelectorElem && sizeSelectorElem.addEventListener("click", function () {
    var sizeBtns = document.getElementsByClassName("svelte-1sv9b3d");

    for (var i = 0; i < sizeBtns.length; i++) {
      //sizeBtns[i] &&
      sizeBtns[i].addEventListener("click", function (e) {
        // 1. remove current message
        document.getElementById("promise-delivery-message-top-container").style.display = "none"; // 2. get size from clicked btn and set filter by size object

        productSizeAsString = e.target.closest(".svelte-1sv9b3d").getElementsByTagName("div")[1].innerText;
        productSizeAsString = addPerentesisToSizeIfHasForAlgoliaApiCallFilters(sizeFromAlgoliaResult, productSizeAsString);

        if (productSizeAsString !== "Einheitsgröße") {
          filterBySizeObjForAlgoliaApi = {
            filters: "size:${
              document.getElementById("aa").innerHTML
            }"".concat(productSizeAsString, "${
        document.getElementById("aa").innerHTML
      }"")
          };
        } // 3. run the main function with the relevant size


        promiseDeliveryMessage();
      });
    }
  });
}

handleProductSize(); // -- END HANDLE PRODUCT SIZE MESSAGE

var result;

function promiseDeliveryMessage() {
  /* -- START GET PRODUCT DATA FROM ALGOLIA -- */
  var productModalCode = dataLayer && dataLayer[4].products.articles[0].model.id;
  var clientAlg = algoliasearch(algoliaDetails.app_id, algoliaDetails.api_search_key);
  var index = clientAlg.initIndex(algoliaDetails.index_name);
  index.search(productModalCode, filterBySizeObjForAlgoliaApi).then(function (r) {
    result = r;
    sizeFromAlgoliaResult = result.hits[0].size; // console.log("result: ", result);

    createPromiseMessages(priosClickAndCollect, clickAndCollectVars, clickAndCollectGeneralOverrides, defaultSettingsClickAndCollect);
    createPromiseMessages(priosDelivery, deliveryVars, deliveryGeneralOverrides, defaultSettingsDelivery);
  }).then(function () {
    insertHtmlCodeToPage();
  });
  /* -- END GET PRODUCT DATA FROM ALGOLIA -- */

  /* -- START CREATE PROMISES MESSAGES FUNCTION  -- */

  function createPromiseMessages(priosObj, varsObj, generalOverrides, defaultSettings) {
    checkZonesAvailability(); // -- START CHECK ZONES AVAILABILITY

    function checkZonesAvailability() {
      // start check prio 1 group
      for (var i = 0; i < priosObj.prio_1.code_zones.length; i++) {
        if (result.hits[0].zones[priosObj.prio_1.code_zones[i]] && result.hits[0].zones[priosObj.prio_1.code_zones[i]].available) {
          varsObj.prio1.availableBool = true;
        }
      } // end check prio 1 group
      // start check prio 2 group


      for (var _i = 0; _i < priosObj.prio_2.code_zones.length; _i++) {
        if (result.hits[0].zones[priosObj.prio_2.code_zones[_i]] && result.hits[0].zones[priosObj.prio_2.code_zones[_i]].available) {
          varsObj.prio2.availableBool = true;
        }
      } // end check prio 2 group
      // start check prio 3 group


      for (var _i2 = 0; _i2 < priosObj.prio_3.code_zones.length; _i2++) {
        if (result.hits[0].zones[priosObj.prio_3.code_zones[_i2]] && result.hits[0].zones[priosObj.prio_3.code_zones[_i2]].available) {
          varsObj.prio3.availableBool = true;
        }
      } // end check prio 3 group
      // start check prio 4 group


      for (var _i3 = 0; _i3 < priosObj.prio_4.code_zones.length; _i3++) {
        if (result.hits[0].zones[priosObj.prio_4.code_zones[_i3]] && result.hits[0].zones[priosObj.prio_4.code_zones[_i3]].available) {
          varsObj.prio4.availableBool = true;
        }
      } // end check prio 4 group
      // start check prio 5 group


      for (var _i4 = 0; _i4 < priosObj.prio_5.code_zones.length; _i4++) {
        if (result.hits[0].zones[priosObj.prio_5.code_zones[_i4]] && result.hits[0].zones[priosObj.prio_5.code_zones[_i4]].available) {
          varsObj.prio5.availableBool = true;
        }
      } // end check prio 5 group
      // start check prio 6 group


      for (var _i5 = 0; _i5 < priosObj.prio_6.code_zones.length; _i5++) {
        if (result.hits[0].zones[priosObj.prio_6.code_zones[_i5]] && result.hits[0].zones[priosObj.prio_6.code_zones[_i5]].available) {
          varsObj.prio6.availableBool = true;
        }
      } // end check prio 6 group
      // start check prio 7 group


      for (var _i6 = 0; _i6 < priosObj.prio_7.code_zones.length; _i6++) {
        if (result.hits[0].zones[priosObj.prio_7.code_zones[_i6]] && result.hits[0].zones[priosObj.prio_7.code_zones[_i6]].available) {
          varsObj.prio7.availableBool = true;
        }
      } // end check prio 7 group
      // start check prio 8 group


      for (var _i7 = 0; _i7 < priosObj.prio_8.code_zones.length; _i7++) {
        if (result.hits[0].zones[priosObj.prio_8.code_zones[_i7]] && result.hits[0].zones[priosObj.prio_8.code_zones[_i7]].available) {
          varsObj.prio8.availableBool = true;
        }
      } // end check prio 8 group


      checkOverridePrios();
    } // end checkZonesAvailability function
    // -- END CHECK ZONES AVAILABILITY
    // -- START CHECK OVERRIDE PRIOS


    function checkOverridePrios() {
      // START PRIO 1
      // start check overrides product nature in prio 1
      for (var i = 0; i < priosObj.prio_1.overrides_product_nature.length; i++) {
        if (priosObj.prio_1.overrides_product_nature[i].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_1.overrides_product_nature[i].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio1.overrideExistBool = true;
          varsObj.prio1.overrideTextMessage = priosObj.prio_1.overrides_product_nature[i].override_text_message;
          varsObj.prio1.overrideCommentBool = priosObj.prio_1.overrides_product_nature[i].override_add_commentBool;
          varsObj.prio1.overrideCommentMessageText = priosObj.prio_1.overrides_product_nature[i].override_comment_text;
        }
      } // end check overrides product nature in prio 1
      // start check overrides model code in prio 1


      for (var _i8 = 0; _i8 < priosObj.prio_1.overrides_modal_code.length; _i8++) {
        if (priosObj.prio_1.overrides_modal_code[_i8].values.includes(result.hits[0].id_code_model) && !priosObj.prio_1.overrides_modal_code[_i8].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio1.overrideExistBool = true;
          varsObj.prio1.overrideTextMessage = priosObj.prio_1.overrides_modal_code[_i8].override_text_message;
          varsObj.prio1.overrideCommentBool = priosObj.prio_1.overrides_modal_code[_i8].override_add_commentBool;
          varsObj.prio1.overrideCommentMessageText = priosObj.prio_1.overrides_modal_code[_i8].override_comment_text;
        }
      } // end check overrides model code in prio 1
      // END PRIO 1
      // START PRIO 2
      // start check overrides product nature in prio 2


      for (var _i9 = 0; _i9 < priosObj.prio_2.overrides_product_nature.length; _i9++) {
        if (priosObj.prio_2.overrides_product_nature[_i9].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_2.overrides_product_nature[_i9].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio2.overrideExistBool = true;
          varsObj.prio2.overrideTextMessage = priosObj.prio_2.overrides_product_nature[_i9].override_text_message;
          varsObj.prio2.overrideCommentBool = priosObj.prio_2.overrides_product_nature[_i9].override_add_commentBool;
          varsObj.prio2.overrideCommentMessageText = priosObj.prio_2.overrides_product_nature[_i9].override_comment_text;
        }
      } // end check overrides product nature in prio 2
      // start check overrides model code in prio 2


      for (var _i10 = 0; _i10 < priosObj.prio_2.overrides_modal_code.length; _i10++) {
        if (priosObj.prio_2.overrides_modal_code[_i10].values.includes(result.hits[0].id_code_model) && !priosObj.prio_2.overrides_modal_code[_i10].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio2.overrideExistBool = true;
          varsObj.prio2.overrideTextMessage = priosObj.prio_2.overrides_modal_code[_i10].override_text_message;
          varsObj.prio2.overrideCommentBool = priosObj.prio_2.overrides_modal_code[_i10].override_add_commentBool;
          varsObj.prio2.overrideCommentMessageText = priosObj.prio_2.overrides_modal_code[_i10].override_comment_text;
        }
      } // end check overrides model code in prio 2
      // END PRIO 2
      // START PRIO 3
      // start check overrides product nature in prio 3


      for (var _i11 = 0; _i11 < priosObj.prio_3.overrides_product_nature.length; _i11++) {
        if (priosObj.prio_3.overrides_product_nature[_i11].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_3.overrides_product_nature[_i11].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio3.overrideExistBool = true;
          varsObj.prio3.overrideTextMessage = priosObj.prio_3.overrides_product_nature[_i11].override_text_message;
          varsObj.prio3.overrideCommentBool = priosObj.prio_3.overrides_product_nature[_i11].override_add_commentBool;
          varsObj.prio3.overrideCommentMessageText = priosObj.prio_3.overrides_product_nature[_i11].override_comment_text;
        }
      } // end check overrides product nature in prio 3
      // start check overrides model code in prio 3


      for (var _i12 = 0; _i12 < priosObj.prio_3.overrides_modal_code.length; _i12++) {
        if (priosObj.prio_3.overrides_modal_code[_i12].values.includes(result.hits[0].id_code_model) && !priosObj.prio_3.overrides_modal_code[_i12].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio3.overrideExistBool = true;
          varsObj.prio3.overrideTextMessage = priosObj.prio_3.overrides_modal_code[_i12].override_text_message;
          varsObj.prio3.overrideCommentBool = priosObj.prio_3.overrides_modal_code[_i12].override_add_commentBool;
          varsObj.prio3.overrideCommentMessageText = priosObj.prio_3.overrides_modal_code[_i12].override_comment_text;
        }
      } // end check overrides model code in prio 3
      // END PRIO 3
      // START PRIO 4
      // start check overrides product nature in prio 4


      for (var _i13 = 0; _i13 < priosObj.prio_4.overrides_product_nature.length; _i13++) {
        if (priosObj.prio_4.overrides_product_nature[_i13].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_4.overrides_product_nature[_i13].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio4.overrideExistBool = true;
          varsObj.prio4.overrideTextMessage = priosObj.prio_4.overrides_product_nature[_i13].override_text_message;
          varsObj.prio4.overrideCommentBool = priosObj.prio_4.overrides_product_nature[_i13].override_add_commentBool;
          varsObj.prio4.overrideCommentMessageText = priosObj.prio_4.overrides_product_nature[_i13].override_comment_text;
        }
      } // end check overrides product nature in prio 4
      // start check overrides model code in prio 4


      for (var _i14 = 0; _i14 < priosObj.prio_4.overrides_modal_code.length; _i14++) {
        if (priosObj.prio_4.overrides_modal_code[_i14].values.includes(result.hits[0].id_code_model) && !priosObj.prio_4.overrides_modal_code[_i14].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio4.overrideExistBool = true;
          varsObj.prio4.overrideTextMessage = priosObj.prio_4.overrides_modal_code[_i14].override_text_message;
          varsObj.prio4.overrideCommentBool = priosObj.prio_4.overrides_modal_code[_i14].override_add_commentBool;
          varsObj.prio4.overrideCommentMessageText = priosObj.prio_4.overrides_modal_code[_i14].override_comment_text;
        }
      } // end check overrides model code in prio 4
      // END PRIO 4
      // START PRIO 5
      // start check overrides product nature in prio 5


      for (var _i15 = 0; _i15 < priosObj.prio_5.overrides_product_nature.length; _i15++) {
        if (priosObj.prio_5.overrides_product_nature[_i15].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_5.overrides_product_nature[_i15].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio5.overrideExistBool = true;
          varsObj.prio5.overrideTextMessage = priosObj.prio_5.overrides_product_nature[_i15].override_text_message;
          varsObj.prio5.overrideCommentBool = priosObj.prio_5.overrides_product_nature[_i15].override_add_commentBool;
          varsObj.prio5.overrideCommentMessageText = priosObj.prio_5.overrides_product_nature[_i15].override_comment_text;
        }
      } // end check overrides product nature in prio 5
      // start check overrides model code in prio 5


      for (var _i16 = 0; _i16 < priosObj.prio_5.overrides_modal_code.length; _i16++) {
        if (priosObj.prio_5.overrides_modal_code[_i16].values.includes(result.hits[0].id_code_model) && !priosObj.prio_5.overrides_modal_code[_i16].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio5.overrideExistBool = true;
          varsObj.prio5.overrideTextMessage = priosObj.prio_5.overrides_modal_code[_i16].override_text_message;
          varsObj.prio5.overrideCommentBool = priosObj.prio_5.overrides_modal_code[_i16].override_add_commentBool;
          varsObj.prio5.overrideCommentMessageText = priosObj.prio_5.overrides_modal_code[_i16].override_comment_text;
        }
      } // end check overrides model code in prio 5
      // END PRIO 5
      // START PRIO 6
      // start check overrides product nature in prio 6


      for (var _i17 = 0; _i17 < priosObj.prio_6.overrides_product_nature.length; _i17++) {
        if (priosObj.prio_6.overrides_product_nature[_i17].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_6.overrides_product_nature[_i17].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio6.overrideExistBool = true;
          varsObj.prio6.overrideTextMessage = priosObj.prio_6.overrides_product_nature[_i17].override_text_message;
          varsObj.prio6.overrideCommentBool = priosObj.prio_6.overrides_product_nature[_i17].override_add_commentBool;
          varsObj.prio6.overrideCommentMessageText = priosObj.prio_6.overrides_product_nature[_i17].override_comment_text;
        }
      } // end check overrides product nature in prio 6
      // start check overrides model code in prio 6


      for (var _i18 = 0; _i18 < priosObj.prio_6.overrides_modal_code.length; _i18++) {
        if (priosObj.prio_6.overrides_modal_code[_i18].values.includes(result.hits[0].id_code_model) && !priosObj.prio_6.overrides_modal_code[_i18].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio6.overrideExistBool = true;
          varsObj.prio6.overrideTextMessage = priosObj.prio_6.overrides_modal_code[_i18].override_text_message;
          varsObj.prio6.overrideCommentBool = priosObj.prio_6.overrides_modal_code[_i18].override_add_commentBool;
          varsObj.prio6.overrideCommentMessageText = priosObj.prio_6.overrides_modal_code[_i18].override_comment_text;
        }
      } // end check overrides model code in prio 6
      // END PRIO 6
      // START PRIO 7
      // start check overrides product nature in prio 7


      for (var _i19 = 0; _i19 < priosObj.prio_7.overrides_product_nature.length; _i19++) {
        if (priosObj.prio_7.overrides_product_nature[_i19].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_7.overrides_product_nature[_i19].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio7.overrideExistBool = true;
          varsObj.prio7.overrideTextMessage = priosObj.prio_7.overrides_product_nature[_i19].override_text_message;
          varsObj.prio7.overrideCommentBool = priosObj.prio_7.overrides_product_nature[_i19].override_add_commentBool;
          varsObj.prio7.overrideCommentMessageText = priosObj.prio_7.overrides_product_nature[_i19].override_comment_text;
        }
      } // end check overrides product nature in prio 7
      // start check overrides model code in prio 7


      for (var _i20 = 0; _i20 < priosObj.prio_7.overrides_modal_code.length; _i20++) {
        if (priosObj.prio_7.overrides_modal_code[_i20].values.includes(result.hits[0].id_code_model) && !priosObj.prio_7.overrides_modal_code[_i20].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio7.overrideExistBool = true;
          varsObj.prio7.overrideTextMessage = priosObj.prio_7.overrides_modal_code[_i20].override_text_message;
          varsObj.prio7.overrideCommentBool = priosObj.prio_7.overrides_modal_code[_i20].override_add_commentBool;
          varsObj.prio7.overrideCommentMessageText = priosObj.prio_7.overrides_modal_code[_i20].override_comment_text;
        }
      } // end check overrides model code in prio 7
      // END PRIO 7
      // START PRIO 8
      // start check overrides product nature in prio 8


      for (var _i21 = 0; _i21 < priosObj.prio_8.overrides_product_nature.length; _i21++) {
        if (priosObj.prio_8.overrides_product_nature[_i21].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !priosObj.prio_8.overrides_product_nature[_i21].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.prio8.overrideExistBool = true;
          varsObj.prio8.overrideTextMessage = priosObj.prio_8.overrides_product_nature[_i21].override_text_message;
          varsObj.prio8.overrideCommentBool = priosObj.prio_8.overrides_product_nature[_i21].override_add_commentBool;
          varsObj.prio8.overrideCommentMessageText = priosObj.prio_8.overrides_product_nature[_i21].override_comment_text;
        }
      } // end check overrides product nature in prio 8
      // start check overrides model code in prio 8


      for (var _i22 = 0; _i22 < priosObj.prio_8.overrides_modal_code.length; _i22++) {
        if (priosObj.prio_8.overrides_modal_code[_i22].values.includes(result.hits[0].id_code_model) && !priosObj.prio_8.overrides_modal_code[_i22].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.prio8.overrideExistBool = true;
          varsObj.prio8.overrideTextMessage = priosObj.prio_8.overrides_modal_code[_i22].override_text_message;
          varsObj.prio8.overrideCommentBool = priosObj.prio_8.overrides_modal_code[_i22].override_add_commentBool;
          varsObj.prio8.overrideCommentMessageText = priosObj.prio_8.overrides_modal_code[_i22].override_comment_text;
        }
      } // end check overrides model code in prio 8
      // END PRIO 8


      setMessageAccordingToPrios();
    } // end checkOverridePrios function
    // -- END CHECK OVERRIDE PRIOS
    // -- START SET MESSAGE ACCORDING TO PRIOS


    function setMessageAccordingToPrios() {
      // start check and set according to prio 1
      if (varsObj.prio1.availableBool) {
        if (varsObj.prio1.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio1.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio1.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio1.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_1.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_1.add_commentBool;
          varsObj.general.commentText = priosObj.prio_1.comment_text;
        } // end check and set according to prio 1
        // start check and set according to prio 2

      } else if (varsObj.prio2.availableBool) {
        if (varsObj.prio2.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio2.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio2.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio2.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_2.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_2.add_commentBool;
          varsObj.general.commentText = priosObj.prio_2.comment_text;
        } // end check and set according to prio 2
        // start check and set according to prio 3

      } else if (varsObj.prio3.availableBool) {
        if (varsObj.prio3.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio3.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio3.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio3.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_3.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_3.add_commentBool;
          varsObj.general.commentText = priosObj.prio_3.comment_text;
        } // end check and set according to prio 3
        // start check and set according to prio 4

      } else if (varsObj.prio4.availableBool) {
        if (varsObj.prio4.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio4.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio4.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio4.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_4.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_4.add_commentBool;
          varsObj.general.commentText = priosObj.prio_4.comment_text;
        } // end check and set according to prio 4
        // start check and set according to prio 5

      } else if (varsObj.prio5.availableBool) {
        if (varsObj.prio5.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio5.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio5.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio5.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_5.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_5.add_commentBool;
          varsObj.general.commentText = priosObj.prio_5.comment_text;
        } // end check and set according to prio 5
        // start check and set according to prio 6

      } else if (varsObj.prio6.availableBool) {
        if (varsObj.prio6.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio6.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio6.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio6.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_6.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_6.add_commentBool;
          varsObj.general.commentText = priosObj.prio_6.comment_text;
        } // end check and set according to prio 6
        // start check and set according to prio 7

      } else if (varsObj.prio7.availableBool) {
        if (varsObj.prio7.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio7.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio7.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio7.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_7.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_7.add_commentBool;
          varsObj.general.commentText = priosObj.prio_7.comment_text;
        } // end check and set according to prio 7
        // start check and set according to prio 8

      } else if (varsObj.prio8.availableBool) {
        if (varsObj.prio8.overrideExistBool) {
          varsObj.general.messageText = varsObj.prio8.overrideTextMessage;
          varsObj.general.commentTextDisplayBool = varsObj.prio8.overrideCommentBool;
          varsObj.general.commentText = varsObj.prio8.overrideCommentMessageText;
        } else {
          varsObj.general.messageText = priosObj.prio_8.text_message;
          varsObj.general.commentTextDisplayBool = priosObj.prio_8.add_commentBool;
          varsObj.general.commentText = priosObj.prio_8.comment_text;
        } // end check and set according to prio 8
        // start no prio group fit

      } else {
        if (defaultSettings.hide_message_bool_no_prio_fit) {
          varsObj.general.hideElemBool = true;
        } else {
          varsObj.general.messageText = defaultSettings.message_text_no_prio_fit;
          varsObj.general.commentTextDisplayBool = defaultSettings.display_comment_no_prio_fit;
          varsObj.general.commentText = defaultSettings.comment_text_no_prio_fit;
        } // end no prio group fit

      }

      overrideMessageAccordingToGeneralOverrides();
    } // end setMessageAccordingToPrios function
    // -- END SET MESSAGE ACCORDING TO PRIOS
    // -- START OVERRIDE MESSAGE ACCORDING TO GENERAL OVERRIDES


    function overrideMessageAccordingToGeneralOverrides() {
      // start check and set according to product nature
      for (var i = 0; i < generalOverrides.overrides_product_nature.length; i++) {
        if (generalOverrides.overrides_product_nature[i].values.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase()) && !generalOverrides.overrides_product_nature[i].override_exeptions_modal_codes.includes(result.hits[0].id_code_model)) {
          varsObj.general.messageText = generalOverrides.overrides_product_nature[i].override_text_message;
          varsObj.general.commentTextDisplayBool = generalOverrides.overrides_product_nature[i].override_add_commentBool;
          varsObj.general.commentText = generalOverrides.overrides_product_nature[i].override_comment_text;
        } // end top if

      } // end check and set according to product nature
      // start check and set according to model code


      for (var _i23 = 0; _i23 < generalOverrides.overrides_modal_code.length; _i23++) {
        if (generalOverrides.overrides_modal_code[_i23].values.includes(result.hits[0].id_code_model) && !generalOverrides.overrides_modal_code[_i23].override_exeptions_product_nature.map(function (e) {
          return e.toLowerCase();
        }).includes(result.hits[0].nature.toLowerCase())) {
          varsObj.general.messageText = generalOverrides.overrides_modal_code[_i23].override_text_message;
          varsObj.general.commentTextDisplayBool = generalOverrides.overrides_modal_code[_i23].override_add_commentBool;
          varsObj.general.commentText = generalOverrides.overrides_modal_code[_i23].override_comment_text;
        } // end top if

      } // end check and set according to model code

    } // end overrideMessageAccordingToGeneralOverrides
    // -- END OVERRIDE MESSAGE ACCORDING TO GENERAL OVERRIDES

  } // end createPromiseMessages function

  /* -- START INSERT HTML CODE TO PAGE FUNCTION  -- */


  function insertHtmlCodeToPage() {
    var messageContainerDiv = document.createElement("div");
    messageContainerDiv.setAttribute("id", "promise-delivery-message-top-container");
    messageContainerDiv.innerHTML = "${
      document.getElementById("aa").innerHTML
    }n                  <div style=${
        document.getElementById("aa").innerHTML
      }"font-size: ".concat(styles.text_size, "; font-weight: ").concat(styles.font_weight, "; margin: 15px 0px; padding: 10px 2px; border-top: 1px solid ").concat(styles.borders_color, "; border-bottom: 1px solid ").concat(styles.borders_color, ";${
        document.getElementById("aa").innerHTML
      }">${document.getElementById("aa").innerHTML}n${
        document.getElementById("aa").innerHTML
      }n      ").concat(result.hits[0].size !== "No Size" && result.hits[0].size !== undefined && result.hits[0].size !== "Einheitsgröße" ? "${
        document.getElementById("aa").innerHTML
      }n          <div style=${
        document.getElementById("aa").innerHTML
      }"margin-bottom: 10px; display: inline-block; font-size: 12px; color: #fff; background-color: #0082C3; padding: 2px 4px${
        document.getElementById("aa").innerHTML
      }">Lieferkonditionen f${document.getElementById("aa").innerHTML}xFCr Gr${
        document.getElementById("aa").innerHTML
      }xF6${
        document.getElementById("aa").innerHTML
      }xDFe: ".concat(result.hits[0].size, "</div>${
        document.getElementById("aa").innerHTML
      }n          ") : "", "${
        document.getElementById("aa").innerHTML
      }n                            <div class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__delivery${
        document.getElementById("aa").innerHTML
      }" style=${
        document.getElementById("aa").innerHTML
      }"padding-bottom: 10px; margin-bottom: 10px; ").concat(deliveryVars.general.hideElemBool && "display: none", "${
        document.getElementById("aa").innerHTML
      }">${
        document.getElementById("aa").innerHTML
      }n                            ${
        document.getElementById("aa").innerHTML
      }n                                <div style=${
        document.getElementById("aa").innerHTML
      }"display:flex;${document.getElementById("aa").innerHTML}">${
        document.getElementById("aa").innerHTML
      }n                          <div>${
        document.getElementById("aa").innerHTML
      }n                          <svg style=${
        document.getElementById("aa").innerHTML
      }"color:").concat(styles.icon_color, "; margin-bottom: -3px; margin: 0 5px${
        document.getElementById("aa").innerHTML
      }" aria-hidden=${document.getElementById("aa").innerHTML}"true${
        document.getElementById("aa").innerHTML
      }" role=${document.getElementById("aa").innerHTML}"img${
        document.getElementById("aa").innerHTML
      }" width=${
        document.getElementById("aa").innerHTML
      }"").concat(styles.icon_size, "${
        document.getElementById("aa").innerHTML
      }" height=${
        document.getElementById("aa").innerHTML
      }"").concat(styles.icon_size, "${
        document.getElementById("aa").innerHTML
      }" preserveAspectRatio=${
        document.getElementById("aa").innerHTML
      }"xMidYMid meet${document.getElementById("aa").innerHTML}" viewBox=${
        document.getElementById("aa").innerHTML
      }"0 0 32 32${document.getElementById("aa").innerHTML}"><path fill=${
        document.getElementById("aa").innerHTML
      }"currentColor${document.getElementById("aa").innerHTML}" d=${
        document.getElementById("aa").innerHTML
      }"M4 16h12v2H4zm-2-5h10v2H2z${
        document.getElementById("aa").innerHTML
      }"></path><path fill=${
        document.getElementById("aa").innerHTML
      }"currentColor${document.getElementById("aa").innerHTML}" d=${
        document.getElementById("aa").innerHTML
      }"m29.919 16.606l-3-7A.999.999 0 0 0 26 9h-3V7a1 1 0 0 0-1-1H6v2h15v12.556A3.992 3.992 0 0 0 19.142 23h-6.284a4 4 0 1 0 0 2h6.284a3.98 3.98 0 0 0 7.716 0H29a1 1 0 0 0 1-1v-7a.997.997 0 0 0-.081-.394ZM9 26a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2Zm14-15h2.34l2.144 5H23Zm0 15a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2Zm5-3h-1.142A3.995 3.995 0 0 0 23 20v-2h5Z${
        document.getElementById("aa").innerHTML
      }"></path></svg>${
        document.getElementById("aa").innerHTML
      }n                          </div>${
        document.getElementById("aa").innerHTML
      }n                          ${
        document.getElementById("aa").innerHTML
      }n                          <div>${
        document.getElementById("aa").innerHTML
      }n                          <span class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__delivery--text${
        document.getElementById("aa").innerHTML
      }">").concat(deliveryVars.general.messageText, "</span>${
        document.getElementById("aa").innerHTML
      }n                         ").concat(deliveryVars.general.commentTextDisplayBool ? "${
        document.getElementById("aa").innerHTML
      }n                             <span style=${
        document.getElementById("aa").innerHTML
      }"display:block; margin-top:3px; color: ".concat(styles.comment_color, "; font-size: ").concat(styles.comment_size, "${
        document.getElementById("aa").innerHTML
      }" class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__click-and-collect--sm-message${
        document.getElementById("aa").innerHTML
      }">${
        document.getElementById("aa").innerHTML
      }n        ").concat(deliveryVars.general.commentText, "${
        document.getElementById("aa").innerHTML
      }n                          </span>${
        document.getElementById("aa").innerHTML
      }n                             ") : "", "${
        document.getElementById("aa").innerHTML
      }n  ${document.getElementById("aa").innerHTML}n  ${
        document.getElementById("aa").innerHTML
      }n                          </div>${
        document.getElementById("aa").innerHTML
      }n                          </div>${
        document.getElementById("aa").innerHTML
      }n                          </div>${
        document.getElementById("aa").innerHTML
      }n                                ${
        document.getElementById("aa").innerHTML
      }n                        ${
        document.getElementById("aa").innerHTML
      }n                        ${
        document.getElementById("aa").innerHTML
      }n                        <div ").concat(clickAndCollectVars.general.hideElemBool && "style=${
        document.getElementById("aa").innerHTML
      }"display: none${document.getElementById("aa").innerHTML}"", " class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__click-and-collect${
        document.getElementById("aa").innerHTML
      }">${
        document.getElementById("aa").innerHTML
      }n                        <div style=${
        document.getElementById("aa").innerHTML
      }"display:flex;${document.getElementById("aa").innerHTML}">${
        document.getElementById("aa").innerHTML
      }n                        <div>${
        document.getElementById("aa").innerHTML
      }n                        <svg style=${
        document.getElementById("aa").innerHTML
      }"color:").concat(styles.icon_color, "; margin-bottom: -3px;margin: 0 5px${
        document.getElementById("aa").innerHTML
      }" aria-hidden=${document.getElementById("aa").innerHTML}"true${
        document.getElementById("aa").innerHTML
      }" role=${document.getElementById("aa").innerHTML}"img${
        document.getElementById("aa").innerHTML
      }" width=${
        document.getElementById("aa").innerHTML
      }"").concat(styles.icon_size, "${
        document.getElementById("aa").innerHTML
      }" height=${
        document.getElementById("aa").innerHTML
      }"").concat(styles.icon_size, "${
        document.getElementById("aa").innerHTML
      }" preserveAspectRatio=${
        document.getElementById("aa").innerHTML
      }"xMidYMid meet${document.getElementById("aa").innerHTML}" viewBox=${
        document.getElementById("aa").innerHTML
      }"0 0 32 32${document.getElementById("aa").innerHTML}"><path fill=${
        document.getElementById("aa").innerHTML
      }"currentColor${document.getElementById("aa").innerHTML}" d=${
        document.getElementById("aa").innerHTML
      }"m30 10.68l-2-6A1 1 0 0 0 27 4H5a1 1 0 0 0-1 .68l-2 6A1.19 1.19 0 0 0 2 11v6a1 1 0 0 0 1 1h1v10h2V18h6v10h16V18h1a1 1 0 0 0 1-1v-6a1.19 1.19 0 0 0 0-.32ZM26 26H14v-8h12Zm2-10h-4v-4h-2v4h-5v-4h-2v4h-5v-4H8v4H4v-4.84L5.72 6h20.56L28 11.16Z${
        document.getElementById("aa").innerHTML
      }"></path></svg>${
        document.getElementById("aa").innerHTML
      }n                        </div>${
        document.getElementById("aa").innerHTML
      }n                        ${
        document.getElementById("aa").innerHTML
      }n                        <div>${
        document.getElementById("aa").innerHTML
      }n                        <span class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__click-and-collect--text${
        document.getElementById("aa").innerHTML
      }">").concat(clickAndCollectVars.general.messageText, "</span>${
        document.getElementById("aa").innerHTML
      }n                        ").concat(clickAndCollectVars.general.commentTextDisplayBool ? "${
        document.getElementById("aa").innerHTML
      }n                              <span style=${
        document.getElementById("aa").innerHTML
      }"display:block; margin-top:3px; color: ".concat(styles.comment_color, "; font-size: ").concat(styles.comment_size, "${
        document.getElementById("aa").innerHTML
      }" class=${
        document.getElementById("aa").innerHTML
      }"promise-delivery-messages__click-and-collect--sm-message${
        document.getElementById("aa").innerHTML
      }">${
        document.getElementById("aa").innerHTML
      }n                               ").concat(clickAndCollectVars.general.commentText, "${
        document.getElementById("aa").innerHTML
      }n                              </span>${
        document.getElementById("aa").innerHTML
      }n                              ") : "", "${
        document.getElementById("aa").innerHTML
      }n    ${
        document.getElementById("aa").innerHTML
      }n                        </div>${
        document.getElementById("aa").innerHTML
      }n                        </div>${
        document.getElementById("aa").innerHTML
      }n                        ${
        document.getElementById("aa").innerHTML
      }n                        </div>${
        document.getElementById("aa").innerHTML
      }n                            </div>${
        document.getElementById("aa").innerHTML
      }n                  "); //insert element

    var elementToInsert = document.getElementsByClassName("block--description")[0].getElementsByClassName("description")[0];
    elementToInsert.after(messageContainerDiv);
  } // end insertHtmlCodeToPage function

  /* -- END INSERT HTML CODE TO PAGE FUNCTION  -- */

} // end promiseDeliveryMessage function


promiseDeliveryMessage();  
      `
    );
  };
  // end generate code

  //console.log("readyScriptCodeAsString: ", readyScriptCodeAsString);
  /* ## END STATES AND FUNCTIONS PROMISE DELIVERY MESSAGE ## */

  return (
    <div className="promise-delivery-message">
      {/* **--** START JS OPERETOR INSERT TO CODE **--** */}
      <div style={{ visibile: "hidden" }} id="aa">
        \
      </div>
      {/* **--** END JS OPERETOR INSERT TO CODE **--** */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2
              id="transition-modal-title"
              style={{ marginBottom: "10px", textTransform: "uppercase" }}
            >
              {props.title}
            </h2>
            <p
              id="transition-modal-description"
              style={{ marginBottom: "12px" }}
            >
              {props.text}
              The messages appear on product pages and give the customer a quick
              overview of the cost and time to receive the product.<br></br>
              You are welcome to implement the feature on your one-shop site.
              Please follow the instructions.
            </p>
            <div style={{ height: "273px" }}>
              <img
                src={props.imgUrl}
                className="popupImg"
                style={{ width: "340px" }}
              />
            </div>

            <div className="popupInputsContainer">
              {/* -- START TOGGLE BUTTONS -- */}
              <div
                style={{
                  margin: "20px 0",
                  background: "#eee",
                  padding: "16px",
                  minHeight: "140px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    marginTop: "12px",
                    color: "#004876",
                  }}
                >
                  {featureCountriesSrtFromDb &&
                  featureCountriesSrtFromDb.length > 0 ? (
                    <p
                      style={{
                        fontSize: "12px",
                        marginBottom: "16px",
                      }}
                    >
                      This feature has been successfully implemented in the
                      following countries: {featureCountriesSrtFromDb}
                    </p>
                  ) : (
                    <span></span>
                  )}

                  {alignment === "yes" ? (
                    <p style={{ fontSize: "16px" }}>
                      We are happy to see here a colleague from{" "}
                      {props.userDataObjFromSheet.country} 🙋‍♂️<br></br> This
                      feature is marked as active in your country. If it is no
                      longer activated, select NO.
                    </p>
                  ) : (
                    <p style={{ fontSize: "16px" }}>
                      We are happy to see here a colleague from{" "}
                      {props.userDataObjFromSheet.country} 🙋‍♂️<br></br> Have you
                      already implemented this feature in your country?
                    </p>
                  )}
                </div>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChangeToggleBtns}
                  aria-label="Platform"
                >
                  <ToggleButton
                    onClick={featureIsLiveInUserCountryHandleOnClick}
                    value="yes"
                    disabled={toggleBtnsDisabledBool || alignment === "yes"}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    onClick={featureIsNotLiveInUserCountryHandleOnClick}
                    value="no"
                    disabled={toggleBtnsDisabledBool || alignment === "no"}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {/* -- END TOGGLE BUTTONS -- */}

              {/* -- START TABS -- */}
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Create / update feature" {...a11yProps(0)} />
                    <Tab label="Instructions" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={valueTabs} index={0}>
                  <div
                    style={{
                      textTransform: "uppercase",
                      marginBottom: "40px",
                      display: "none",
                    }}
                    className="popupInputsContainer__wrapper"
                  >
                    <h2 className="promise-delivery-message__section-title">
                      Relevant links:
                    </h2>
                    <p style={{ marginBottom: "10px" }}>
                      <a
                        target="_blank"
                        href="https://docs.google.com/document/d/1Ee5Xc9NFYW2dGxAgeBQGg_tJ8H3f-MC3rT9F-9hwKLE/edit?usp=sharing"
                      >
                        instructions document
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href="https://docs.google.com/spreadsheets/d/1xorrJrgQvCBKnwZUVuCTvi4KNcO2oY1uLYLetxUx9S8/edit?usp=sharing"
                      >
                        google sheet templates
                      </a>{" "}
                      <span style={{ color: "red" }}>
                        (please make a copy!)
                      </span>
                    </p>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"general"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef1}
                        onFileLoad={(data) =>
                          handleOnFileLoad(data, setGeneralDirectDataFromCsv)
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef1)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"prios_clickandcollect"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef2}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setPriosClickandcollectDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef2)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name:{" "}
                        <span>"in_prios_override_clickandcollect"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef3}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setInPriosOverrideClickandcollectDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef3)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name:{" "}
                        <span>"strict_overrides_clickandcollect"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef4}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setStrictOverrideClickandcollectDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef4)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name:{" "}
                        <span>"general_settings_clickandcollect"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef5}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setGeneralSettingsClickandcollectDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef5)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"prios_delivery"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef6}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setPriosDeliverytDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef6)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"in_prios_override_delivery"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef7}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setInPriosOverrideDeliverytDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef7)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"strict_overrides_delivery"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef8}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setStrictOverrideDeliveryDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef8)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper promise-delivery-message__section">
                    <div className="promise-delivery-message__text">
                      <p>
                        File name: <span>"general_settings_delivery"</span>
                      </p>
                    </div>
                    <div className="promise-delivery-message__btn">
                      <CSVReader
                        ref={buttonRef9}
                        onFileLoad={(data) =>
                          handleOnFileLoad(
                            data,
                            setGeneralSettingsDeliveryDirectDataFromCsv
                          )
                        }
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <Button
                              style={{ maxWidth: "50%" }}
                              variant="contained"
                              onClick={(e) => handleOpenDialog(e, buttonRef9)}
                            >
                              upload csv
                            </Button>

                            <div
                              style={{
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 5,
                                marginBottom: 5,
                                paddingLeft: 13,
                                paddingTop: 3,
                                width: "60%",
                                color: "green",
                              }}
                            >
                              {file && file.name}
                            </div>
                          </aside>
                        )}
                      </CSVReader>
                    </div>
                  </div>

                  <div className="popupInputsContainer__wrapper primary-button-container">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={checkIfAllFilesAreUploaded}
                      className="primary-btn"
                    >
                      Generate script
                    </Button>
                  </div>
                  {generateCode && (
                    <div>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={copyToClipFunc}
                      >
                        copy script
                      </button>
                      {showSuccessCopied && (
                        <div style={{ margin: "10px 0", position: "relative" }}>
                          <DoneIcon
                            style={{ color: "green", position: "absolute" }}
                            fontSize="small"
                          />
                        </div>
                      )}
                      <pre>
                        <code>{readyScriptCodeAsString}</code>
                      </pre>
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={valueTabs} index={1}>
                  <div
                    style={{ textTransform: "uppercase", marginBottom: "40px" }}
                    className="popupInputsContainer__wrapper"
                    id="how-to-accordion"
                  >
                    <div>
                      <p
                        style={{ textTransform: "none", marginBottom: "12px" }}
                      >
                        I divided the implementation of the feature into 18 tiny
                        steps. Good luck! 🙂
                      </p>
                      <h2
                        className="pdm-accordion-section-title"
                        style={{ fontSize: "14px", marginBottom: "10px" }}
                      >
                        start here
                      </h2>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>1.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            What will we use to implement the feature?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <ul>
                              <li>Algolia</li>
                              <li>Google sheet</li>
                              <li>Google tag manager</li>
                            </ul>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>2.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            The steps we are going to take
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                marginBottom: "12px",
                                background: "yellow",
                                display: "inline-block",
                              }}
                            >
                              This is only an overview. I will show you how to
                              do it step by step.
                            </span>
                            <ol>
                              <li>
                                Prepare a list of your zone codes. Zone is a
                                location, store, or warehouse where the product
                                might be available.
                              </li>
                              <li>Make a copy of the google sheet template.</li>
                              <li>
                                Fill the google sheets - 1 general sheet, 4
                                sheets for the delivery message, and 4 sheets
                                for the click and collect message.
                              </li>
                              <li>Download the google sheets as CSV files.</li>
                              <li>
                                Upload the CSV files to the app and generate the
                                script.
                              </li>
                              <li>Test the script on your site.</li>
                              <li>
                                Implement the script in google tag manager.
                              </li>
                            </ol>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>3.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            What do you need to prepare?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Each product in Algolia has the "zones."<br></br>{" "}
                            Each zone code represents one location where the
                            product might be available. <br></br>A zone can be a
                            store or warehouse. <br></br>For example: This code
                            represents our{" "}
                            <span style={{ textDecoration: "underline" }}>
                              warehouse
                            </span>{" "}
                            in Mannheim:
                            <code
                              style={{
                                background: "yellow",
                                marginLeft: "10px",
                              }}
                            >
                              d58211ca-e17e-4f91-8df0-ae9e3db7465d
                            </code>
                            <br></br>This code represents our{" "}
                            <span style={{ textDecoration: "underline" }}>
                              store
                            </span>{" "}
                            in Vösendorf:
                            <code
                              style={{
                                background: "yellow",
                                marginLeft: "10px",
                              }}
                            >
                              2d3efeb2-6ec2-49ed-93fc-5dbfb051bddc
                            </code>{" "}
                            <br></br>Please prepare a list of all your zones.
                            <br></br>
                            <br></br>
                            <span
                              style={{
                                color: "#E20C18",
                                marginBottom: "10px",
                                display: "inline-block",
                                fontSize: "14px",
                              }}
                            >
                              *Ask your transport manager for the zones. If he
                              doesn't have this information, he should contact
                              the DktFF team.
                            </span>
                            <div>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1NzUeT_yAjH0JY7-9obFjoVelITAIGzKs"
                                style={{ width: "90%", marginTop: "20px" }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>4.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Make a copy of the google sheet template
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <a
                              href="https://docs.google.com/spreadsheets/d/1xorrJrgQvCBKnwZUVuCTvi4KNcO2oY1uLYLetxUx9S8/edit?usp=sharing"
                              target="_blank"
                            >
                              Google sheet template
                            </a>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel5"}
                        onChange={handleChange("panel5")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>5.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            We are displaying two messages
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            We need to think of the messages as separate from
                            each other. Therefore, we will prepare them
                            separately. Each one has 4 Google sheets.
                            <div>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1AyASJ9VW76RCCu6j45RMVLCublMSF9Ru"
                                style={{ width: "64%", marginTop: "20px" }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>6.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Save your Algolia credentials to your account on the
                            app
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/2ca6cfb272dd4cddb5c6aaf3d167b5f4"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <h2
                        className="pdm-accordion-section-title"
                        style={{
                          fontSize: "14px",

                          marginTop: "39px",
                        }}
                      >
                        Let's prepare the google sheets
                      </h2>
                      <p
                        style={{ textTransform: "none", marginBottom: "10px" }}
                      >
                        I will show you how I did it for our website:
                      </p>

                      <Accordion
                        expanded={expanded === "panel7"}
                        onChange={handleChange("panel7")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>7.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Our zones
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Here are our zones:
                            <div>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1DB8uDWayt6-gROc187NaIg281PEHaBuj"
                                style={{
                                  width: "64%",
                                  marginTop: "20px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                            We have three stores (blue), the central warehouse
                            (green), and eight other warehouses (black). Each
                            zone has a code.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel8"}
                        onChange={handleChange("panel8")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>8.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "general"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Please ignore the columns : algolia_app_id,
                            algolia_api_search_key, algolia_index_name. You
                            don't need to fill anything in there.<br></br>{" "}
                            <br></br>In styles, you can choose
                            "use-default-styles" (recommended) if you want to
                            use the default styles of the messages.<br></br>
                            <br></br>
                            Select "custom-styles" if you want to add your CSS
                            styles. <br></br>
                            <br></br>If you choose "use-default-styles", you
                            don't need to fill anything in the rest of the
                            columns. <br></br>
                            <br></br>I want to use the default styles, so I will
                            select "use-default-styles":
                            <div style={{ marginTop: "12px" }}>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1YeC5MeL4r2mHZL7y2Zy9RLP5K4b5ai-l"
                                style={{ width: "64%", marginTop: "20px" }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel9"}
                        onChange={handleChange("panel9")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>9.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            delivery message - prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "prios_delivery"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            We are starting with the delivery message.<br></br>
                            <div
                              style={{
                                marginTop: "12px",
                                marginBottom: "10px",
                              }}
                            >
                              <img
                                src="https://drive.google.com/uc?export=view&id=19oHKvQxYsJAkC7v3ks7LHO5kaauc47R7"
                                style={{
                                  width: "64%",
                                  marginTop: "20px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                            First, we should split all of our zones into
                            priority groups. To each group, I will add a
                            specific message. <br></br>
                            <br></br>
                            Here is how I split our zones into two different
                            priority groups:
                            <div style={{ margin: "12px 0" }}>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1Ob4GGYFVgGTuH0q55ACcJLeCfvZfqzZB"
                                style={{
                                  width: "64%",
                                  marginTop: "20px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                            The priority is our warehouse in Manheim. If the
                            product is available in this zone, I want to display
                            the message: "Delivery: within 3-5 working days |
                            €4.99". So I will add the code zone of warehouse
                            Manheim and the message to the first group.{" "}
                            <br></br>
                            <br></br>
                            The second priority is all the other warehouses. If
                            the product is not available in warehouse Manheim
                            but in one of the other, I will display the message:
                            "Delivery: within 1-2 weeks | €4.99".<br></br>
                            <br></br>
                            The stores are irrelevant to the delivery message
                            because we will not send the product from there. If
                            you offer "ship from store," you can also add the
                            relevant stores.
                            <br></br>
                            <br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/830b42d4306e478f9ea0643ab4076c7a"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel10"}
                        onChange={handleChange("panel10")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>10.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            delivery message - prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "in_prios_override_delivery"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <p style={{ color: "gray" }}>
                              "override_in_priority_group" - Select the relevant
                              priority group you want to override.<br></br>
                              <br></br>
                              "override_by" - Select if you want to override by
                              modal code or product nature. <br></br>
                              <br></br>
                              "values" - Add the modal codes/ product nature.
                              <br></br>
                              <br></br>
                              "override_text_message" - Add message.<br></br>
                              <br></br>
                              "override_add_comment" - Select if you want to add
                              a comment to the message<br></br>
                              <br></br>
                            </p>
                            <div
                              style={{
                                marginTop: "12px",
                                marginBottom: "10px",
                              }}
                            >
                              <img
                                src="https://drive.google.com/uc?export=view&id=1bsyb6ueDyiSZHb7DXDwoMarbIW4v1IFo"
                                style={{
                                  width: "64%",
                                  marginTop: "20px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                            I have two priority groups. I have included all
                            products in these groups. <br></br>
                            <br></br>
                            The problem now is that I have products that these
                            messages are irrelevant for them. The delivery fee
                            for the extensive products (like table tennis, for
                            example) is more expensive, and it takes more time
                            for delivery to arrive. <br></br>
                            <br></br>
                            The solution is to create an override rule. I can
                            override messages for specific products by modal
                            code or product nature.<br></br>
                            <br></br>
                            Here is a collection of products I want to display a
                            different message for them: 8529108, 8293230,
                            8299170, 8527376, 8605632, 8656840, 8656839,
                            8656838, 8387870, 8591512, 8656496, 8249809,
                            8656495, 8492638, 8600730, 8611649, 8642422,
                            8671880, 8671879, 8671878, 8753489, 8605830,
                            8648415, 8664477, 8574784, 8064899<br></br>
                            <br></br>
                            Here is the message I want to display for these
                            products if they are available in my first priority
                            group (Mannheim): "Delivery: within 1-2 weeks |
                            €39.90."
                            <br></br>
                            <br></br>
                            Here is the message I want to display for these
                            products if they are not available in the first
                            priority group but available in my second priority
                            group (all other warehouses): "Delivery: within 2-3
                            weeks | €39.90."
                            <br></br>
                            <br></br>
                            <iframe
                              style={{ marginBottom: "20px", display: "block" }}
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/78c23749e2f54425882ae4cda4f7e298"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                            Great, I just created the rules for the collection
                            of modal codes. <br></br>
                            <br></br>Next, I want to display the same override
                            messages for a collection of product natures.{" "}
                            <br></br>
                            <br></br>
                            Here is the collection of product natures I want to
                            override: Dinghy, Laufband, Tischtennisplatte,
                            Hometrainer, Hantelset, Langhantelstange, Surfboard,
                            Squat Rack, Crosstrainer, Power Tower, Studiobike,
                            Rudergerät, Fahrradträger, Multifunktionsgerät,
                            Heimtrainer. <br></br>
                            <br></br> I will add them the same way I did with
                            the modal codes. But here, I have a problem. I have
                            products under those product natures that I don't
                            want to include in the overriding rule.<br></br>
                            <br></br> Here are the modal codes I want to
                            exclude: 8405265, 8484587, 8501776, 8563463,
                            8505768, 8542887, 8543290, 8405780, 8529458,
                            8364829, 8018574, 8655645, 8491830, 8527501,
                            8558427, 8668294, 8524781, 8655914, 8625585,
                            8594463, 8617923, 8673318, 8611032, 8626634.{" "}
                            <br></br>
                            <br></br>Therefore, I can use the "exceptions"
                            column. If I create an override rule by modal code,
                            I can exclude products by product nature. If I
                            create an override rule by product nature, I can
                            exclude products by modal codes.<br></br>
                            <br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/669630cef8a24284881c89fa40c65d9e"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel11"}
                        onChange={handleChange("panel11")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>11.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            delivery message - Prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "strict_overrides_delivery"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            The strict override rules work the same as the
                            in-prio override rules, but the strict override will
                            not consider product availability. If you create a
                            strict override rule, the message of the rule will
                            always appear!
                            <div style={{ marginTop: "12px" }}>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1ZTZnHt278_wnls0vPIQt5VC93SrzHHsG"
                                style={{ width: "64%", marginTop: "20px" }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel12"}
                        onChange={handleChange("panel12")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>12.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            delivery message - Prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "general_settings_delivery"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Here you can choose what to display if the product
                            is unavailable in any priority groups and has no
                            strict rule. You can hide the message entirely or
                            display a different message. For me, I want to hide
                            the message entirely, so I will select yes in
                            "hide_message_if_no_prio_fit" and ignore the rest of
                            the columns.
                            <div style={{ marginTop: "12px" }}>
                              <img
                                src="https://drive.google.com/uc?export=view&id=1KTRU-_5fHgigIoIWPD9SdAeHr9XudqRu"
                                style={{ width: "64%", marginTop: "20px" }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel13"}
                        onChange={handleChange("panel13")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>13.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            click and collect message - Prepare the sheet:{" "}
                            <span style={{ textTransform: "none" }}>
                              "prios_clickandcollect"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            The click-and-collect message works the same way as
                            the delivery message. Here are my priority groups:
                            <div
                              style={{
                                marginTop: "12px",
                                marginBottom: "12px",
                              }}
                            >
                              <img
                                src="https://drive.google.com/uc?export=view&id=13KpmyIy7QkBi5azV0Yl-dfhDvK9kUoor"
                                style={{
                                  width: "64%",
                                  marginTop: "20px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                            My priority is our three stores. If the product is
                            available in one of them, I want to display the
                            message: "Click & Collect: ready for collection in
                            selected branches in 2 hours* | free *for orders
                            before 4 pm". I will also add a comment (for orders
                            before 4 pm). <br></br>
                            <br></br>
                            My second priority is our warehouse in Manheim. If
                            the product is unavailable in one of our stores but
                            available in warehouse Manheim, I want to display
                            the message: "Click & Collect: in 3-5 working days |
                            free".
                            <br></br>
                            <br></br>
                            My third priority is all of the other warehouses. If
                            the product is not available in Manheim but
                            available in one of them, I will display the
                            message: "Click & Collect: in 1-2 weeks | free"
                            <br></br>
                            <br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/3a8d8f67901148ba87c7c2aa4d1f0bbe"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel14"}
                        onChange={handleChange("panel14")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>14.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            click and collect message - Prepare the sheets:{" "}
                            <span style={{ textTransform: "none" }}>
                              "in_prios_override_clickandcollect",
                              "strict_overrides_clickandcollect",
                              "general_settings_clickandcollect"
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            You can fill them the same way as with the delivery
                            message.<br></br> You can leave it all empty if you
                            don't need override rules. <br></br>Don't forget to
                            fill the general settings of the click and collect
                            message: "general_settings_clickandcollect"
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <h2
                        className="pdm-accordion-section-title"
                        style={{
                          fontSize: "14px",

                          marginTop: "39px",
                        }}
                      >
                        Generate script, test and deploy
                      </h2>

                      <Accordion
                        expanded={expanded === "panel15"}
                        onChange={handleChange("panel15")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>15.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Download all google sheets as CSV files
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            You have to download{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              all
                            </span>{" "}
                            nine sheets - it doesn't matter if a sheet is empty
                            or not. Save them to a new folder.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel16"}
                        onChange={handleChange("panel16")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>16.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Upload all CSV files into the app
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Upload all CSV files into the app and click on
                            "Generate script"<br></br>
                            <br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/045b40845ea94ed799a594ecd4d84ba0"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel17"}
                        onChange={handleChange("panel17")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>17.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Test the script on your site
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                display: "block",
                              }}
                            >
                              Products to test:
                            </span>
                            Before going live, you should test your script on
                            these products:
                            <ul style={{ color: "gray", fontSize: "12px" }}>
                              <li>8588308</li>
                              <li>8397086</li>
                              <li>8667096</li>
                              <li>8378493</li>
                            </ul>
                            <ol>
                              After the message appears, please change the
                              product size a few times and be sure that the
                              message displays accordingly.
                              <span
                                style={{
                                  display: "block",
                                  color: "#E20C18",
                                  fontSize: "12px",
                                  marginTop: "2px",
                                }}
                              >
                                *Please contact me (hadar.acobas@decathlon.com)
                                if the message does not update after changing
                                the product's size. Share the code and a link to
                                your site.
                              </span>
                              <br></br>
                              <br></br>
                              <span
                                style={{
                                  textDecoration: "underline",
                                  display: "block",
                                }}
                              >
                                How to test:
                              </span>
                              <li>Enter a product page</li>
                              <li>Mouse - right click</li>
                              <li>Inspect</li>
                              <li>Console</li>
                              <li>Paste the script into the console</li>
                              <li>Click enter</li>
                              <li>You should see the messages on the page</li>
                            </ol>
                            <br></br>
                            <br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/27d852573f584c1b8c952f73b3cadce8"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      {/* start editing--- */}
                      <Accordion
                        expanded={expanded === "panel18"}
                        onChange={handleChange("panel18")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ flexShrink: 0 }}>18.</Typography>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              marginLeft: "10px",
                            }}
                          >
                            Implement script with google tag manager
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              textTransform: "none",
                              padding: "10px 40px",
                            }}
                          >
                            Implement script with google tag manager:
                            <ol>
                              <li>Create a new tag</li>
                              <li>Tag type: HTML</li>
                              <li>
                                Paste the code into the tag.{" "}
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Please wrap the code with script html tag:
                                </span>
                                <div
                                  style={{
                                    marginTop: "12px",
                                    marginBottom: "12px",
                                  }}
                                >
                                  <img
                                    src="https://drive.google.com/uc?export=view&id=1Z-W_CYnGZGlfc6nP2uZh8cGdFW9EeuES"
                                    style={{
                                      width: "64%",
                                      marginTop: "20px",
                                      border: "1px solid black",
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                The trigger should include all your product
                                pages. If you don't have this trigger, please
                                create one.{" "}
                              </li>
                            </ol>
                            <br></br>
                            <br></br>I will recommend you to create a folder for
                            your local features. Once your tag is ready, you can
                            move it into the folder.<br></br>
                            <br></br>
                            Here is our google tag manager:<br></br>
                            <iframe
                              width="640"
                              height="360"
                              src="https://www.loom.com/embed/737d137873b14eb6836d4ebffd4849f2"
                              frameborder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      {/* end editing--- */}
                    </div>
                  </div>
                </TabPanel>
              </Box>
              {/* -- END TABS -- */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
