import React from "react";
import "./index.css";

function Footer() {
  return (
    <div className="footer">
      {/*
       <p>Powered by online team Austria</p>
      */}
      <p>onefront.decathlon.com</p>
    </div>
  );
}

export default Footer;
